import { CaseDetail_Types } from '../../sysObjects/apiModels/Case.types';


export namespace ServiceUtils {

    export const doesServiceContainInvoicedBillableItems = (
        service: CaseDetail_Types.RetrievedServiceToFulfill
    ): boolean => {
        return (
            (
                service.billableItems && service?.billableItems.some((bi) => bi.isInvoiced)
            ) || false
        );
    };

    export const doesServiceContainOutstandingBillableItems = (
        service: CaseDetail_Types.RetrievedServiceToFulfill
    ): boolean => {
        return (
            (
                service.billableItems && service?.billableItems.some((bi) => !bi.isInvoiced)
            ) || false
        );
    };
}

export default ServiceUtils;
