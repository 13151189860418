import React from 'react';
import SVGTypes from './svg.types';

export const Search: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99418)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM16.9196 26.8187C19.1569 27.0752 21.4086 26.4774 23.2242 25.145C23.2653 25.1998 23.3091 25.2518 23.3584 25.3025L28.652 30.5968C28.9098 30.8548 29.2596 30.9999 29.6243 31C29.989 31.0001 30.3389 30.8553 30.5969 30.5975C30.8549 30.3396 30.9999 29.9898 31 29.6251C31.0001 29.2603 30.8554 28.9104 30.5975 28.6524L25.304 23.3581C25.255 23.3085 25.2024 23.2627 25.1465 23.221C26.4774 21.4042 27.0735 19.1518 26.8155 16.9143C26.5574 14.6765 25.4641 12.6187 23.7541 11.1525C22.0442 9.68629 19.8437 8.9199 17.5931 9.00663C15.3424 9.09337 13.2075 10.0268 11.6154 11.6203C10.0233 13.2137 9.0915 15.3496 9.00639 17.6007C8.92128 19.8517 9.68913 22.0519 11.1563 23.761C12.6235 25.4702 14.6819 26.5622 16.9196 26.8187ZM24.925 20.8302C25.305 19.9126 25.5006 18.9291 25.5006 17.9359C25.5006 15.93 24.7039 14.0062 23.2857 12.5878C21.8675 11.1694 19.944 10.3726 17.9384 10.3726C15.9328 10.3726 14.0093 11.1694 12.5911 12.5878C11.1729 14.0062 10.3762 15.93 10.3762 17.9359C10.3762 18.9291 10.5718 19.9126 10.9518 20.8302C11.3319 21.7478 11.8889 22.5816 12.5911 23.2839C13.2933 23.9862 14.127 24.5433 15.0445 24.9234C15.962 25.3035 16.9453 25.4992 17.9384 25.4992C18.9315 25.4992 19.9148 25.3035 20.8323 24.9234C21.7498 24.5433 22.5835 23.9862 23.2857 23.2839C23.9879 22.5816 24.5449 21.7478 24.925 20.8302Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99418">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Search;
