import React from 'react';
import SVGTypes from './svg.types';

export const EmptyRadio: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99367)">
        <path
          d="M0 20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path d="M0 0H40V40H0V0Z"  fill={props.fill} />
      </g>
      <path
        d="M20 39C9.50659 39 1 30.4934 1 20C1 9.50659 9.50659 1 20 1C30.4934 1 39 9.50659 39 20C39 30.4934 30.4934 39 20 39Z"
        fill={props.fill}
        strokeWidth="2"
      />
      <defs>
        <clipPath id="clip0_3783_99367">
          <path
            d="M0 20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyRadio;
