import React from 'react';
import FormControllerContainer from '../formControlContainer/FormControlContainer';
import FormDurationTypes from './FormDuration.types';
import { ControlState } from '../../../../sysObjects/common.types';
import { FormControlRef } from '../formControlContainer/FormControlContainer.types';
import './FormDuration.css';


const FormDuration: React.ForwardRefRenderFunction<
  FormControlRef,
  FormDurationTypes.Props
> = (props, ref) => {
  const breakDuration = (input?: string | null) => {
    if (!input || input === undefined || input === null) {
      return { days: '', hours: '', minutes: '' };
    }

    let [days, time] = input.includes('.') ? input.split('.') : ['', input];
    let [hours, minutes] = time ? time.split(':') : ['', ''];
    if (days === undefined || days === null) {
      days = '';
    }
    days = days || '';
    hours = hours || '';
    minutes = minutes || '';
    return { days, hours, minutes };
  };

  const [boxState, setBoxState] = React.useState<ControlState>('neutral');
  const [errorString, setErrorString] = React.useState<string>('');
  const [day, setDay] = React.useState<string>('');
  const [hour, setHour] = React.useState<string>('');
  const [minute, setMinute] = React.useState<string>('');
  const [internalDuration, setInternalDuration] = React.useState<string>('');

  const validateValue = () => {
    if (props.hidden !== undefined && props.hidden) {
      return true;
    }
    if (props.readonly !== undefined && props.readonly) {
      return true;
    }

    if (props.showDays && (day === '' || day === null)) {
      if (
        props.requiredDetails !== undefined ||
        props.requiredDetails !== null
      ) {
        setErrorString(props.invalidDurationText);
        setBoxState('negative');
        return false;
      }
    }

    if (props.showTime === true) {
      if (!props.requiredDetails) {
        if (hour === '' || (hour === null && parseInt(hour) < 24)) {
          setErrorString(props.invalidDurationText);
          setBoxState('negative');
          return false;
        }

        if (minute === '' || (minute === null && parseInt(minute) < 60)) {
          setErrorString(props.invalidDurationText);
          setBoxState('negative');
          return false;
        }
      }
    }

    setErrorString('');
    setBoxState('positive');
    return true;
  };

  const handleInputChange = (fieldName: string, fieldValue: string) => {
    if (props.readonly) {
      return;
    }
    if (props.showDays && fieldName === 'days') {
      setDay(fieldValue);
      setHour('00');
      setMinute('00');
      validateValue();
    }
    if (fieldName === 'hours') {
      setHour(fieldValue);
    }
    if (fieldName === 'minutes') {
      setMinute(fieldValue);
    }

    if (props.onChange) {
      var asTimeSpan = props.showDays
        ? `${day}.${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`
        : `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`;
      props.onChange({
        id: props.id,
        value: asTimeSpan,
        fieldId: props.fieldId,
      });
      setInternalDuration(asTimeSpan);
    }
  };

  const handleBlurChange = (fieldName: string, fieldValue: string) => {
    if (props.readonly) {
      return;
    }

    if (fieldName === 'hours') {
      setHour(fieldValue.padStart(2, '0'));
    }
    if (fieldName === 'minutes') {
      setMinute(fieldValue.padStart(2, '0'));
    }

    if (validateValue()) {
      if (props.onChange) {
        var asTimeSpan = props.showDays
          ? `${day}.${hour}:${minute}:00`
          : `${hour}:${minute}:00`;

        props.onChange({
          id: props.id,
          value: asTimeSpan,
          fieldId: props.fieldId,
        });

        setInternalDuration(asTimeSpan);
      }
    }
  };

  React.useImperativeHandle(ref, () => ({
    triggerValidation: () => validateValue(),
    reset: () => setBoxState('neutral'),
  }));

  React.useEffect(() => {
    const { days, hours, minutes } = breakDuration(props.value);
    setDay(days);
    setHour(hours);
    setMinute(minutes);
    setInternalDuration(
      props.value !== undefined && props.value !== null ? props.value : ''
    );
  }, [props.value]);

  return (
    <FormControllerContainer
      id={props.id}
      hidden={props.hidden}
      boxState={boxState}
      readonly={props.readonly}
      label={props.label}
      errorMessage={errorString}
      helpMessage={props.helpMessage}
      insertPadder={true}
      requiredText={
        !props.requiredDetails ? undefined : props.requiredDetails.formLabel
      }
      displayMode={props.displayMode}
    >
      <div>
        <input id={props.id} hidden={true} value={internalDuration}  readOnly></input>
      </div>

      <div className="Duration-Box">
        {props.showDays === true ? (
          <div className="duration-div duration-days">
            <label htmlFor={`${props.id}_days`} className="duration-font">
              {props.labels.days}
            </label>
            <input
              className="duration-font"
              type="numeric"
              maxLength={3}
              id={`${props.id}_days`}
              name="days"
              data-testid={`${props.id}_days`}
              value={day}
              onChange={(e) => handleInputChange('days', e.target.value)}
              onBlur={(e) => handleInputChange('days', e.target.value)}
              readOnly={props.readonly}
            />
          </div>
        ) : null}
        {props.showTime === true ? (
          <>
            <div className="duration-div duration-hours">
              <label htmlFor={`${props.id}_hours`} className="duration-font">
                {props.labels.hours}
              </label>
              <input
                className="duration-font"
                type="numeric"
                maxLength={2}
                id={`${props.id}_hours`}
                data-testid={`${props.id}_hours`}
                name="hours"
                value={hour}
                onChange={(e) => handleInputChange('hours', e.target.value)}
                onBlur={(e) => handleBlurChange('hours', e.target.value)}
                readOnly={props.readonly}
              />
            </div>

            <div className="duration-div duration-minutes">
              <label htmlFor={`${props.id}_minutes`} className="duration-font">
                {props.labels.minutes}
              </label>
              <input
                className="duration-font"
                type="numeric"
                maxLength={2}
                max={60}
                id={`${props.id}_minutes`}
                name="minutes"
                data-testid={`${props.id}_minutes`}
                value={minute}
                onChange={(e) => handleInputChange('minutes', e.target.value)}
                onBlur={(e) => handleBlurChange('minutes', e.target.value)}
                readOnly={props.readonly}
              />
            </div>
          </>
        ) : (
          <>
            <div></div>
            <div></div>
          </>
        )}
        {props.showDays !== true ? <div></div> : null}
        <div></div>
      </div>
    </FormControllerContainer>
  );
};

export default React.forwardRef(FormDuration);
