import React, { FC } from 'react';
import KeyValueLabelTypes from './KeyValueLabel.types';
import './KeyValueLabel.css';
import SideBar from '../../reusableBlocks/sideBar/SideBar';
const KeyValueLabel: FC<KeyValueLabelTypes.Props> = (props) => {
  return (
    <>
      <SideBar
        FieldKey="KeyValueLabel"
        boxState="locked"
        includeInnerBar
        className={`Key-Label-Bar${props.isTop ? ' Top' : ''}`}
        key={`${props.id}-Label-Bar`}
      />
      <span className={`Text-Regular Key-Label${props.isTop ? ' Top' : ''}`} key={`${props.id}-Label`}>
        {props.label}
      </span>
      <SideBar
        FieldKey="KeyValueLabel"
        boxState="locked"
        includeInnerBar
        className={`Key-Value-Bar${props.isTop ? ' Top' : ''}`}
        key={`${props.id}-Value-Bar`}
      />
      <span className={`Text-Regular Key-Value${props.isTop ? ' Top' : ''}`} key={`${props.id}-Value`}>
        {props.value}
      </span>
    </>
  );
};

export default KeyValueLabel;
