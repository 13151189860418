
import {
  createAction,
  confirmAction,
  fetchAction,
  updateAction,
  partialAction,
} from '../../systemUtils/common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import ExternalUser_Types from '../../sysObjects/apiModels/ExternalUser.types';
import { LocalEnumerations } from '../common/CommonHelpers';
import System_Types from '../../sysObjects/apiModels/System.types';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import CustomerTypes from '../../sysObjects/apiModels/Customer.types';

namespace ExternalUsersActions {
  /**
   *
   * @param {string} orgId The id of the organisation to search in
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information
   * @returns {FunctionalResult<ExternalUser_Types.listItem[]>} The result of the action
   */
  export const customerLookUpAsync = async (
    orgId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<ExternalUser_Types.listItem[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ExternalUserAccounts.customersByReferrer
        .path!}/${orgId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Saves the user object.
   * @param headerInfo The header information to pass to the calling method
   * @param obj The record to save.
   * @param id The id of the user
   * @returns The result of the action
   */
  export const saveUserAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: ExternalUser_Types.User,
    id?: string
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (headerInfo.isFailure) {
      return FunctionalResult.Error('Failed to get headerInfo');
    }
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    if (id) {
      return updateAction({
        hostPath: host!.result!.path,
        apiPath: `${settings.ExternalUserAccounts.update.path!}/${id}`,
        userId: headerInfo.result!.userId,
        accessToken: headerInfo.result!.token,
        formData: obj,
      });
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.ExternalUserAccounts.create.path!,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
      formData: obj,
    });
  };

  /**
   * Sets the user state
   * @param headerInfo The header information to pass to the calling method
   * @param userID The id of the user to set the status for
   * @param id The id of the status to set
   * @returns
   */
  export const setUserState = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    userID: string,
    id: number
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (headerInfo.isFailure) {
      return FunctionalResult.Error('Failed to get headerInfo');
    }
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return partialAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ExternalUserAccounts.setState.path}/${userID}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
      formData: { status: id },
    });
  };

  /**
   * Gets the internal user by the user id
   * @param headerInfo The header information to pass to the calling method
   * @param usrId The id of the user to get
   * @returns
   */
  export const getUserAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    usrId: string
  ): Promise<FunctionalResult<ExternalUser_Types.User>> => {
    const host = await getHostConfigAsync();
    if (headerInfo.isFailure) {
      return FunctionalResult.Error('Failed to get headerInfo');
    }
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ExternalUserAccounts.get.path}/${usrId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   *  Gets the Customers with the current statuses
   * @param headerInfo The header information to pass to the calling method
   * @param query The query settings
   * @returns FunctionalResult<IExternalUserList[]>
   */
  export const findUsersAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    query: ExternalUser_Types.queryUserObj
  ): Promise<FunctionalResult<ExternalUser_Types.listItem[]>> => {
    if (headerInfo.isFailure) {
      return FunctionalResult.Error('Failed to load header details');
    }

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    var params: string[] = [];
    var paramString = '';

    if (query.status !== undefined) {
      query.status.forEach((status) => {
        params.push(
          `${settings.ExternalUserAccounts.findUsers.statusQuery}=${status}`,
        );
      });
    }

    if (query.role) {
      params.push(
        `${settings.ExternalUserAccounts.findUsers.roleQuery}=${query.role}`,
      );
    }

    if (query.orgId !== undefined) {
      params.push(
        `${settings.ExternalUserAccounts.findUsers.orgQuery}=${query.orgId}`,
      );
    }

    if (params.length > 0) {
      paramString = `?${params.join('&')}`;
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ExternalUserAccounts.findUsers.path}${paramString}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Requests the invite token to be resent
   * @param id
   * @param {string} usr the usrId to pass to the calling method
   * @returns
   */
  export const sendRefreshToken = async (
    headerDetails: FunctionalResult<System_Types.ApiHeader>,
    id: string,
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.ExternalUserAccounts.refreshToken.path}?${settings.ExternalUserAccounts.refreshToken.query}=${id}`,
      userId: headerDetails.result!.userId,
      accessToken: headerDetails.result!.token,
    });
  };

  export const getCustomerProfileAsync = async (
    headerDetails: FunctionalResult<System_Types.ApiHeader>,
    id: string,
  ): Promise<FunctionalResult<CustomerTypes.Profile>> => {
    if (headerDetails.isFailure) {
      return FunctionalResult.Error('Failed to load header details');
    }

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Customers.get.path}/${id}`,
      userId: headerDetails.result!.userId,
      accessToken: headerDetails.result!.token,
    });
  };

  export const saveCustomerProfileAsync = async (
    headerDetails: FunctionalResult<System_Types.ApiHeader>,
    customerProfile: CustomerTypes.Profile,
    id?: string,
  ) => {
    if (headerDetails.isFailure) {
      return FunctionalResult.Error('Failed to load header details');
    }

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (id) {
      return updateAction({
        hostPath: host!.result!.path,
        apiPath: `${settings.Customers.update.path!}`,
        userId: headerDetails.result!.userId,
        accessToken: headerDetails.result!.token,
        formData: customerProfile,
      });
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.Customers.create.path!,
      userId: headerDetails.result!.userId,
      accessToken: headerDetails.result!.token,
      formData: customerProfile,
    });
  };

  /** Creates an empty object for the user interface to bind to. */
  export const createCustomerProfile = (): CustomerTypes.Profile => {
    return {
      userAccountId: '',
      employmentHistory: '',
      employmentType: undefined,
      pronouns: undefined,
      roleAndResponsibilities: '',
      preferredName: '',
      lengthOfEmployment: '',
      employmentContactName: '',
      customerInEducation: null,
    };
  };
  /** Creates an empty object for the user interface to bind to. */
  export const createCustomerInEducation =
    (): CustomerTypes.CustomerInEducation => {
      return {
        courseName: '',
        lengthOfCourse: '',
        universityCollegeName: '',
        yearOfStudy: '',
      };
    };

  /** Creates an empty object for the user interface to bind to. */
  export const createExternalAccount = (): ExternalUser_Types.User => {
    return {
      name: '',
      otherNames: '',
      surname: '',
      primaryEmail: '',
      secondaryEmail: '',
      birthDate: null,
      homeAddress: {
        line1: '',
        line2: '',
        line3: '',
        country: '',
        postalCode: '',
      },
      ethnicity: undefined,
      disabilities: [],
      gender: undefined,
      role: LocalEnumerations.Roles.Customer,
      jobTitle: '',
      status: 1,
      primaryTelephoneNumber: '',
      secondaryTelephoneNumber: '',
      organisationId: '',
      msBookingsBusinessId: undefined,
      msBookingsStaffMemberId: undefined,
    };
  };
}

export default ExternalUsersActions;
