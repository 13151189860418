import React from "react";
import { AccountInfo, Configuration, EndSessionRequest, LogLevel, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { AzureConfig, HostConfig, getHostConfigAsync } from "../../systemUtils/common/HostConfigActions";
import { FunctionalResult } from "../../sysObjects/FunctionalResult";
import { ConfigContext } from "../sharedControls/contexts/ConfigContext";
import logger from "../../systemUtils/Logger";
export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [msalConfig, setMsalConfig] = React.useState<Configuration | null>(null);
  const [initialisationComplete, setInitialisationComplete] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!initialisationComplete) {
      setInitialisationComplete(true);

      getHostConfigAsync().then((config: FunctionalResult<HostConfig>) => {
        if (config === null || config.isFailure) {
          return;
        }

        createMsalConfig(config!.result!.azure).then((msalConfig) => {
          if (config) {
            setMsalConfig(msalConfig);
          }
        });
      })
    }
  })

  async function createMsalConfig(config: AzureConfig): Promise<Configuration | null> {
    if (config) {
      const msalConfig = {
        auth: {
          clientId: config.clientId,
          authority: config.authority,
          redirectUri: '/passback',
          postLogoutRedirectUri: '/',
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: config.cacheLocation,
          storeAuthStateInCookie: true,
        },
        system: {
          loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
              if (!containsPii) {
                 switch (level) {
                  case LogLevel.Error:
                    logger.error(message);
                    break;
                  case LogLevel.Info:
                    logger.info(message);
                    break;
                  case LogLevel.Verbose:
                    logger.debug(message);
                    break;
                  case LogLevel.Warning:
                    logger.warn(message);
                    break;
                  default:
                    break;
                }
              }
            },
          },
        },
      };

      return msalConfig;
    }

    return null;
  }

  return (
    <ConfigContext.Provider value={{ msalConfig, initialisationComplete, updateConfig: setMsalConfig }}>
      {children}
    </ConfigContext.Provider>
  )
}

export const silentRequest = (currentAccount: AccountInfo): SilentRequest => {
  return {
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    account: currentAccount,
    forceRefresh: true,
    refreshTokenExpirationOffsetSeconds: 7200
  };
};

export const redirectRequest: RedirectRequest = {
  scopes: [],
  prompt: 'select_account'
}