import React from 'react';
import ButtonTypes from './Button.types';

import './Button.css';

const Button: React.FC<ButtonTypes.Props> = (props) => {
  const [isDisabled, setIsDisabled] = React.useState(false);

  const handleClick = (e: any) => {
    if (!isDisabled) {
      setIsDisabled(true);
      if (props.clickEvent) {
        props.clickEvent(e);
      }

      if(props.useCooldown === false) {
        setIsDisabled(false);
        return;
      }

      setTimeout(() => {
        setIsDisabled(false);
      }, 5000);
    }
  };
  const getClass = () => {
    if (!props.mode) {
      return '';
    }
    return props.mode;
  };

  return (
    <button
      key={props.itemKey}
      type="button"
      className={`lexxic-button Action-Regular ${getClass()}`}
      onClick={handleClick}
      disabled={props.disabled ? props.disabled : isDisabled}
      aria-label={props.ariaLabel}
      aria-describedby={props.ariaDescribedBy}
      data-testid={props.testId}
    >
      {props.children && props.children}
      <label className="btnLabel">{props.label}</label>
    </button>
  );
};

export default Button;
