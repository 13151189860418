import { FC, useEffect, useState } from "react";
import { DiagnosisRecordListTypes } from "./DiagnosisRecordList.types";
import { DiagnosisRecordRowTypes } from "./DiagnosisRecordRow/DiagnosisRecordRow.types";
import ListTableSearch from "../../sharedControls/tables/listTableSearch/ListTableSearch";
import ListTable from "../../sharedControls/tables/listTable/ListTable";
import { filterTable, sortTable } from "../../../systemUtils/common/SortSearchHelpers";
import PageLoader from "../../sharedControls/general/loading/pageLoader/PageLoader";
import { ColDetails, colSearchDetails } from "../../../sysObjects/common.types";
import DiagnosisRecordRow from "./DiagnosisRecordRow/DiagnosisRecordRow";
import SortableHeader from "../../sharedControls/tables/listTable/headers/sortable/SortableHeader";
import StandardHeader from "../../sharedControls/tables/listTable/headers/standard/StandardHeader";
import AddDiagnosisRecordRow from "./AddDiagnosisRecordRow/AddDiagnosisRecordRow";
import CustomerTypes from "../../../sysObjects/apiModels/Customer.types";

const DiagnosisRecordList: FC<DiagnosisRecordListTypes.Props> = (props) => {
    const [sortColumnDetails, setSortColumnDetails] = useState<colSearchDetails>({
        field: 'diagnosisDate',
        searchDirection: 'desc',
        searchType: 'date',
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [rows, setRows] = useState<DiagnosisRecordRowTypes.Item[]>([]);
    const [showTextSearch, setShowTextSearch] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const fieldsToSearch: (keyof DiagnosisRecordRowTypes.Item)[] = ['diagnosisDate', 'diagnosisSource', 'addedByName', 'diagnosedCondition'];

    useEffect(() => {
        setRows(props.rows);
        setIsLoading(false);
    }, [props.rows]);

    const saveRecord = (record: CustomerTypes.DiagnosisRecord) => {
        if(!props.events?.onAddDiagnosisRecord) return;

        setIsLoading(true);
        props.events.onAddDiagnosisRecord(record).then(() => {
            if (props.triggerRefresh) {
                props.triggerRefresh();
            }
            setIsLoading(false);
        });
    };

    return isLoading ? (
        <PageLoader alt={props.labels.common.load} />
    ) : (
        rows && (
            <>
                <ListTable
                    isLoading={props.isLoading}
                    search={{
                        isVisible: showTextSearch,
                        onStateChange: (isExpanded: boolean) => setShowTextSearch(isExpanded),
                    }}
                    pagingDetails={props.pagingDetails}
                    rows={rows && rows.length > 0
                        ?
                        sortTable(
                            filterTable(rows, searchString, fieldsToSearch),
                            sortColumnDetails.field as keyof DiagnosisRecordRowTypes.Item,
                            sortColumnDetails.searchDirection === 'none'
                                ? 'asc'
                                : sortColumnDetails.searchDirection,
                            sortColumnDetails.searchType
                        ).map((item) => (
                            <DiagnosisRecordRow
                                key={item.id}
                                item={item}
                                labels={props.labels.rowLabels}
                                readonly={props.readOnly} />
                        ))
                        : []}
                    className="Diagnosis-Record-Table"
                    labels={{
                        of: props.labels.table.of,
                        size: props.labels.table.size,
                        tableName: props.labels.table.tableName,
                        emptyMessage: props.labels.table.emptyMessage,
                    }}
                    defaultItem={props.readOnly
                        ? undefined
                        : <AddDiagnosisRecordRow
                            onSave={saveRecord}
                            labels={props.labels.addRowLabels}
                            relatedConditions={props.enumerations.relatedConditions}
                            diagnosisSources={props.enumerations.diagnosisSources}
                        />
                    }
                >
                    <ListTable.Headers>
                        <div className="Heading Text-Regular" />
                        {props.cols.map((col: ColDetails, index: number) => {
                            return col.style === 'sortable' ? (
                                <SortableHeader
                                    key={`${col.id}-${index}`}
                                    id={col.id}
                                    name={col.name}
                                    fieldName={col.field}
                                    searchType={col.searchType}
                                    sortDirection={
                                        col.field === sortColumnDetails.field
                                            ? sortColumnDetails.searchDirection
                                            : 'none'
                                    }
                                    onSortSelected={(name, searchType, sortDirection) => {
                                        setSortColumnDetails({
                                            field: name,
                                            searchDirection: sortDirection,
                                            searchType: searchType as
                                                | 'string'
                                                | 'number'
                                                | 'boolean'
                                                | 'date',
                                        });
                                    }}
                                />
                            ) : (
                                <StandardHeader
                                    id={col.id}
                                    name={col.name}
                                    key={`${col.id}-${index}`}
                                />
                            );
                        })}
                    </ListTable.Headers>
                    <ListTable.SearchOptions>
                        {showTextSearch && (
                            <ListTableSearch
                                Labels={{ searchButton: props.labels.search }}
                                onSearch={(message: string) => setSearchString(message)}
                            />
                        )}
                    </ListTable.SearchOptions>
                </ListTable>
            </>
        ));
};
export default DiagnosisRecordList;