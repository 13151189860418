import React, { useEffect, useContext, useRef } from 'react';
import { TaskActions } from '../../../systemUtils/task/TaskActions';
import { useIntl } from 'react-intl';
import  Enumerations, { getHeadersAsync }  from '../../../systemUtils/common/CommonHelpers';
import { useMsal } from '@azure/msal-react';
import { TaskItemProps } from './tasks/taskItem/TaskItem.types';
import TaskList from './tasks/taskList/TaskList';
import { UserClaimsContext } from '../../sharedControls/contexts/UserClaimsContext';

const ViewTasks: React.FC = () => {
  const intl = useIntl();
  const messages = require(`./locales/${intl.locale}.json`);

  const [taskList, setTaskList] = React.useState<TaskItemProps[]>([]);
  const loading = useRef<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const { userClaims } = useContext(UserClaimsContext);
  const taskStatusesLoaded = useRef<boolean>(false);
  const [refreshRequired, setRefreshStatus] = React.useState<boolean>(false);
  const { instance } = useMsal();

  useEffect(() => {
    if(taskStatusesLoaded.current){
      return;
    }
    taskStatusesLoaded.current = true;
  }, [refreshRequired]);

  useEffect(() => {
    const loadTasksAsync = async () => {
      const headers = await getHeadersAsync(userClaims, instance);
      await TaskActions.listTasksAsync(
        {
          assignedUserAccountId: userClaims!.user!.userAccountId,
        },
        headers
      )
        .then((taskResult) => {
          loading.current = false;
          if (taskResult.isFailure) {
            setError(messages.errorMessage);
            return;
          }
  
          if (!taskResult.result || taskResult.result.length === 0) {
            setError(messages.noTasks);
            return;
          }
          const status = Enumerations.getTaskStatuses(intl.locale);
          setTaskList(
            taskResult.result!.map((task) => {
              return {
                id: task.id,
                headerLabel: task.label,
                notes: [task.text],
                createdBy: task.assignedByUserFullName,
                completed: task.completedDateTime !== null,
                completedDate: task.completedDateTime,
                createdDateTime: task.createdDateTime,
                dueDate: task.dueDate ,
                caseReference: task.caseReference,
                status: status.find((s) => s.key === task.status)?.value ||'',
                completable: task.completable,
                controlState: task.completedDateTime
                  ? 'positive'
                  : task.dueDate && new Date() > new Date(task.dueDate)
                  ? 'negative'
                  : 'neutral',
              };
            })
          );
  
          setRefreshStatus(false);
        })
        .catch((error) => {
          setError(messages.errorMessage);
        });
    };

    if (taskStatusesLoaded.current && !loading.current) {
      loading.current = true;
      loadTasksAsync();
    }
  }, []);

  return (
    <TaskList
      Label="Tasks"
      tasks={taskList}
      key="tasks"
      isLoading={loading.current}
      errorMessage={error}
      CompleteTicked={async (id) => {
        TaskActions.markTaskCompleteAsync(
          id,
          await getHeadersAsync(userClaims, instance)
        ).then((result) => {
          if (result.isFailure) {
            setError(messages.errorMessage);
            return;
          }
          setTaskList((prevTaskList) =>
            prevTaskList.map((task) =>
              task.id === id ? { ...task, completed: true } : task
            )
          );
        });
        setRefreshStatus(true);
      }}
    />
  );
};

export default ViewTasks;
