import React from 'react';
import { FormControlRef } from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import Expander from '../../../../sharedControls/general/expander/Expander';
import AddRecommendationTypes from './AddRecommendation.Types';
import FormSearchableDropDown from '../../../../sharedControls/formControls/formSearchableDropDown/FormSearchableDropDown';
import FormSearchableDropDownTypes from '../../../../sharedControls/formControls/formSearchableDropDown/FormSearchableDropDown.types';
import ServiceRecommendation_Types from '../../../../../sysObjects/apiModels/ServiceRecommendation.Types';
import './AddRecommendation.css';

const AddRecommendation: React.FC<AddRecommendationTypes.Props> = (props) => {
  const formRef = React.useRef<FormControlRef>(null);
  const [isExpanded, setExpanded] = React.useState(false);
  const [serviceDefinitionId, setServiceDefinitionId] = React.useState('');

  return (
    <div className="Recommendation-Adder">
      <Expander
        id={`AddRecommendation_Status`}
        key={`AddRecommendation_Status`}
        isExpanded={isExpanded}
        eventHandler={{ onClick: () => setExpanded(!isExpanded) }}
      />
      <div className="Action-Title Text-Regular">
        {props.labels.addRecommendationTitle}
      </div>
      {isExpanded && (
        <div className="Recommendation-Details">
          <FormSearchableDropDown
            items={props.serviceDefinitions.map(e => {
              return {
                key: e.key,
                value: e.value
              } as FormSearchableDropDownTypes.SearchableItem;
            }) ?? []}
            fieldId="serviceDefinitionId"
            id="add_recommendation_serviceDefinitionId"
            label={props.labels.serviceLabel}
            defaultText={props.labels.serviceDefaultText}
            value={serviceDefinitionId}
            onChange={(e) => {
              setServiceDefinitionId(e.value as string);
            }}
            displayMode="Column"
            requiredDetails={
              {
                formLabel: '',
                message: props.labels.serviceRequiredMessage,
              }
            }
            ref={formRef}
          />
          <div className="col Text-Regular">{props.labels.actionsLabel}</div>
          <ButtonBox
            id="recommendationAdd"
            displayBorder={true}
            buttons={[
              {
                controlState: serviceDefinitionId === '' ? 'locked' : 'positive',
                isDisabled: serviceDefinitionId === '',
                label: props.labels.btnSave,
                id: 'recommendationAdd',
                onClick: () => {
                  if (!formRef.current?.triggerValidation()) {
                    return;
                  }

                  const recommendation = {
                    caseId: props.caseId,
                    serviceDefinitionId: serviceDefinitionId,
                  } as ServiceRecommendation_Types.ServiceRecommendation;

                  if (!props.events.addRecommendation) return;

                  props.events.addRecommendation(recommendation).then((result) => {
                    if (result.isSuccess) {
                      setServiceDefinitionId('');
                      setExpanded(false);
                    }
                  });
                },
              },
              {
                controlState: 'negative',
                label: props.labels.btnCancel,
                id: 'recommendationCancel',
                onClick: () => {
                  setServiceDefinitionId('');
                  setExpanded(false);
                },
              },
            ]}
            key={`recommendationAddButtonBox`}
          />
        </div>
      )}
    </div>
  );
};

export default AddRecommendation;
