import React, { useRef } from 'react';
import { AddDiagnosisRecordRowTypes } from './AddDiagnosisRecordRow.types';
import Expander from '../../../sharedControls/general/expander/Expander';
import ButtonBox from '../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import ButtonBoxTypes from '../../../sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import CustomerTypes from '../../../../sysObjects/apiModels/Customer.types';
import DiagnosisRecordActions from '../../../../systemUtils/userUtils/DiagosisRecordActions';
import { FormControlOnChangeData, FormControlRef } from '../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import FormDropDown from '../../../sharedControls/formControls/formDropDown/FormDropDown';
import FormDate from '../../../sharedControls/formControls/formDate/FormDate';
import FormTextArea from '../../../sharedControls/formControls/formTextArea/FormTextArea';
import './AddDiagnosisRecordRow.css';

const AddDiagnosisRecordRow: React.FC<AddDiagnosisRecordRowTypes.Props> = (props) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const [formData, setFormData] = React.useState<CustomerTypes.DiagnosisRecord>(
    DiagnosisRecordActions.createDefaultDiagnosisRecord()
  );
  const formRefs = [
    useRef<FormControlRef>(null), // condition
    useRef<FormControlRef>(null), // date
    useRef<FormControlRef>(null), // diagnosis source
    useRef<FormControlRef>(null), // notes
  ];

  const validateFormData = () => {
    let isValid = true;

    formRefs.forEach((ref) => {
      if (!ref.current?.triggerValidation()) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleSelectChange = (
    result: FormControlOnChangeData<string | string[] | number | null>,
  ) => {
    if (result.value === null || result.value === '') {
      return;
    }

    if (Array.isArray(result.value)) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]:
        typeof result.value === 'number'
          ? result.value
          : parseInt(result.value! as string, 10),
    }));
  };

  const handleDateChange = (result: FormControlOnChangeData<Date | null>) => {
    if (!result.value) {
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: null,
      }));
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: new Date(result.value!) || null,
    }));
  };

  const handleSave = () => {
    if (!props.onSave) {
      return;
    }

    if(!validateFormData()) {
      return;
    }

    props.onSave(formData);
  };

  const handleCancel = () => {
    setExpanded(false);
    setFormData(DiagnosisRecordActions.createDefaultDiagnosisRecord());
  };

  const renderActions = () => {
    return (
      <>
        <div>{props.labels.actions}</div>
        <div className="Colspan">
          <div className="Button-Box">
            <ButtonBox
              id="add-diagnosis-action-buttons"
              key="add-diagnosis-action-buttons"
              displayBorder={true}
              buttons={[
                {
                  label: props.labels.saveButtonLabel,
                  id: "save-diagnosis",
                  controlState: validateFormData() ? 'positive' : 'neutral',
                  onClick: () => handleSave(),
                  isDisabled: !validateFormData(),
                },
                {
                  label: props.labels.cancelButtonLabel,
                  id: "cancel-save-diagnosis",
                  controlState: 'neutral',
                  onClick: () => handleCancel(),
                }
              ] as ButtonBoxTypes.Button[]}
            />
          </div>
        </div>
      </>
    );
  };

  const renderExpandedRow = () => {
    return (
      <>
        <div />
        <div className='AddDiagnosis-Row'>
          <FormDropDown
            items={props.relatedConditions}
            fieldId="diagnosedCondition"
            id="add_diagnosis_condition"
            label={props.labels.diagnosedCondition}
            value={formData.diagnosedCondition}
            onChange={(e) => handleSelectChange(e)}
            requiredDetails={{
              formLabel: props.labels.required,
              message: `${props.labels.diagnosedCondition} ${props.labels.requiredText}`,
            }}
            ref={formRefs[0]}
            defaultText={props.labels.conditionDefaultText}
            displayMode={'Column'}
          />

          <FormDate
            invalidDateText={props.labels.invalidDateText}
            datePartLabels={{
              day: props.labels.datePartLabels.day,
              month: props.labels.datePartLabels.month,
              year: props.labels.datePartLabels.year
            }}
            fieldId="dateDiagnosed"
            id="add_diagnosis_date"
            label={props.labels.diagnosisDate}
            value={formData.dateDiagnosed}
            ref={formRefs[1]}
            requiredDetails={{
              formLabel: props.labels.required,
              message: `${props.labels.diagnosisDate} ${props.labels.requiredText}`,
            }}
            onChange={(e) => handleDateChange(e)}
            displayMode={'Column'}
          />

          <FormDropDown
            items={props.diagnosisSources}
            fieldId="diagnosisSource"
            id="add_diagnosis_source"
            label={props.labels.diagnosisSource}
            value={formData.diagnosisSource}
            onChange={(e) => handleSelectChange(e)}
            requiredDetails={{
              formLabel: props.labels.required,
              message: `${props.labels.diagnosisSource} ${props.labels.requiredText}`,
            }}
            ref={formRefs[2]}
            defaultText={props.labels.sourceDefaultText}
            displayMode={'Column'}
          />

          <FormTextArea
            fieldId="notes"
            id="add_diagnosis_notes"
            label={props.labels.notes}
            value={formData.notes}
            ref={formRefs[3]}
            onChange={(e) => handleFormChange(e)}
            displayMode={'Column'}
          />

          {renderActions()}
        </div>
      </>
    );
  };

  const handleExpand = () => {
    const expanded = !isExpanded;
    if(!expanded) {
      handleCancel();
    }
    setExpanded(expanded);
  };

  const renderHeader = () => {
    return (
      <>
        <Expander
          id="add-diagnosis-expander"
          key="add-diagnosis-expander"
          isExpanded={isExpanded === true}
          eventHandler={{ onClick: () => handleExpand() }}
        />
        <div className="td Action-Title">{props.labels.addDiagnosisTitle}</div>
      </>
    )
  };

  const renderRow = () => {
    return (
      <>
        {renderHeader()}
        {isExpanded && renderExpandedRow()}
      </>
    )
  };

  return (
    <div
      className="Add-Diagnosis-Record"
      data-testid="add-diagnosis-record-row"
      key="add-diagnosis-record-row"
    >
      {renderRow()}
    </div>
  );
};

export default AddDiagnosisRecordRow;