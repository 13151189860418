import React, { FC } from 'react';
import DocumentTypes from './DocumentRow.types';
import StatusLabel from '../../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import { SvgClock, SvgDocument } from '../../../../sharedControls/svg';
import Expander from '../../../../sharedControls/general/expander/Expander';
import Button from '../../../../sharedControls/general/button/Button';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import { ControlState } from '../../../../../sysObjects/common.types';

const DocumentRow: FC<DocumentTypes.Props> = (props) => {
  const [showStateChange, setShowStateChange] = React.useState(false);
  const getExtension = (fileName: string) => {
    const split = fileName.split('.').pop();
    if (split) {
      if (split.toLocaleLowerCase() === 'pdf') {
        return '.pdf (PDF)';
      }
      if (
        split.toLocaleLowerCase() === 'docx' ||
        split.toLocaleLowerCase() === 'doc'
      ) {
        return `.${split.toLocaleLowerCase()} (Word)`;
      }
      return `.${split.toLocaleLowerCase()}`;
    }
    return '';
  };

  const renderExpanded = () => {
    return (
      <div className="Summary-Details">
        <div className="col">
          <span className="Text-Regular">{props.labels.visibility}</span>
        </div>
        <div className="value-col">
          <StatusLabel
            label={props.document.visibilityLabel}
            renderAs="span"
            status="neutral"
          />
        </div>
        <div className="col">
          <span className="Text-Regular">{props.labels.typeOfDocument}</span>
        </div>
        <div className="value-col Two-Col">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            showIcon={false}
            allowHover={false}
            content={<SvgDocument className="icon" />}
          />
          <span className="Text-Regular" data-testid="fileType">
            {getExtension(props.document.documentName)}
          </span>
        </div>
        <div className="col">
          <span className="Text-Regular">{props.labels.uploadedBy}</span>
        </div>
        <div className="value-col Two-Col">
          <StatusBlock
            boxSize="small"
            boxState="locked"
            showIcon={false}
            allowHover={false}
            className="Status-User-Int"
            content={props.document.uploadedByInt}
          />
          <span className="Text-Regular">{props.document.uploadedBy}</span>
        </div>
        <div className="col">
          <span className="Text-Regular">{props.labels.uploadedOn}</span>
        </div>
        <div className="value-col Four-Col">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            showIcon={false}
            allowHover={false}
            content={<SvgClock className="icon" />}
          />
          <span className="Text-Regular">{props.document.uploadedOnTime}</span>
          <StatusBlock
            boxSize="small"
            boxState="positive"
            showIcon={false}
            allowHover={false}
            content={<SvgClock className="icon" />}
          />
          <span className="Text-Regular">{props.document.uploadedOnDate}</span>
        </div>

        {props.document.possibleVisibility.length > 0 && (
          <>
            <div className="col">
              <span className="Text-Regular">*Action</span>
            </div>
            <div className="value-col Two-Col">
              <ButtonBox
                displayBorder={true}
                className={`Change-Visibility-Button-Confirm`}
                id={`Download-${props.document.documentId}-Button`}
                key={`Download-${props.document.documentId}-Button`}
                buttons={[
                  {
                    id: `Download-${props.document.documentId}-Change-Button`,
                    label: props.labels.changeVisibility,
                    controlState: 'positive',
                    isChecked: showStateChange,
                    onClick: () => {
                      setShowStateChange(!showStateChange);
                    },
                  },
                ]}
              />
              {showStateChange && (
                <ButtonBox
                  displayBorder={true}
                  className={`Change-Visibility-States`}
                  id={`Download-${props.document.documentId}-Button`}
                  key={`Download-${props.document.documentId}-Button`}
                  buttons={[
                    ...props.document.possibleVisibility.filter((state) => state.key !== props.document.visibilityId).map((state) => {
                      return {
                        id: `Download-${props.document.documentId}-${state}-Button`,
                        label: state.value,
                        controlState: 'positive' as ControlState,
                        onClick: () => {
                          props.events.changeDocumentVisibility(
                            props.document.documentId,
                            props.document.documentName,
                            state.key,
                            state.value,
                          );
                        },
                      };
                    }),
                    {
                      id: `Download-${props.document.documentId}-Cancel-Button`,
                      label: props.labels.cancelButton,
                      controlState: 'negative' as ControlState,
                      onClick: () => setShowStateChange(false),
                    },
                  ]}
                />
              )}
            </div>
          </>
        )}
        {props.document.note && (
          <>
            <div>
              <span className="Text-Regular">{props.labels.note}</span>
            </div>
            <div>
              <span className="Text-Regular">{props.document.note}</span>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="DocumentRow">
      <div className="Expand-Box">
        <Expander
          isExpanded={props.document.isExpanded}
          eventHandler={{
            onClick: () =>
              props.events.expandIconClicked(
                props.document.documentId,
                !props.document.isExpanded,
              ),
          }}
          id={`documentRow-${props.document.documentId}`}
          key={`documentRow-${props.document.documentId}`}
        />
      </div>
      <div>
        <span>{props.document.documentName.split('.')[0]}</span>
      </div>
      {!props.document.isExpanded && (
        <>
          <div>
            <StatusLabel
              label={props.document.visibilityLabel}
              renderAs="span"
              status="neutral"
            />
          </div>
          <div>
            <span>{props.document.timeSinceEdit}</span>
          </div>
        </>
      )}

      <div className="Buttons">
        <Button
          itemKey={`Download-${props.document.documentId}-Button`}
          testId={`Download-${props.document.documentId}-Button`}
          key={`Download-${props.document.documentId}-Button`}
          label={props.labels.download}
          clickEvent={() =>
            props.events.downloadClicked(
              props.document.documentId,
              props.document.caseId,
              props.document.userId,
            )
          }
          mode="positive"
        />
      </div>
      {props.document.isExpanded && renderExpanded()}
    </div>
  );
};

export default DocumentRow;
