import exp from "constants";
import { SearchType } from "../../sysObjects/common.types";

/**
 * Filters the records based on the search string and fields to check
 * @param records - The array of records to filter
 * @param searchString - The string to search for within the records
 * @param fieldsToCheck - The fields of the records to check against the search string
 * @returns The filtered array of records
 */
export const filterTable = <T extends object>(
  records: T[],
  searchString: string,
  fieldsToCheck: (keyof T)[]
): T[] => {
  if (!searchString) {
    return records;
  }

  const lowerCaseSearchString = searchString.trim().toLocaleLowerCase();

  return records.filter((record) => {
    return fieldsToCheck.some((field) => {
      const fieldValue = record[field];
      if (typeof fieldValue === 'string') {
        const lowerCaseFieldValue = fieldValue.toLocaleLowerCase();
        return lowerCaseFieldValue.includes(lowerCaseSearchString);
      }
      return false;
    });
  });
};

/**
 * Sorts an array of objects by a specified string property.
 * @param array The array to sort.
 * @param column The name of the column to sort by.
 * @param direction The direction to sort ('asc' for ascending, 'desc' for descending).
 * @returns The sorted array.
 */
const sortByStringColumn = (
  array: any[],
  column: string,
  direction: 'asc' | 'desc' = 'asc'
): any[] => {
  return array.sort((a, b) => {
    const valueA = a[column]?.toString().toLowerCase() ?? '';
    const valueB = b[column]?.toString().toLowerCase() ?? '';
    if (valueA < valueB) return direction === 'asc' ? -1 : 1;
    if (valueA > valueB) return direction === 'asc' ? 1 : -1;
    return 0;
  });
};

/**
 * Sorts an array of objects by a specified number property.
 * @param array The array to sort.
 * @param column The name of the column to sort by.
 * @param direction The direction to sort ('asc' for ascending, 'desc' for descending).
 * @returns The sorted array.
 */
const sortByNumberColumn = (
  array: any[],
  column: string,
  direction: 'asc' | 'desc' = 'asc'
): any[] => {
  return array.sort((a, b) => {
    const valueA = a[column] ?? 0;
    const valueB = b[column] ?? 0;
    return direction === 'asc' ? valueA - valueB : valueB - valueA;
  });
};

/**
 * Sorts an array of objects by a specified date property.
 * @param array The array to sort.
 * @param column The name of the column to sort by.
 * @param direction The direction to sort ('asc' for ascending, 'desc' for descending).
 * @returns The sorted array.
 */
const sortByDateColumn = (
  array: any[],
  column: string,
  direction: 'asc' | 'desc' = 'asc'
): any[] => {
  return array.sort((a, b) => {
    const valueA = a[column] ? new Date(a[column]) : new Date();
    const valueB = b[column] ? new Date(b[column]) : new Date();
    return direction === 'asc' ? valueA.getTime() - valueB.getTime() : valueB.getTime() - valueA.getTime();
  });
};

/**
 * Sorts an array of objects by a specified boolean property.
 * @param array The array to sort.
 * @param column The name of the column to sort by.
 * @param direction The direction to sort ('asc' for ascending, 'desc' for descending).
 * @returns The sorted array.
 */
const sortByBooleanColumn = (
  array: any[],
  column: string,
  direction: 'asc' | 'desc' = 'asc'
): any[] => {
  return array.sort((a, b) => {
    const valueA = a[column] ?? false;
    const valueB = b[column] ?? false;
    return direction === 'asc' ? valueA - valueB : valueB - valueA;
  });
};

export const sortTable = <T extends object>(
  records: T[],
  sortColumn: keyof T,
  sortDirection: 'asc' | 'desc',
  sortType: SearchType
): T[] => {
  switch (sortType) {
    case 'string':
      return sortByStringColumn(records, sortColumn as string, sortDirection);
    case 'number':
      return sortByNumberColumn(records, sortColumn as string, sortDirection);
    case 'date':
      return sortByDateColumn(records, sortColumn as string, sortDirection);
    case 'boolean':
      return sortByBooleanColumn(records, sortColumn as string, sortDirection);
    default:
      return records;
  }
}
