import React from 'react';
import LabelItemTypes from './LabelItem.types';
import SideBar from '../sideBar/SideBar';

const LabelItem: React.FC<LabelItemTypes.Props> = (props) => {
  return (
    <>
      {props.renderSideBarInLabel !== true && (
        <SideBar
          boxState="locked"
          FieldKey={`sidebar_${props.labelName}`}
          key={`sidebar_${props.labelName}`}
          includeInnerBar={true}
          className={props.classNameStatus}
        />
      )}
      <div className={props.classNameLabel}>
      {props.renderSideBarInLabel === true && (
        <SideBar
          boxState="locked"
          FieldKey={`sidebar_${props.labelName}`}
          key={`sidebar_${props.labelName}`}
          includeInnerBar={true}
          className={props.classNameStatus}
        />
      )}
        <label key={`labelFor_${props.label}`} htmlFor={props.labelName} className='Text-Regular'>
          {props.label}
        </label>
      </div>
      <div className={`${!props.classNameValue ?'' : `${props.classNameValue} `}Text-Regular`}>
        <label key={props.labelName} id={props.labelName}>
          {props.value}
        </label>
      </div>
    </>
  );
};

export default LabelItem;
