import React from 'react';
import { TaskItemProps } from './TaskItem.types';
import { useIntl } from 'react-intl';
import './TaskItem.css';
import DateHelper from '../../../../../systemUtils/common/DateHelpers';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import SideBar from '../../../../sharedControls/reusableBlocks/sideBar/SideBar';
import StatusLabel from '../../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import BaseBlock from '../../../../sharedControls/reusableBlocks/baseBlock/BaseBlock';
import { SvgClock } from '../../../../sharedControls/svg';

const TaskItem: React.FC<TaskItemProps> = (props) => {
  const intl = useIntl();
  const locales = require(`../../locales/${intl.locale}.json`);

  const renderDates = () => {
    return props.completedDate ? (
      // for completed tasks: show created date and completed date
      <>
        {props.createdDateTime &&
          renderDate(
            DateHelper.getLocalDateString(props.createdDateTime, intl, 'MM'),
            locales.raised
          )}
        {props.completedDate &&
          renderDate(
            DateHelper.getLocalDateString(props.completedDate, intl, 'MM'),
            locales.occurred
          )}
      </>
    ) : props.dueDate ? (
      // for outstanding tasks with a due date: show due date
      <>
        {props.dueDate &&
          renderDate(
            DateHelper.getLocalDateString(props.dueDate, intl, 'MM'),
            locales.due
          )}
      </>
    ) : (
      // for outstanding tasks without a due date: show created date
      <>
        {props.createdDateTime &&
          renderDate(
            DateHelper.getLocalDateString(props.createdDateTime, intl, 'MM'),
            locales.raised
          )}
      </>
    );
  };

  const renderDate = (date: string, label: string) => {
    return (
      <>
        <StatusLabel
          label={label}
          renderAs="span"
          key={`status-${props.id}-${label}`}
          status="neutral"
        />
        <StatusBlock
          boxSize="small"
          boxState="positive"
          id={`${props.id}_LastModifiedDateIcon`}
          content={<SvgClock width={30} height={30} fill={'white'} />}
        />
        <span className="Text-Regular">{date}</span>
      </>
    );
  };

  const renderAssigned = (name?: string) => {
    if (!name) {
      return null;
    }
    const nameParts = name!.split(' ').slice(0, 3);
    const initials = nameParts.map((part) => part[0]).join('');

    return (
      <>
        <BaseBlock boxSize="small" content={initials} id="personStatus" />
        <span className="Text-Regular">{name}</span>
      </>
    );
  };

  const renderPeople = () => {
    return props.createdBy ? (
      <div role="grid" className="Task-Person">
        {props.createdBy && (
          <>
            <StatusLabel
              label={locales.raisedBy}
              renderAs="span"
              key={`status-${props.id}-OccurredDate`}
              status="neutral"
            />
            {renderAssigned(props.createdBy)}
          </>
        )}
      </div>
    ) : null;
  };

  const renderCaseReference = () => {
    return props.caseReference ? (
      <div className="Task-Ref">
        <StatusLabel
          label={locales.caseRef}
          renderAs="span"
          key={`status-${props.id}-caseRef`}
          status="neutral"
        />
        <span className="Text-Regular">{props.caseReference}</span>
      </div>
    ) : null;
  };

  return (
    <div className="Task-Container" data-testid={`Task-Container-${props.id}`}>
      <SideBar
        FieldKey={'item'}
        boxState={props.controlState}
        includeInnerBar={false}
      />
      <div className="Task-Header">
        <StatusBlock
          boxState={props.controlState}
          boxSize="small"
          id={`container_${props.id}`}
        />
        <h3 className="Task-Title Text-Strong">{props.headerLabel}</h3>
      </div>
      <div className="Task-Body">
        <StatusLabel
          label={props.status}
          renderAs="span"
          key={`status-${props.id}`}
          status={props.controlState}
        />
        <div role="grid" className="Task-Dates">
          {renderDates()}
        </div>
        {renderPeople()}
        {renderCaseReference()}
        {props.notes && (
          <div className="Task-Notes" key="notes">
            {props.notes.map((note, index) => {
              return (
                <>
                  <StatusBlock
                    boxState="neutral"
                    boxSize="small"
                    id={`${props.id}_statusBlock_${index}`}
                    key={`${props.id}_statusBlock_${index}`}
                  />
                  <span
                    className="Text-Regular"
                    id={`${props.id}_note_${index}`}
                    key={`${props.id}_note_${index}`}
                  >
                    {note}
                  </span>
                </>
              );
            })}
          </div>
        )}
        {props.completable && !props.completedDate && (
          <div className="Task-Completion">
            <label
              className="check-label Text-Regular"
              htmlFor={`complete_${props.id}`}
            >
              <input
                id={`complete_${props.id}`}
                data-testid={`complete_${props.id}`}
                type="checkbox"
                onClick={() => {
                  if (!props.CompleteTicked) {
                    return;
                  }
                  props.CompleteTicked(props.id);
                }}
              />
              Mark As Complete
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskItem;
