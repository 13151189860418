import React from 'react';
import SVGTypes from './svg.types';

export const Close: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      className={props.className}
      width={props.width}
      data-testid={props.testId}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
        fill={props.fill}
        fillOpacity="0.0001"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7514 13.2937C30.8302 13.3866 30.8927 13.4969 30.9354 13.6184C30.978 13.7398 31 13.87 31 14.0015C31 14.133 30.978 14.2632 30.9354 14.3847C30.8927 14.5062 30.8302 14.6165 30.7514 14.7094L20.5988 26.7063C20.5202 26.7994 20.4268 26.8733 20.324 26.9237C20.2213 26.9741 20.1111 27 19.9998 27C19.8885 27 19.7783 26.9741 19.6755 26.9237C19.5727 26.8733 19.4794 26.7994 19.4008 26.7063L9.24812 14.7094C9.08925 14.5216 9 14.267 9 14.0015C9 13.736 9.08925 13.4814 9.24812 13.2937C9.40698 13.106 9.62245 13.0005 9.84712 13.0005C10.0718 13.0005 10.2873 13.106 10.4461 13.2937L19.9998 24.5848L29.5534 13.2937C29.632 13.2006 29.7254 13.1267 29.8282 13.0763C29.931 13.0259 30.0411 13 30.1524 13C30.2637 13 30.3739 13.0259 30.4767 13.0763C30.5795 13.1267 30.6728 13.2006 30.7514 13.2937Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Close;
