import React, { FC, useEffect, useState } from 'react';
import PriceEditingTypes from './PriceEditing.types';
import FormTextCapture from '../../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import { FormControlRef } from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import ButtonBoxTypes from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import './PriceEditing.css';

const PriceEditing: FC<PriceEditingTypes.Props> = (props) => {
  const [price, setPrice] = useState<string>('');
  const [revertChecked, setRevertChecked] = useState<boolean>(false);
  const ref = React.useRef<FormControlRef>(null);

  useEffect(() => {
    setPrice(props.price ? props.price.toString() : '');
  }, [props.price]);

  const determineButtons = () => {
    const buttons = [
      {
        id: `save-${props.serviceId}`,
        label: props.labels.saveButton,
        controlState: 'positive',
        onClick: () => {
          if (!ref.current?.triggerValidation()) {
            return;
          }
          props.setPrice(Number(price));
        },
      },
      {
        id: `cancel-${props.serviceId}`,
        label: props.labels.cancelButton,
        controlState: 'negative',
        onClick: () => {
          setPrice(props.price ? props.price.toString() : '');
          props.cancelPrice();
        },
      },
    ] as ButtonBoxTypes.Button[];

    if (props.isCustomised) {
      buttons.push({
        id: `revert-${props.serviceId}`,
        label: props.labels.revertLabel,
        controlState: 'negative',
        onClick: () => {
          setRevertChecked(true);
        }
      });
    }

    return buttons;
  };

  const renderConfirmation = () => {
    return revertChecked && (
      <ButtonBox
        id={`confirm-revert-${props.serviceId}-render`}
        key={`confirm-revert-${props.serviceId}-render`}
        className="Confirm-Revert-Buttons"
        title={props.labels.revertConfirmation}
        displayBorder={true}
        buttons={[
          {
            id: `confirm-revert-${props.serviceId}-yes`,
            controlState: 'negative',
            label: props.labels.revertConfirmationYes,
            onClick: () => {
              if (props.isCustomised && props.revertPrice) {
                props.revertPrice();
              }
              setRevertChecked(false);
            },
          },
          {
            id: `confirm-revert-${props.serviceId}-no`,
            controlState: 'positive',
            label: props.labels.revertConfirmationNo,
            onClick: () => {
              setRevertChecked(false);
            },
          },
        ]}
      />
    )
  };

  return (
    <div className='Price-Editing'>
      <FormTextCapture
        id={`price-${props.serviceId}`}
        decimalPlaces={2}
        requiredDetails={{
          formLabel: props.labels.requiredField,
          message: props.labels.requiredFieldMessage,
        }}
        displayMode="Column"
        fieldId="price"
        label={props.labels.controlLabel}
        value={price + ''}
        ref={ref}
        onChange={(evt) => {
          setPrice((evt.value || '').toString());
        }}
        errorMessage={props.labels.errorMessage}
        textInputType="price"
      />
      <div>{props.labels.actionLabel}</div>
      <div className='Revert-Confirmation-Container'>
        <ButtonBox
          id={`price-buttons-${props.serviceId}`}
          buttons={determineButtons()}
        />
        {renderConfirmation()}
      </div>
    </div>
  );
};

export default PriceEditing;
