import React, { FC, useEffect, useState } from 'react';
import AlertModalTypes from './AlertModal.types';
import './AlertModal.css';
import StatusBlock from '../../../reusableBlocks/statusBlock/StatusBlock';
import { SvgBad } from '../../../svg';

const AlertModal: FC<AlertModalTypes.Props> = (props) => {
  const [localProps, setLocalProps] =
    useState<AlertModalTypes.messageDetailObj>(props.messageDetail);
  const [animationClass, setAnimationClass] = useState<'MoveUp' | ''>('');

  useEffect(() => {
    setLocalProps(props.messageDetail);
  }, [props.messageDetail]);

  useEffect(() => {
    if (localProps.showMessage) {
      const timer = setTimeout(() => {
        props.onDismiss('TimeOut', {
          message: localProps.message,
          alertType: localProps.alertType,
        });
        setAnimationClass('');
      }, 11000);

      const timer2 = setTimeout(() => {
        setAnimationClass('MoveUp');
      }, 10000);

      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } else {
    }
  }, [
    localProps.showMessage,
    localProps.message,
    localProps.alertType,
    props.onDismiss,
  ]);

  return (
    <div
      className={`CommonMessageBox${localProps.showMessage ? '' : ' lexxic-hidden'}${animationClass === '' ? '' : ' ' + animationClass}`}
      key="ModalBox"
    >
      <div className="MessageBox">
        <StatusBlock
          boxSize="large"
          boxState={localProps.alertType}
          id="StatusBox-Modal"
          showIcon={true}
        />
        <p className="Text-Strong">{localProps.message}</p>
        <StatusBlock
          boxSize="large"
          boxState="locked"
          id="StatusBox-Modal"
          onclick={() => {
            setLocalProps({ ...localProps, showMessage: false });
            props.onDismiss('User-Acknowledged', {
              message: '',
              alertType: 'neutral',
            });
          }}
          showIcon={false}
          content={<SvgBad className="icon" />}
        />
      </div>
    </div>
  );
};

export default AlertModal;
