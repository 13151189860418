import React, { FC } from 'react';
import StandardHeaderTypes from './StandardHeader.types';
import './StandardHeader.css';

export const StandardHeader: FC<StandardHeaderTypes.Props> = (props) => {
  return (
    <div className="ListTable-Heading" id={props.id} role="columnheader" aria-roledescription='columnheader'>
      <span className="Text-Regular">{props.name}</span>
    </div>
  );
};

export default StandardHeader;
