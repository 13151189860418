export namespace CaseContainerPageTypes {
    export enum Tabs {
        Summary = 0,
        Referral = 1,
        Services = 2,
        PreRequisites = 3,
        Appointments = 4,
        BillableItems = 5,
        Recommendations = 6,
        Documents = 7,
        Notes = 8,
    }
}