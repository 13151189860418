import React from 'react';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import PricingDetails_Types from './PricingDetails.types';
import FormTextCapture from '../../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import FormTextArea from '../../../../sharedControls/formControls/formTextArea/FormTextArea';
import FormDropDown from '../../../../sharedControls/formControls/formDropDown/FormDropDown';
import StatusLabel from '../../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import { useNavigate } from 'react-router-dom';

import './PricingDetails.css';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
const PricingDetails: React.FC<PricingDetails_Types.Props> = (props) => {
  const navigate = useNavigate();

  const [formData, setFormData] =
    React.useState<PricingDetails_Types.pricingControlData_WithStates>(props.pricingDefinition);

  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(props.pricingDefinition.isReadOnly);

  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleSave = async () => {
    const validationResults = formRefs.map((innerRef) =>
      innerRef.current?.triggerValidation(),
    );
    const tabValid = validationResults.every((result) => result === true);

    if (!tabValid) {
      return;
    }

    props.events.OnSave(formData).then((result) => {
        if (result.isFailure) {
          return;
        }
        setIsReadOnly(true);
    });
  };

  return (
    <div className={`PriceList-Definition-CU${isReadOnly ? ' Read-Only' : ''}`}>
      <FormTextCapture
        id="name"
        fieldId="name"
        label={props.labels.name}
        textInputType="text"
        value={formData.name}
        maxLength={250}
        requiredDetails={{
          formLabel: props.labels.required,
          message: `${props.labels.name} ${props.labels.requiredMessage}`,
        }}
        ref={formRefs[0]}
        onChange={handleFormChange}
        displayMode={'Column'}
      />
      <div className="ReadOnly-Col"> {props.pricingDefinition.name}</div>
      <FormTextArea
        fieldId="description"
        id="description"
        label={props.labels.description}
        value={formData.description}
        maxLength={250}
        ref={formRefs[1]}
        onChange={handleFormChange}
        displayMode={'Column'}
      />
      <div className="ReadOnly-Col">{props.pricingDefinition.description}</div>
      <FormDropDown
        fieldId="currency"
        id="currency"
        value={formData.currency}
        items={props.supportedCurrencies}
        label={props.labels.currency}
        defaultText={props.labels.defaultCurrency}
        onChange={handleFormChange}
        requiredDetails={{
          formLabel: props.labels.required,
          message: `$props.labels.currency} ${props.labels.requiredMessage}`,
        }}
        ref={formRefs[2]}
        displayMode={'Column'}
      />
      <div className="ReadOnly-Col">
        <StatusLabel
          renderAs="span"
          label={props.pricingDefinition.currency}
          status="neutral"
        />
      </div>
      <span className="Action-Label">{props.labels.actionLabel}</span>
      {isReadOnly ? (
        <ButtonBox
          buttons={[
            {
              label: props.labels.editButton,
              onClick: () => setIsReadOnly(false),
              id: `Edit-Price-Details_${props.pricingDefinition.id}`,
              controlState: 'positive',
            },
            {
              id: `Edit-Price-List_${props.pricingDefinition.id}`,
              controlState: 'positive',
              label: props.labels.editPriceButton,
              onClick: () =>
                navigate(`/pricing/edit/${props.pricingDefinition.id}`),
            },
          ]}
          id="pricingActions-Expand"
        />
      ) : (
        <ButtonBox
          buttons={[
            {
              label: props.labels.saveButton,
              onClick: () => handleSave(),
              id: `save-Record_${props.pricingDefinition.id}`,
              controlState: 'positive',
            },
            {
              label: props.labels.cancelButton,
              onClick: () => setIsReadOnly(true),
              id: `cancel-Record_${props.pricingDefinition.id}`,
              controlState: 'negative',
            },
          ]}
          id="pricingActions-Expand-Confirm"
        />
      )}
    </div>
  );
};

export default PricingDetails;
