import { confirmAction, fetchAction } from '../../systemUtils/common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import policyDetails from '../../config/currentPolicy.json';
import { IUserClaims } from '../../sysObjects/UserClaims.types';
import { LocalEnumerations } from '../common/CommonHelpers';
import Common_Types from '../../sysObjects/apiModels/Common.types';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import System_Types from '../../sysObjects/apiModels/System.types';
import logger from '../Logger';
export namespace UserCore {
  /**
   * Checks if the user is in a role
   * @param {IUserClaims | null} userClaims The user claims
   * @param {number[]} roles The roles to check
   * @returns {boolean} If the user is in the role
   */
  export const isInRole = (
    userClaims: IUserClaims | null,
    roles: number[],
  ): boolean => {
    try {
      return roles.some((role) => role === userClaims?.role);
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error.message);
      }
      return false;
    }
  };

  /**
   * Checks if the user is a super user
   * @param userClaims The user claims
   * @returns {boolean} If the user is in the role
   */
  export const userIsSuperUser = (userClaims: IUserClaims | null): boolean => {
    try {
      if (!userClaims) {
        return false;
      }
      return userClaims?.role <= LocalEnumerations.Roles.SuperUser;
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error.message);
      }
      return false;
    }
  };

  /**
   * Checks if the user is a case manager or higher
   * @param userClaims The user claims
   * @returns {FunctionalResult<boolean>} If the user is in the role
   */
  export const userIsCaseManagerOrHigher = (
    userClaims: IUserClaims | null,
  ): boolean => {
    try {
      if (!userClaims) {
        return false;
      }
      return userClaims?.role <= LocalEnumerations.Roles.CaseManager;
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error.message);
      }
      return false;
    }
  };

  /**
   * Checks if the user is an assessor or higher
   * @param userClaims The user claims
   * @returns {boolean} If the user is in the role
   */
  export const userIsAssessorOrHigher = (
    userClaims: IUserClaims | null,
  ): boolean => {
    try {
      if (!userClaims) {
        return false;
      }
      return userClaims?.role <= LocalEnumerations.Roles.Assessor;
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error.message);
      }
      return false;
    }
  };

  /**
   * Checks if the user is an associate assessor or higher
   * @param userClaims The user claims
   * @returns {boolean} If the user is in the role
   */
  export const userIsAssociateAssessorOrHigher = (
    userClaims: IUserClaims | null,
  ): boolean => {
    try {
      if (!userClaims) {
        return false;
      }
      return userClaims?.role <= LocalEnumerations.Roles.AssociateAssessor;
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error.message);
      }
      return false;
    }
  };

  export const userIsReferrerOrHigher = (
    userClaims: IUserClaims | null,
  ): boolean => {
    try {
      if (!userClaims) {
        return false;
      }
      return userClaims?.role <= LocalEnumerations.Roles.Referrer;
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error.message);
      }
      return false;
    }
  };

  /**
   * Checks if the user is in a role
   * @param {IUserClaims | null} userClaims The user claims
   * @param {number[]} roles The roles to check
   * @returns {Promise<FunctionalResult<boolean>>} If the user is in the role
   */

  export const isInRoleAsync = (
    userClaims: IUserClaims | null,
    roles: number[],
  ): Promise<FunctionalResult<boolean>> => {
    return new Promise((resolve) => {
      if (!userClaims) {
        resolve(FunctionalResult.Error('User claims not available'));
      } else {
        const isInRole = roles.some((role) => role === userClaims.role);
        resolve(FunctionalResult.Success(isInRole));
      }
    });
  };

  export const userHasAgreedToPolicy = (
    userClaims: IUserClaims | null,
  ): boolean => {
    if (!userClaims) {
      return false;
    }
    return userClaims?.consentRecords!.some(
      (c) => c.version === policyDetails.version,
    );
  };

  /**
   * A call to get the users IP address
   * @returns {FunctionalResult<string>} With the users Ip Address
   */
  export const fetchIPInfo = async (): Promise<FunctionalResult<string>> => {
    try {
      const response = await fetch('https://ipinfo.io/json');
      const data = await response.json();
      return FunctionalResult.Success(data.ip);
    } catch (error) {
      return FunctionalResult.Error('Error fetching client IP');
    }
  };

  /**
   * Saves the consent back to the database
   * @param {string} ip The Ip address
   * @param {string} headerInfo - The headers for the request
   * @returns If the action was successful.
   */
  export const saveConsentAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    ip: string,
  ) => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: settings.Consent.path,
      userId: headerInfo.result.userId,
      formData: {
        userAccountId: headerInfo.result.userId,
        policyName: policyDetails.policyName,
        version: policyDetails.version,
        ipAddress: ip,
      },
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Retrieves user claims
   * @param identityProviderId The identity provider identifier
   * @param emailAddress The email address
   * @returns A result indicating the success of the operation containing the user claims
   */
  export const retrieveUserClaims = async (
    identityProviderId: string,
    emailAddress: string,
    accessToken: string,
  ): Promise<FunctionalResult<IUserClaims>> => {
    let encodedEmail = encodeURIComponent(emailAddress);
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Other.Claims.retrieveClaims.path}?${settings.Other.Claims.retrieveClaims.queryIdentity}=${identityProviderId}&${settings.Other.Claims.retrieveClaims.queryEmail}=${encodedEmail}`,
      accessToken: accessToken,
    });
  };

  /**
   * Gets the latest claims
   * @param {string} usr -  The user id of the claims to
   * @returns the result of the action
   */
  export const refreshClaimsAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<IUserClaims>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: settings.Other.Claims.Refresh.path,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Retrieves the initials of a user.
   * @param user - The user object.
   * @returns The initials of the user.
   */
  export const getUserInitials = (
    user: Common_Types.EntityWithName,
  ): string => {
    return `${user.name[0]}${user.surname[0]}`;
  };
}

export default UserCore;
