import React from 'react';
import './NotesTab.css';
import NotesTabTypes from './NotesTab.types';
import CaseNoteRowTypes from '../../caseNotes/caseNotesRow/CaseNoteRow.types';
import CaseNoteRow from '../../caseNotes/caseNotesRow/CaseNoteRow';
import AddNote from '../../caseNotes/addNote/AddNote';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';

const NotesTab: React.FC<NotesTabTypes.Props> = (props) => {
  const [rows, setRows] = React.useState<CaseNoteRowTypes.CaseNoteRowItem[]>(
    [],
  );
  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  return (
    <>
      <ListTable
        isLoading={props.isCaseLoading}
        className="CaseNotes-Table"
        labels={{
          tableName: props.labels.table.tableName,
          of: props.labels.table.of,
          size: props.labels.table.size,
          emptyMessage: props.labels.table.emptyMessage,
        }}
        rows={
          rows?.map((row, rI) => {
            return <CaseNoteRow key={`BillItems_Rows_${rI}`} item={row} />;
          }) || []
        }
        pagingDetails={props.pagingDetails}
        defaultItem={
          <AddNote
            caseId={props.caseId}
            documents={props.associatedDocuments}
            labels={props.labels.addNote}
            events={{
              addNote: props.events.onCreateNote,
            }}
          />
        }
      >
          <ListTable.Headers>
            {[
              <div key="StatusCol"></div>,
              ...props.labels.table.tableHeaders.map((header, hI) => {
                return <div key={`CaseNoteItem_Header_${hI}`}>{header}</div>;
              }),
            ]}
          </ListTable.Headers>
      </ListTable>
    </>
  );
};

export default NotesTab;
