import React from 'react';
import ReferralTabTypes from './ReferralTab.types';
import LabelItem from '../../../../sharedControls/reusableBlocks/labelItem/LabelItem';
import { SvgClock } from '../../../../sharedControls/svg';
import BaseBlock from '../../../../sharedControls/reusableBlocks/baseBlock/BaseBlock';
import SideBar from '../../../../sharedControls/reusableBlocks/sideBar/SideBar';
import './ReferralTab.css';

const ReferralTab: React.FC<ReferralTabTypes.ReferralTabProps> = (props) => {
  return (
    <>
      <div className="ReferralTab Font-Heading">
        <span className="heading">{props.labels.title}</span>
      </div>

      <div className="Referral-Tab-Details">
        <LabelItem
          label={props.labels.formLabels.id}
          value={props.refId}
          labelName="id"
          classNameLabel="Col-Two Base-Line"
          classNameValue="Col-Three Base-Line"
        />
        <LabelItem
          label={props.labels.formLabels.firstName}
          value={props.firstName}
          labelName="firstName"
          classNameLabel="Col-Two"
          classNameValue="Col-Three"
        />
        <LabelItem
          label={props.labels.formLabels.lastName}
          value={props.lastName}
          labelName="lastName"
          classNameLabel="Col-Two Base-Line"
          classNameValue="Col-Three Base-Line"
        />
        <LabelItem
          label={props.labels.formLabels.dob}
          value={
            <>
              <BaseBlock
                boxSize="small"
                className="positive"
                content={<SvgClock className="icon" />}
              />
              {props.dob}
            </>
          }
          labelName="dob"
          classNameLabel="Col-Two"
          classNameValue="Col-Three"
        />
        <LabelItem
          label={props.labels.formLabels.email}
          value={props.email}
          labelName="email"
          classNameLabel="Col-Two"
          classNameValue="Col-Three"
        />
        <LabelItem
          label={props.labels.formLabels.tel}
          value={props.tel}
          labelName="tel"
          classNameLabel="Col-Two Base-Line"
          classNameValue="Col-Three Base-Line"
        />
        <LabelItem
          label={props.labels.formLabels.refOrg}
          value={props.refOrg}
          labelName="refOrg"
          classNameLabel="Col-Two  Base-Line"
          classNameValue="Col-Three Base-Line"
        />
        <SideBar
          boxState="locked"
          FieldKey={`sidebar_suspectedConditions`}
          key={`sidebar_suspectedConditions`}
          includeInnerBar={true}
        />
        <div className="Col-Two">
          <label className="Text-Regular" htmlFor="suspectedConditions">
            {props.labels.formLabels.suspectedConditions}
          </label>
        </div>
        <div className="Col-Three">
          <ul id="suspectedConditions">
            {props.suspectedConditions.map((condition) => (
              <li key={condition.key} className="Text-Regular">
                {condition.value}
              </li>
            ))}
          </ul>
        </div>
        <SideBar
          boxState="locked"
          FieldKey={`sidebar_servicesRequired`}
          key={`sidebar_servicesRequired`}
          includeInnerBar={true}
        />
        <div className="Col-Two">
          <label htmlFor="servicesRequired" className="Text-Regular">
            {props.labels.formLabels.servicesRequired}
          </label>
        </div>
        <div className="Col-Three">
          <ul id="servicesRequired">
            {props.servicesRequired.map((condition) => (
              <li key={condition.key} className="Text-Regular">
                {condition.value}
              </li>
            ))}
          </ul>
        </div>
        <SideBar
          boxState="locked"
          FieldKey={`sidebar_referralReason`}
          key={`sidebar_referralReason`}
          includeInnerBar={true}
        />
        <div className="Col-Two">
          <label htmlFor="referralReason" className="Text-Regular">
            {props.labels.formLabels.referralReason}
          </label>
        </div>
        <div className="Col-Three">
          <ul id="referralReason" className="Text-Regular">
            <li key={props.referralReason?.key}>
              {props.referralReason?.value}
            </li>
            {props.referralReasonOther && <li>{props.referralReasonOther}</li>}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ReferralTab;
