import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import {
  fetchAction,
  createAction,
  updateAction,
  confirmAction,
  deleteAction,
} from '../../systemUtils/common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import Organisation_Types from '../../sysObjects/apiModels/Organisation.types';
import System_Types from '../../sysObjects/apiModels/System.types';

export namespace OrgActions {
  /**
   * finds the orgs based on the search details
   * @param { string } orgId The of top org find the related orgs
   * @param {IUserClaims} performingUser the user to use.
   * @returns The result of the action
   */
  export const lookUpAsync = async (
    paramObj: Organisation_Types.searchQueryObj,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<Organisation_Types.RetrievedOrganisation[]>> => {
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    let params: string[] = [];
    let queryPath = '';
    if (paramObj.orgType !== undefined) {
      params.push(
        `${settings.Organisations.search.queryType}=${paramObj.orgType}`,
      );
    }
    if (paramObj.hasBillingDetails !== undefined) {
      params.push(
        `${settings.Organisations.search.queryHasBillingDetails}=${paramObj.hasBillingDetails}`,
      );
    }
    if (paramObj.name !== undefined) {
      params.push(
        `${settings.Organisations.search.queryName}=${paramObj.name}`,
      );
    }

    if (params.length > 0) {
      queryPath = `?${params.join('&')}`;
    }
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Organisations.search.path!}${queryPath}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * finds the related orgs
   * @param { string } orgId The of top org find the related orgs
   * @param {IUserClaims} performingUser the user to use.
   * @returns The result of the action
   */
  export const getOrgByIDAsync = async (
    orgId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<Organisation_Types.RetrievedOrganisation>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Organisations.get.path!}/${orgId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * finds the related orgs
   * @param { string } orgId The of top org find the related orgs
   * @param {IUserClaims} performingUser the user to use.
   * @returns The result of the action
   */
  export const findRelatedOrgsAsync = async (
    orgId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<Organisation_Types.OrganisationSummary[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Organisations.find.path!}/${orgId}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Saves the user object.
   * @param { IUserClaims } usr - the system user to pass to the calling method
   * @param obj -The record to save.
   * @param id - The id of the record to save.
   * @returns The result of the action
   */
  export const saveOrganisationAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: Organisation_Types.organisation,
    id?: string,
  ): Promise<FunctionalResult<void | string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (id) {
      return updateAction({
        hostPath: host!.result!.path,
        apiPath: `${settings.Organisations.update.path!}/${id}`,
        userId: headerInfo.result.userId,
        formData: obj,
        accessToken: headerInfo.result.token,
      });
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.Organisations.create.path!,
      userId: headerInfo.result.userId,
      formData: obj,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * finds the billing details
   * @param { string } id The of top org find the related orgs
   * @param {IUserClaims} performingUser the user to use.
   * @returns The result of the action
   */
  export const getBillingById = async (
    id: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<Organisation_Types.billingDetails>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.OrganisationBillingDetails.get.path!}/${id}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Saves the Billing Details.
   * @param object The record to save.
   * @param { IUserClaims } usr - the system user to pass to the calling method
   * @param { Id } id - the id of the billing details to pass to the calling method
   * @returns The result of the action
   */
  export const saveBillingDetailsAsync = async (
    obj: Organisation_Types.billingDetails,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    id?: string,
  ): Promise<FunctionalResult<void | string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (id) {
      return updateAction({
        hostPath: host!.result!.path,
        apiPath: `${settings.OrganisationBillingDetails.update.path!}/${id}`,
        userId: headerInfo.result.userId,
        formData: obj,
        accessToken: headerInfo.result.token,
      });
    }

    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.OrganisationBillingDetails.create.path!,
      userId: headerInfo.result.userId,
      formData: obj,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * Gets the pricing details for the organisation
   * @param {string} id - The id of the organisation to get the pricing for
   * @param headerInfo - The header information to pass to the calling method
   * @returns Promise<FunctionalResult<Organisation_Types.organisationPrices[]>> -
   */
  export const getPricingByOrg = async (
    id: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<Organisation_Types.organisationPrices[]>> => {
    if (headerInfo.isFailure && headerInfo.result == null) {
      return FunctionalResult.Error('Failed to load header information');
    }

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Organisations.pricing.get.path!}/${id}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Saves the pricing details for the organisation
   * @param {Organisation_Types.organisationPrices} obj - The pricing details to save
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo - The header information to pass to the calling method
   * @returns Promise<FunctionalResult<void>> - The result of the action
   */
  export const savePricingAsync = async (
    obj: Organisation_Types.organisationPriceUpdate,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<string>> => {
    if (headerInfo.isFailure && headerInfo.result == null) {
      return FunctionalResult.Error('Failed to load header information');
    }

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    let path = `${obj.organisationId}`;
    path = `${path}?${settings.Organisations.pricing.update.queryServiceId}=${obj.serviceDefinitionId}`;
    path = `${path}&${settings.Organisations.pricing.update.queryPricing}=${obj.price}`;

    return updateAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Organisations.pricing.update.path!}/${path}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Reverts a single organisation price to its price list price.
   * @param organisationPriceId The id of the organisation price to revert.
   * @param headerInfo The header information to pass to the calling method.
   * @returns The result of the action.
   */
  export const revertSingleOrganisationPriceAsync = async (
    organisationPriceId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<void>> => {
    if (headerInfo.isFailure && headerInfo.result == null) {
      return FunctionalResult.Error('Failed to load header information');
    }

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return deleteAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Organisations.pricing.revertSinglePrice.path!}/${organisationPriceId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Reverts the entire organisation price list.
   * @param organisationId The id of the organisation to revert the prices for.
   * @param headerInfo The header information to pass to the calling method.
   * @returns The result of the action.
   */
  export const revertEntireOrganisationPriceListAsync = async (
    organisationId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<void>> => {
    if (headerInfo.isFailure && headerInfo.result == null) {
      return FunctionalResult.Error('Failed to load header information');
    }

    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return deleteAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Organisations.pricing.revertAllPrices.path!}/${organisationId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /** Creates an empty model to bind to */
  export const createDefaultOrganisation =
    (): Organisation_Types.organisation => {
      return {
        name: '',
        industrySectorCode: '',
        notes: '',
        organisationSize: null,
        organisationType: null,
        referrerReferringRelationships: [],
        employerReferringRelationships: [],
        usersCanAccessPortal: false,
      };
    };

  /** Creates an empty billing details tro bind to */
  export const createDefaultBillingDetails = (
    id: string,
  ): Organisation_Types.billingDetails => {
    return {
      assignedCaseManager: [],
      assignedConsultingBusinessPsychologist: '',
      assignedRelationshipManager: '',
      billingAccount: createDefaultBillingAccount(),
      isPORequired: false,
      notes: '',
      organisationId: id,
      pricingDefinitionId: '',
    };
  };

  /** Creates an empty billing account */
  const createDefaultBillingAccount = (): Organisation_Types.billingAccount => {
    return {
      address: {
        line1: '',
        line2: undefined,
        line3: '',
        country: '',
        postalCode: '',
      },
      currency: 'GBP',
      emailAddress: '',
      fullName: '',
      taxCode: '',
      telephoneNumber: '',
    };
  };
}
export default OrgActions;
