import React, { FC, useEffect, useState } from 'react';
import PricingDefinitionItemRow from '../pricingItemRow/PricingItemRow';
import { PricingItemListTypes } from './PricingItemList.types';
import { PricingDefinitionItemTypes } from '../pricingItemRow/PricingItemRow.types';
import './PricingItemList.css';
import PageLoader from '../../../sharedControls/general/loading/pageLoader/PageLoader';
import ListTable from '../../../sharedControls/tables/listTable/ListTable';
import ListTableSearch from '../../../sharedControls/tables/listTableSearch/ListTableSearch';
import {
  filterTable,
  sortTable,
} from '../../../../systemUtils/common/SortSearchHelpers';
import StandardHeader from '../../../sharedControls/tables/listTable/headers/standard/StandardHeader';
import SortableHeader from '../../../sharedControls/tables/listTable/headers/sortable/SortableHeader';
import { ColDetails, colSearchDetails } from '../../../../sysObjects/common.types';

const PricingItemList: FC<PricingItemListTypes.Props> = (props) => {
  const [sortColumnDetails, setSortColumnDetails] = useState<colSearchDetails>({
    field: 'name',
    searchDirection: 'asc',
    searchType: 'string',
  });
  const fieldsToSearch: (keyof PricingDefinitionItemTypes.Item)[] = ['name'];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<PricingDefinitionItemTypes.Item[]>([]);

  const [showTextSearch, setShowTextSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    setRows(props.rows);
    setIsLoading(false);
  }, [props.rows]);

  useEffect(() => {
    setRows((prevItems) => {
      return prevItems.map((prevItem) => {
        return {
          ...prevItem,
          expanded: false,
        };
      });
    });
  }, [searchString]);


  return isLoading ? (
    <PageLoader alt={props.labels.common.load} />
  ) : (
    rows && (
      <>
        <ListTable
          isLoading={false}
          labels={{
            of: props.labels.table.of,
            size: props.labels.table.size,
            tableName: props.labels.table.tableName,
            emptyMessage: props.labels.table.emptyMessage,
          }}
          search={{
            isVisible: showTextSearch,
            onStateChange: (isExpanded: boolean) =>
              setShowTextSearch(isExpanded),
          }}
          pagingDetails={props.pagingDetails}
          rows={
            rows && rows.length > 0
              ?
              sortTable(
               filterTable(rows, searchString, fieldsToSearch), 
              sortColumnDetails.field as keyof PricingDefinitionItemTypes.Item,
              sortColumnDetails.searchDirection === 'none'
                ? 'asc'
                : sortColumnDetails.searchDirection,
              sortColumnDetails.searchType
              ).map((item) => (
                  <PricingDefinitionItemRow
                    key={item.id ?? item.selectedServiceId}
                    item={item}
                    labels={props.labels.rowLabels}
                  />
                ))
              : []
          }
          className="Pricing-Table"
        >
          <ListTable.Headers>
            <div className="Heading Text-Regular" />
            {props.cols.map((col: ColDetails, index: number) => {
              return col.style === 'sortable' ? (
                <SortableHeader
                  key={`${col.id}-${index}`}
                  id={col.id}
                  name={col.name}
                  fieldName={col.field}
                  searchType={col.searchType}
                  sortDirection={
                    col.field === sortColumnDetails.field
                      ? sortColumnDetails.searchDirection
                      : 'none'
                  }
                  onSortSelected={(name, searchType, sortDirection) => {
                    setSortColumnDetails({
                      field: name,
                      searchDirection: sortDirection,
                      searchType: searchType as
                        | 'string'
                        | 'number'
                        | 'boolean'
                        | 'date',
                    });
                  }}
                />
              ) : (
                <StandardHeader
                  id={col.id}
                  name={col.name}
                  key={`${col.id}-${index}`}
                />
              );
            })}
          </ListTable.Headers>
          <ListTable.SearchOptions>
            {showTextSearch && (
              <ListTableSearch
                Labels={{ searchButton: props.labels.search }}
                onSearch={(message: string) => setSearchString(message)}
              />
            )}
          </ListTable.SearchOptions>
        </ListTable>
      </>
    )
  );
};

export default PricingItemList;
