import React, { useContext, useEffect } from 'react';
import DocumentTabTypes from './DocumentsTab.types';
import DocumentActions from '../../../../../systemUtils/document/DocumentActions';
import DocumentTypes from '../../../../../sysObjects/apiModels/UploadDocument.types';
import { FunctionalResult } from '../../../../../sysObjects/FunctionalResult';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';
import DocumentRow from '../../documentDetails/documentRow/DocumentRow';
import DocumentRowTypes from '../../documentDetails/documentRow/DocumentRow.types';

import './DocumentsTab.css';
import AddDocument from '../../documentDetails/addDocument/AddDocument';
import { getHeadersAsync } from '../../../../../systemUtils/common/CommonHelpers';
import { UserClaimsContext } from '../../../../sharedControls/contexts/UserClaimsContext';
import { useMsal } from '@azure/msal-react';

const DocumentTab: React.FC<DocumentTabTypes.Props> = (props) => {
  const [rows, setRows] = React.useState<DocumentRowTypes.Document[]>([]);
  const { userClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();

  React.useEffect(() => {
    if (props.load !== true) {
      return;
    }

    setRows(props.rows);
  }, [props.load, props.loader, props.rows]);

  const handleUpload = async (
    modal: DocumentTypes.UploadModal,
  ): Promise<FunctionalResult<string>> => {
    return props.events.uploadDocument(modal).then((result) => {
      props.events.refreshDocuments();
      return result;
    });
  };
  return (
    <ListTable
      isLoading={props.isCaseLoading}
      className="DocumentsTable"
      labels={props.labels.table.common}
      rows={rows.map((row, i) => {
        return (
          <DocumentRow
            key={`DocumentRow_${row.documentId}`}
            document={row}
            events={{
              changeDocumentVisibility: props.events.changeDocumentVisibility,
              expandIconClicked: (documentId: string, isExpanded: boolean) => {
                const newRows = rows.map((row) => {
                  if (row.documentId === documentId) {
                    return { ...row, isExpanded: isExpanded };
                  } else {
                    return { ...row, isExpanded: false };
                  }
                });
                setRows(newRows);
              },
              downloadClicked: async (documentId: string) => {
                DocumentActions.retrieveCaseDocumentFileContentAsync(
                  {
                    caseId: props.caseId,
                    documentId: documentId,
                    relatedExternalUserId: props.customerId,
                  },
                  await getHeadersAsync(userClaims, instance),
                )
                  .then((result) => {
                    if (result.isFailure) {
                      props.events.onError('Error fetching document');
                      return;
                    }
                    const blob = new Blob([result.result!.data], {
                      type: result.result?.contentType,
                    });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${row.documentName}`;
                    a.click();
                  })
                  .catch((error) => {
                    props.events.onError('Error fetching document');
                  });
              },
            }}
            labels={props.labels.table.row}
          />
        );
      })}
      pagingDetails={props.pagingDetails}
      defaultItem={
        <AddDocument
          maxSizeInMB={32}
          labels={props.labels.addDocument}
          caseId={props.caseId}
          locale={props.locale.locale}
          events={{
            uploadDocument: handleUpload,
          }}
        />
      }
    >
      <ListTable.Headers>
          {[
            <div key="Heading-Check-0"></div>,
            ...props.labels.table.headers.map((header, hI) => {
              return (
                <div
                  key={`DocumentsHeader_${hI}`}
                  className="Heading Text-Regular"
                >
                  {header}
                </div>
              );
            }),
          ]}
        </ListTable.Headers>
    </ListTable>
  );
};

export default DocumentTab;
