import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../../systemUtils/slaDefinition/SlaDefinitionActions';
import OrgActions from '../../../../systemUtils/organisation/OrganisationActions';
import { useNavigate, useParams } from 'react-router-dom';
import UserCore from '../../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import { UserClaimsContext } from '../../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import ButtonBox from '../../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import { getHeadersAsync } from '../../../../systemUtils/common/CommonHelpers';
import CommonPageContext from '../../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import {
  ColDetails,
  colSearchDetails,
  ControlState,
  KeyValuePair,
} from '../../../../sysObjects/common.types';
import ListTable from '../../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ListTableRow from '../../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import SLADefinitionIndexTypes from './Definition.types';
import serviceActions from '../../../../systemUtils/services/ServiceDefinitionActions';
import './SlaDefinitionIndex.css';
import ListTableSearch from '../../../../systemComponents/sharedControls/tables/listTableSearch/ListTableSearch';
import {
  filterTable,
  sortTable,
} from '../../../../systemUtils/common/SortSearchHelpers';
import SortableHeader from '../../../../systemComponents/sharedControls/tables/listTable/headers/sortable/SortableHeader';
import StandardHeader from '../../../../systemComponents/sharedControls/tables/listTable/headers/standard/StandardHeader';

const SLA_Definition_Index: React.FC = () => {
  const fieldsToSearch: (keyof SLADefinitionIndexTypes.TableRow)[] = [
    'name',
    'description',
  ];
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userSettings } = useUserSettingsContext();
  const context = React.useContext(CommonPageContext);

  const { orgId, billingId } = useParams();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [list, setList] = React.useState<SLADefinitionIndexTypes.TableRow[]>(
    []
  );

  const [sortColumnDetails, setSortColumnDetails] = useState<colSearchDetails>({
    field: 'name',
    searchDirection: 'asc',
    searchType: 'string',
  });

  const [showTextSearch, setShowTextSearch] = React.useState<boolean>(false);
  const [searchString, setSearchString] = React.useState<string>('');

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const findCaseAsync = async () => {
    const headers = await getHeadersAsync(userClaims, instance);
    const orgResult = await OrgActions.getOrgByIDAsync(orgId!, headers);

    if (orgResult.isFailure || !orgResult.result) {
      showMessage(
        locales.ApiResponses.loadingFailed,
        'negative',
        '/organisations'
      );
      return;
    }

    const sdResult = await serviceActions.fetchServiceDefinitions(
      headers,
      intl.locale
    );
    if (sdResult.isFailure) {
      showMessage(
        locales.ApiResponses.loadingFailed,
        'negative',
        `/Organisations/${orgId}/billing`
      );
      return;
    }

    const rst = await actions.findAsync(headers, {
      orgId: orgId!,
    });
    if (rst.isFailure || !rst.result) {
      showMessage(
        locales.ApiResponses.loadingFailed,
        'negative',
        `/organisations/${orgId}/billing/${billingId}`
      );
      return;
    }
    setIsLoading(false);
    const breadcrumbs = [
      ...locales.breadcrumbs.base,
      {
        label: orgResult.result!.name,
        link: `/organisations/${orgId}/edit`,
        key: orgId,
      },
      {
        label: locales.breadcrumbs.index.label,
        key: 'sla',
      },
    ];

    context?.handleCrumbUpdate(breadcrumbs);

    setList(
      rst.result.map((sla) => {
        return {
          ...sla,
          serviceDefinitionDetails:
            (sdResult.result!.find((sd) => sd.key === sla.serviceDefinitionId)
              ?.value as string) ?? '',
          isExpanded: false,
          isButtonRowExpanded: false,
        };
      })
    );
  };

  React.useEffect(() => {
    if (!orgId || !billingId) {
      showMessage(
        locales.ApiResponses.loadingFailed,
        'negative',
        `${orgId}/billing/`
      );
      return;
    }
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }

    findCaseAsync();
  }, []);

  React.useEffect(() => {
    setList((prevItems) => {
      return prevItems.map((prevItem) => {
        return {
          ...prevItem,
          isExpanded: false,
          buttonsExpanded: false,
        };
      });
    });
  }, [searchString]);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        search={{
          isVisible: showTextSearch,
          onStateChange: (isExpanded: boolean) => setShowTextSearch(isExpanded),
        }}
        isLoading={isLoading}
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableName,
          emptyMessage: locales.emptyMessage,
        }}
        rows={sortTable(
          filterTable(list, searchString, fieldsToSearch),
          sortColumnDetails.field as keyof SLADefinitionIndexTypes.TableRow,
          sortColumnDetails.searchDirection === 'none'
            ? 'asc'
            : sortColumnDetails.searchDirection,
          sortColumnDetails.searchType
        ).map((li) => (
          <ListTableRow
            key={li.id}
            id={li.id}
            events={{
              OnExpandChange: (isExpanded: boolean) => {
                setList((prevItems) =>
                  prevItems.map((item) =>
                    item.id === li.id
                      ? {
                          ...item,
                          isExpanded,
                          isButtonRowExpanded:
                            isExpanded === true
                              ? false
                              : item.isButtonRowExpanded,
                        }
                      : {
                          ...item,
                          isExpanded: false,
                          isButtonRowExpanded: false,
                        }
                  )
                );
              },
              OnButtonVisibilityChange: (isButtonRowExpanded: boolean) => {
                setList((prevItems) =>
                  prevItems.map((item) =>
                    item.id === li.id
                      ? { ...item, isButtonRowExpanded }
                      : {
                          ...item,
                          isExpanded: false,
                          isButtonRowExpanded: false,
                        }
                  )
                );
              },
            }}
            isButtonRowExpanded={li.isButtonRowExpanded}
            isExpanded={li.isExpanded}
          >
            <ListTableRow.Collapsed>
              <div>{li.name}</div>
              <div>{li.serviceDefinitionDetails}</div>
            </ListTableRow.Collapsed>
            <ListTableRow.Expanded>
              <>
                <div>{locales.name}</div>
                <div>{li.name}</div>
                <div>{locales.description}</div>
                <div>{li.description}</div>
                <div>{locales.relatedService}</div>
                <div>{li.serviceDefinitionDetails}</div>
                <div>{locales.duration}</div>
                <div>{li.duration?.split('.')[0]}</div>
                <div>{locales.actions}</div>
                <div>
                  <ButtonBox
                    id={`sla-edit-${li.id}`}
                    key={`sla-edit-${li.id}`}
                    displayBorder={true}
                    className="Button-Box-expanded"
                    buttons={[
                      {
                        id: `edit-${li.id}`,
                        controlState: 'positive',
                        label: locales.editButton,
                        onClick: () =>
                          navigate(
                            `/organisations/${orgId}/billing/${billingId}/sla/edit/${li.id}`
                          ),
                      },
                    ]}
                  />
                </div>
              </>
            </ListTableRow.Expanded>
            <ListTableRow.ButtonRow
              actionLabel={locales.actions}
              isExpanded={li.isButtonRowExpanded}
              id={li.id}
            >
              <ButtonBox
                id={`sla-edit-${li.id}`}
                key={`sla-edit-${li.id}`}
                displayBorder={true}
                buttons={[
                  {
                    id: `edit-${li.id}`,
                    controlState: 'positive',
                    label: locales.editButton,
                    onClick: () =>
                      navigate(
                        `/organisations/${orgId}/billing/${billingId}/sla/edit/${li.id}`
                      ),
                  },
                ]}
              />
            </ListTableRow.ButtonRow>
          </ListTableRow>
        ))}
        className="SLA-Definition-Index-Table"
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular" />
          {locales.cols.map((col: ColDetails, index: number) => {
            return col.style === 'sortable' ? (
              <SortableHeader
                id={col.id}
                key={`${col.id}-${index}`}
                name={col.name}
                fieldName={col.field}
                searchType={col.searchType}
                sortDirection={
                  col.field === sortColumnDetails.field
                    ? sortColumnDetails.searchDirection
                    : 'none'
                }
                onSortSelected={(name, searchType, sortDirection) => {
                  setSortColumnDetails({
                    field: name,
                    searchDirection: sortDirection,
                    searchType: searchType as
                      | 'string'
                      | 'number'
                      | 'boolean'
                      | 'date',
                  });
                }}
              />
            ) : (
              <StandardHeader
                id={col.id}
                name={col.name}
                key={`${col.id}-${index}`}
              />
            );
          })}
          <div className="Heading Text-Regular" />
        </ListTable.Headers>
        <ListTable.SearchOptions>
          {showTextSearch && (
            <ListTableSearch
              Labels={{ searchButton: locales.search }}
              onSearch={(message: string) => setSearchString(message)}
            />
          )}
        </ListTable.SearchOptions>
      </ListTable>
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'slaCreate',
            label: locales.createButton,
            mode: 'positive',
            clickEvent: () => {
              navigate(
                `/organisations/${orgId}/billing/${billingId}/sla/create`
              );
            },
          }}
        >
          {locales.createSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default SLA_Definition_Index;
