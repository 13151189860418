import React, {
  ChangeEvent,
  FC,
  useEffect,
} from 'react';
import PageSizeTypes from './PagingSize.types';

import './PagingSize.css';

const PagingSize: FC<PageSizeTypes.Props> = ({ label, pageSizes, value, onChange }) => {
  useEffect(() => {}, []);

  const OnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!onChange) {
      return;
    }

    onChange(parseInt(e.target.value));
  };

  return !pageSizes ? null : (
    <div className={`paging-control lexxic-text`}>
      <label htmlFor='pagingSelect' className='label Text-Regular'>{label}</label>
      <select className='select' data-testid='paging-select' id='pagingSelect' value={value ?? ''} onChange={OnChange}>
        {pageSizes.map((i) => {
          return (
            <option key={`pagingControl_${i}`} value={i}>
              {i}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default PagingSize;
