import React, { useState } from 'react';
import { getTimeSince } from '../../../../../systemUtils/common/CommonHelpers';
import { SvgClock } from '../../../../sharedControls/svg';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import Expander from '../../../../sharedControls/general/expander/Expander';
import RecommendationRowTypes from './RecommendationRow.types';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';

const RecommendationRow: React.FC<RecommendationRowTypes.Props> = (props) => {
  const [isExpanded, setExpandedState] = useState<Boolean>(
    props.item.isExpanded,
  );

  const renderExpander = () => {
    return (
      <div>
        <Expander
          id={`${props.item.itemID}_Status`}
          key={`${props.item.itemID}_Status`}
          isExpanded={isExpanded}
          eventHandler={{ onClick: () => setExpandedState(!isExpanded) }}
        />
      </div>
    );
  };

  const renderCreatorName = () => {
    return (
      <>
        <StatusBlock
          boxSize="small"
          boxState="locked"
          showIcon={false}
          allowHover={false}
          className="Status-User-Int"
          content={props.item.createdByInitials}
        />
        {props.item.createdByName}
      </>
    )
  };

  const renderCreatedDateTime = () => {
    return (
      <>
        <StatusBlock
          boxSize="small"
          boxState="positive"
          showIcon={false}
          allowHover={false}
          content={<SvgClock className='icon' />}
        />
        {getTimeSince(props.item.createdDateTime)}
      </>
    )
  };

  const renderCollapsedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.item.serviceDefinitionName}</div>
        <div className="td">
          {renderCreatedDateTime()}
        </div>
        <div className="td">
          {renderCreatorName()}
        </div>
      </>
    );
  };

  const renderExpandedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.item.serviceDefinitionName}</div>
      </>
    );
  };

  const renderExpandedRow = () => {
    return (
      <div className="Expanded-row">
        <div className="col Text-Regular">{props.labels.createdByLabel}</div>
        <div className="Value-Column">
          {renderCreatorName()}
        </div>
        <div className="col Text-Regular">{props.labels.createdDateTimeLabel}</div>
        <div className="Value-Column">
          {renderCreatedDateTime()}
        </div>
        {
          !props.item.readOnly && (
            <>
              <div className='col Text-Regular'>{props.labels.actionsLabel}</div>
              <ButtonBox
                id={`recommendation_row_${props.item.itemID}_button_box`}
                buttons={[{
                  controlState: 'positive',
                  label: props.labels.deleteBtn,
                  id: `recommendation_row_${props.item.itemID}_delete`,
                  onClick: () => {
                    if (!props.events.onDeleteRecommendation) return;
                    props.events.onDeleteRecommendation(props.item.itemID);
                  },
                }]}
              />
            </>
          )
        }
      </div>
    );
  };

  const renderRow = () => {
    return (
      <>
        {isExpanded
          ? renderExpandedHeader()
          : renderCollapsedHeader()}
        {isExpanded && renderExpandedRow()}
      </>
    );
  };

  return <div className="RecommendedRow-Item">{renderRow()}</div>;
};

export default RecommendationRow;
