import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../../sharedControls/contexts/UserClaimsContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState } from '../../../../../sysObjects/common.types';
import CommonPageContext from '../../../../sharedControls/contexts/CrumbUpdateContext';
import FormCheckbox from '../../../../sharedControls/formControls/formCheckbox/FormCheckbox';
import FormDate from '../../../../sharedControls/formControls/formDate/FormDate';
import FormTextCapture from '../../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import InformationButton from '../../../../sharedControls/general/InformationButton/InformationButton';
import PageLoader from '../../../../sharedControls/general/loading/pageLoader/PageLoader';
import { Case_Types, CaseDetail_Types } from '../../../../../sysObjects/apiModels/Case.types';
import CaseUtils from '../../../../../systemUtils/case/caseUtils';
import { getHeadersAsync, getDate, getCurrentStatus, LocalEnumerations } from '../../../../../systemUtils/common/CommonHelpers';
import { PreRequisitesTabProps } from './PreRequisitesTab.types';

import './PreRequisitesTab.css';

const PreRequisitesTab: React.FC<PreRequisitesTabProps> = ({
  caseDetails,
  onSaveSuccess,
  onSave,
  locales,
  isCaseLoading
}) => {
  const { userClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);

  const [prerequisitesSaved, setPrerequisitesSaved] = useState(false);
  const [formData, setFormData] = useState<CaseDetail_Types.TriageDetail>(
    caseDetails.triageDetail || CaseUtils.createEmptyTriage(caseDetails.id, getDate())
  );

  const pageRefs = [
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAwaitingPrerequisites, setIsAwaitingPrerequisites] = useState<boolean>(
    getCurrentStatus(caseDetails.statusHistory).value ===
    LocalEnumerations.CaseStatuses.AwaitingPrerequisites,
  );
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const getDateOrNull = (dateString: string | null | undefined | Date): Date | null => {
    return dateString ? new Date(dateString) : null;
  };

  const showMessage = (message: string, state: ControlState) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
  };

  useEffect(() => {
    loadDataAsync();
    if (caseDetails) {
      setFormData(caseDetails.triageDetail || CaseUtils.createEmptyTriage(caseDetails.id, getDate()));

      setIsAwaitingPrerequisites(
        getCurrentStatus(caseDetails.statusHistory).value === LocalEnumerations.CaseStatuses.AwaitingPrerequisites,
      );
      
      setIsLoading(false);
    }
  }, [caseDetails]); 

  /**
   * Handles the change event for form controls and updates the form data state.
   */
  const handleDataChange = (
    result: FormControlOnChangeData<boolean | string | number | number[] | string[] | null>
  ) => {
    const updatedFormData = {
      ...formData,
      [result.fieldId]: result.value,
    };
    setFormData(updatedFormData);
  };

  /**
   * Handles the change event for Date based form controls and updates the form data state.
   */
  const handleDateChange = (result: FormControlOnChangeData<Date | null>) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value ? new Date(result.value) : null,
    }));
  };

  useEffect(() => {
    const disableSave = !(
      formData.consentReceived &&
      formData.paymentApproved &&
      formData.questionsCompleted
    );
    setSaveDisabled(disableSave);
  }, [formData]);

  useEffect(() => {
    if (prerequisitesSaved) {
      setSaveDisabled(true);
    }
  }, [prerequisitesSaved]);


  const loadDataAsync = async () => {
    CaseUtils.getTriageByCaseAsync(
      caseDetails!.id,
      await getHeadersAsync(userClaims, instance)
    ).then((rst) => {
      if (rst.isFailure) {
        showMessage(locales.ApiResponses.loadingFailed, 'negative');
        return;
      }

      if (
        rst.result?.caseTriage !== undefined &&
        rst.result?.caseTriage !== null
      ) {
        setFormData(rst.result!.caseTriage!);
      }

      setIsAwaitingPrerequisites(
        rst.result?.currentCaseStatus ===
          (LocalEnumerations.CaseStatuses.AwaitingPrerequisites as number),
      );
      setIsLoading(false);
    });
  };

  const saveRecord = async () => {
    const validationResults = pageRefs.map((innerRef) =>
      innerRef.current?.triggerValidation()
    );
  
    const tabValid = validationResults.every((result) => result === true);
    if (!tabValid) {
      return;
    }
  
    await onSave(formData);
  
    setPrerequisitesSaved(true); 
    setIsAwaitingPrerequisites(false); 
    onSaveSuccess(); 
  
    setFormData((prevData) => ({
      ...prevData,
    }));
  };

  return (isLoading || isCaseLoading) ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
        <FormCheckbox
          displayMode="Box"
          fieldId="paymentApproved"
          id="paymentApproved"
          label={locales.labels.paymentApproved}
          value={formData?.paymentApproved ?? false}
          onChange={handleDataChange}
          readonly={!isAwaitingPrerequisites}
          ref={pageRefs[0]}
        />
        <FormTextCapture
          displayMode="Box"
          fieldId="purchaseOrderNumber"
          id="purchaseOrderNumber"
          textInputType="text"
          maxLength={50}
          label={locales.labels.purchaseOrderNumber}
          value={formData?.purchaseOrderNumber ?? ''}
          onChange={handleDataChange}
          readonly={!isAwaitingPrerequisites}
          ref={pageRefs[1]}
        />
        <FormDate
          displayMode="Box"
          fieldId="paymentApprovedDate"
          id="paymentApprovedDate"
          label={locales.labels.paymentApprovedDate}
          value={getDateOrNull(formData?.paymentApprovedDate)}
          onChange={handleDateChange}
          invalidDateText={locales.common.invalidDate}
          readonly={!isAwaitingPrerequisites}
          ref={pageRefs[2]}
          datePartLabels={locales.labels.dateParts}
        />
        <FormCheckbox
          displayMode="Box"
          fieldId="consentReceived"
          id="consentReceived"
          label={locales.labels.consentReceived}
          value={formData?.consentReceived ?? false}
          onChange={handleDataChange}
          readonly={!isAwaitingPrerequisites}
          ref={pageRefs[3]}
        />
        <FormDate
          displayMode="Box"
          fieldId="consentReceivedDate"
          id="consentReceivedDate"
          label={locales.labels.consentReceivedDate}
          value={getDateOrNull(formData?.consentReceivedDate)}
          onChange={handleDateChange}
          invalidDateText={locales.common.invalidDate}
          readonly={!isAwaitingPrerequisites}
          ref={pageRefs[4]}
          datePartLabels={locales.labels.dateParts}
        />
        <FormDate
          displayMode="Box"
          fieldId="consentApprovedDate"
          id="consentApprovedDate"
          label={locales.labels.consentApprovedDate}
          value={getDateOrNull(formData?.consentApprovedDate)}
          onChange={handleDateChange}
          invalidDateText={locales.common.invalidDate}
          readonly={!isAwaitingPrerequisites}
          ref={pageRefs[5]}
          datePartLabels={locales.labels.dateParts}
        />
        <FormCheckbox
          displayMode="Box"
          fieldId="questionsCompleted"
          id="questionsCompleted"
          label={locales.labels.questionsCompleted}
          value={formData?.questionsCompleted ?? false}
          onChange={handleDataChange}
          readonly={!isAwaitingPrerequisites}
          ref={pageRefs[6]}
        /> 
      {isAwaitingPrerequisites ? (
        <InformationButton
          key="informationSave"
          buttonDetails={{
            itemKey: 'informationSave',
            clickEvent: saveRecord,
            label: locales.labels.saveButton,
            mode: 'positive',
            disabled: saveDisabled,
          }}
        >
          <>{locales.labels.saveActionSummary}</>
        </InformationButton>
      ) : (
        <p>{locales.triageAlreadyCompletedSummary}</p>
      )}
    </div>
  );
};

export default PreRequisitesTab;
