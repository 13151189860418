import React, { useState, useEffect, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import Enumerations, {
  LocalEnumerations,
  getHeadersAsync,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import internalUserActions from '../../../systemUtils/userUtils/InternalUserActions';
import { useNavigate, useParams } from 'react-router-dom';
import Common_Types from '../../../sysObjects/apiModels/Common.types';
import { useMsal } from '@azure/msal-react';
import { KeyValuePairMultipleValues } from '../../../sysObjects/apiModels/KeyValuePairMultipleValues.types';
import OrgActions from '../../../systemUtils/organisation/OrganisationActions';
import ExternalUsersActions from '../../../systemUtils/userUtils/ExternalUserActions';
import ExternalUser_Types from '../../../sysObjects/apiModels/ExternalUser.types';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import FormDropDown from '../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import { FunctionalResult } from '../../../sysObjects/FunctionalResult';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import FormCheckboxList from '../../../systemComponents/sharedControls/formControls/formCheckboxList/FormCheckboxList';

const InternalUserCreateUpdate: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);
  const { role, id } = useParams();

  const formRefs = [
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
    [useRef<FormControlRef>(null), useRef<FormControlRef>(null)],
    [useRef<FormControlRef>(null), useRef<FormControlRef>(null)],
  ];

  const getRole = (name?: string | null) => {
    if (name === null || name === undefined) {
      return -1;
    }
    switch (name.toLowerCase()) {
      case 'referrer':
        return LocalEnumerations.Roles.Referrer;
      case 'assessor':
        return LocalEnumerations.Roles.Assessor;
      case 'associate':
        return LocalEnumerations.Roles.AssociateAssessor;
      case 'case':
        return LocalEnumerations.Roles.CaseManager;
      case 'admin':
        return LocalEnumerations.Roles.SuperUser;
      default:
        return -1;
    }
  };

  const { userClaims } = useContext(UserClaimsContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [genders, setGenders] = useState<KeyValuePair<number>[]>([]);
  const [ethnicity, setEthnicity] = useState<KeyValuePair<number>[]>([]);
  const [disabilities, setDisabilities] = useState<KeyValuePair<number>[]>([]);
  const [organisationId, setOrganisationId] = useState<string>('');

  const [referralOrganisations, setReferralOrganisations] = useState<
    KeyValuePair<string>[]
  >([]);

  const [bookingStaff, setBookingStaff] = useState<
    KeyValuePairMultipleValues<string>[]
  >([]);

  const [formData, setFormData] = useState<
    Common_Types.BaseUser | ExternalUser_Types.User
  >(internalUserActions.createDefaultUser(getRole(role)));

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleSelectChange = (
    result: FormControlOnChangeData<string | string[] | number | null>,
  ) => {
    if (result.value === null) {
      return;
    }
    if (Array.isArray(result.value)) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]:
        typeof result.value === 'number'
          ? result.value
          : parseInt((result.value as string)!, 10),
    }));

    if (
      result.fieldId === 'role' &&
      result.value !== LocalEnumerations.Roles.AssociateAssessor &&
      result.value !== LocalEnumerations.Roles.Assessor
    ) {
      setFormData((prevData) => ({
        ...prevData,
        msBookingsStaffMemberId: undefined,
        msBookingsBusinessId: undefined,
      }));
    }
  };

  const handleBookingStaffChange = (
    result: FormControlOnChangeData<string | number | string[] | null>,
  ) => {
    if (result.value === null) {
      return;
    }

    if (result.value === locales.controls.msBookingsStaffMemberId.defaultText) {
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: undefined,
        msBookingsBusinessId: undefined,
      }));

      return;
    }

    let item = bookingStaff.find((x) => x.key === result.value)!;

    if (!item) {
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
      msBookingsBusinessId: item.value2,
    }));
  };

  const renderBookingsControls = (tabIndex: number, rowIndex: number) => {
    // using a hidden form text capture for non assessor roles allows for validation
    return formData.role === LocalEnumerations.Roles.AssociateAssessor ||
      formData.role === LocalEnumerations.Roles.Assessor ? (
      <FormDropDown
        displayMode="Box"
        id="msBookingsStaffMemberId"
        fieldId="msBookingsStaffMemberId"
        items={bookingStaff}
        ref={formRefs[tabIndex][rowIndex]}
        value={formData.msBookingsStaffMemberId}
        requiredDetails={{
          formLabel: `${locales.common.required}`,
          message: `${locales.controls.msBookingsStaffMemberId.label} ${locales.common.requiredMessage}`,
        }}
        label={locales.controls.msBookingsStaffMemberId.label}
        defaultText={locales.controls.msBookingsStaffMemberId.defaultText}
        onChange={handleBookingStaffChange}
        hidden={
          formData.role !== LocalEnumerations.Roles.AssociateAssessor &&
          formData.role !== LocalEnumerations.Roles.Assessor
        }
      />
    ) : (
      <FormTextCapture
        displayMode="Box"
        fieldId="msBookingsStaffMemberId"
        id="msBookingsStaffMemberId"
        ref={formRefs[tabIndex][rowIndex]}
        label={locales.controls.msBookingsStaffMemberId.label}
        textInputType="text"
        value={formData.msBookingsStaffMemberId}
        maxLength={250}
        onChange={handleBookingStaffChange}
        hidden={true}
      />
    );
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const retrieveAndSetBookingsStaffMembers = async () => {
    return internalUserActions
      .findBookingsSystemStaffAsync(
        await getHeadersAsync(userClaims, instance)
      )
      .then((result) => {
        if (result.isFailure) {
          showMessage(
            getServerErrors(locales, result.errorCode),
            'negative',
            '/',
          );

          return FunctionalResult.Error('Failed to load host configuration');
        }

        setBookingStaff(
          result.result!.map((item) => ({
            key: item.msBookingsStaffMemberId,
            value: item.displayName,
            value2: item.msBookingsBusinessId,
          })),
        );
        return FunctionalResult.Success('Success');
      })
      .catch((error) => {
        showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
        return FunctionalResult.Error('Failed to load host configuration');
      });
  };

  useEffect(() => {
    if (getRole(role) === LocalEnumerations.Roles.Referrer) {
      context?.handleCrumbUpdate(
        [...locales.breadcrumbs.referrer].slice(
          0,
          locales.breadcrumbs.referrer.length - 1,
        ),
      );
    } else {
      context?.handleCrumbUpdate(
        [...locales.breadcrumbs.normal].slice(
          0,
          locales.breadcrumbs.normal.length - 1,
        ),
      );
    }
    if (!UserCore.userIsCaseManagerOrHigher(userClaims?.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }

    retrieveAndSetBookingsStaffMembers()
      .then((rst_BM) => {
        if (rst_BM.isFailure) {
          return FunctionalResult.Error('Failed to load host configuration');
        }
        setEthnicity(Enumerations.getEthnicity(intl.locale));
        setDisabilities(Enumerations.getDisabilities(intl.locale));
        setGenders(Enumerations.getGender(intl.locale));

        return FunctionalResult.Success('Success');
      })
      .then(async (rst_stepOne) => {
        if (rst_stepOne.isFailure) {
          return FunctionalResult.Error<Common_Types.BaseUser>(
            rst_stepOne.error!,
          );
        }

        if (!id) {
          return FunctionalResult.Success<Common_Types.BaseUser>(formData);
        }

        if (getRole(role) === LocalEnumerations.Roles.Referrer) {
          return ExternalUsersActions.getUserAsync(
            await getHeadersAsync(userClaims!, instance),
            id
          ).then((rst) => {
            if (rst.isFailure) {
              showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
              return FunctionalResult.Error<Common_Types.BaseUser>(rst.error!);
            }
            return FunctionalResult.Success(
              rst.result! as Common_Types.BaseUser,
            );
          });
        } else {
          return internalUserActions
            .getUserAsync(await getHeadersAsync(userClaims!, instance), id)
            .then((rst) => {
              if (rst.isFailure) {
                showMessage(
                  locales.ApiResponses.loadingFailed,
                  'negative',
                  '/',
                );
                return FunctionalResult.Error<Common_Types.BaseUser>(
                  rst.error!,
                );
              }
              return FunctionalResult.Success(
                rst.result! as Common_Types.BaseUser,
              );
            });
        }
      })
      .then(async (result) => {
        setIsLoading(false);
        if (result.isFailure) {
          return;
        }
        if (getRole(role) === LocalEnumerations.Roles.Referrer) {
          OrgActions.lookUpAsync(
            { hasBillingDetails: true },
            await getHeadersAsync(userClaims!, instance)
          ).then((rst) => {
            if (rst.error) {
              showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
              return;
            }
            setReferralOrganisations(
              rst.result!.map((item) => {
                return { key: item.id, value: item.name };
              }),
            );

            if (id) {
              setFormData(result.result as ExternalUser_Types.User);
              updateBreadcrumbs(
                role!,
                id,
                result.result as ExternalUser_Types.User,
              );
            } else {
              updateBreadcrumbs(
                role!,
                null,
                result.result as ExternalUser_Types.User,
              );
            }
          });
        } else {
          if (id) {
            setFormData(result.result as Common_Types.BaseUser);
            updateBreadcrumbs(
              role!,
              id,
              result.result as ExternalUser_Types.User,
            );
          } else {
            updateBreadcrumbs(
              role!,
              null,
              result.result as ExternalUser_Types.User,
            );
          }
        }
      });
  }, []);

  const updateBreadcrumbs = (
    role: string,
    id: string | null,
    result: ExternalUser_Types.User,
  ) => {
    const baseBreadcrumbs =
      getRole(role) === LocalEnumerations.Roles.Referrer
        ? locales.breadcrumbs.referrer
        : locales.breadcrumbs.normal;

    const breadcrumbs = id
      ? baseBreadcrumbs.slice(0, baseBreadcrumbs.length - 1).concat({
        label: `${result.surname}, ${result.name}`,
        link: '',
        key: id,
      })
      : baseBreadcrumbs;

    context?.handleCrumbUpdate(breadcrumbs);
  };

  const [pageStates, setPages] = useState<KeyValuePair<string>[]>([
    {
      key: locales.tabNames.pageOne,
      value: 'neutral',
    },
    {
      key: locales.tabNames.pageTwo,
      value: 'neutral',
    },
    {
      key: locales.tabNames.pageThree,
      value: 'neutral',
    },
    {
      key: locales.tabNames.pageFour,
      value: 'neutral',
    },
  ]);

  const pageChange = (oldPage: number) => {
    const validationResults = formRefs[oldPage].map((ref) =>
      ref.current?.triggerValidation(),
    );

    const allValid = validationResults.every((result) => result === true);

    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[oldPage] = {
        ...updatedPages[oldPage],
        value: allValid ? 'positive' : 'negative',
      };
      return updatedPages;
    });
  };

  const saveRecord = async () => {
    let allValid: boolean[] = [];

    formRefs.forEach((ref, i) => {
      const validationResults = ref.map((innerRef) =>
        innerRef.current?.triggerValidation(),
      );
      const tabValid = validationResults.every((result) => result === true);
      allValid.push(tabValid);
      setPages((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[i] = {
          ...updatedPages[i],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
    });

    const pageValid = allValid.every((isValid) => isValid);

    let formattedFormData = formData;

    if (
      formData.msBookingsStaffMemberId === '' ||
      formData.msBookingsStaffMemberId === undefined
    ) {
      formattedFormData = {
        ...formData,
        msBookingsBusinessId: undefined,
        msBookingsStaffMemberId: undefined,
      };
    }

    if (pageValid) {
      if (formData.role === LocalEnumerations.Roles.Referrer) {
        ExternalUsersActions.saveUserAsync(
          await getHeadersAsync(userClaims!, instance),
          formattedFormData as ExternalUser_Types.User,
          id,
        ).then((result) => {
          if (result.isFailure) {
            showMessage(getServerErrors(locales, result.errorCode), 'negative');
            return;
          }
          showMessage(
            `${formData.surname}, ${formData.name}: - ${locales.ApiResponses.saveSuccess}`,
            'positive',
            '/customers/accounts',
          );
        });
        return;
      }
      internalUserActions
        .saveUserAsync(await getHeadersAsync(userClaims!, instance), formattedFormData, id)
        .then((result) => {
          if (result.isFailure) {
            showMessage(getServerErrors(locales, result.errorCode), 'negative');
            return;
          }
          showMessage(
            `${formData.surname}, ${formData.name}: - ${locales.ApiResponses.saveSuccess}`,
            'positive',
            '/team/accounts',
          );
        });
      return;
    }
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        backLabel={locales.labels.back}
        nextLabel={locales.labels.forward}
        pageChangeAction={pageChange}
        pages={[
          {
            name: pageStates[0].key,
            mode: pageStates[0].value as ControlState,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormDropDown
                  fieldId="organisationId"
                  id="organisationId"
                  label={locales.controls.organisationId.label}
                  displayMode="Box"
                  items={referralOrganisations}
                  value={
                    getRole(role) === LocalEnumerations.Roles.Referrer
                      ? (formData as ExternalUser_Types.User).organisationId
                      : organisationId
                  }
                  defaultText="Select"
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.organisationId.label} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0][3]}
                  onChange={(result) => {
                    if (getRole(role) === LocalEnumerations.Roles.Referrer) {
                      (formData as ExternalUser_Types.User).organisationId =
                        result.value as string;
                    }
                    setOrganisationId(result.value as string);
                  }}
                  hidden={formData.role !== LocalEnumerations.Roles.Referrer}
                />
                <FormTextCapture
                  fieldId="name"
                  ref={formRefs[0][0]}
                  id="name"
                  label={locales.controls.name.label}
                  requiredDetails={{
                    formLabel: `${locales.common.required}`,
                    message: `${locales.controls.name.label} ${locales.common.requiredMessage}`,
                  }}
                  textInputType="text"
                  value={formData.name}
                  displayMode="Box"
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormTextCapture
                  fieldId="otherNames"
                  id="otherNames"
                  displayMode="Box"
                  ref={formRefs[0][1]}
                  label={locales.controls.secondName.label}
                  textInputType="text"
                  value={formData.otherNames}
                  maxLength={250}
                  onChange={handleFormChange}
                  hidden={formData.role !== LocalEnumerations.Roles.Referrer}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="surname"
                  id="surname"
                  ref={formRefs[0][2]}
                  label={locales.controls.surname.label}
                  requiredDetails={{
                    formLabel: `${locales.common.required}`,
                    message: `${locales.controls.surname.label} ${locales.common.requiredMessage}`,
                  }}
                  textInputType="text"
                  value={formData.surname}
                  maxLength={250}
                  onChange={handleFormChange}
                />
              </>
            ),
          },
          {
            name: pageStates[1].key,
            mode: pageStates[1].value as ControlState,
            enabled: true,
            orderNo: 2,
            showAsOrdered: true,
            content: (
              <>
                <FormDropDown
                  id="gender"
                  fieldId="gender"
                  displayMode="Box"
                  items={genders}
                  ref={formRefs[1][0]}
                  value={formData.gender}
                  label={locales.controls.gender.label}
                  defaultText={locales.controls.gender.defaultText}
                  onChange={handleSelectChange}
                />
                <FormTextCapture
                  fieldId="jobTitle"
                  id="jobTitle"
                  displayMode="Box"
                  ref={formRefs[1][1]}
                  helpMessage={locales.controls.jobTitle.helperText}
                  label={locales.controls.jobTitle.label}
                  requiredDetails={
                    formData.role !== LocalEnumerations.Roles.Referrer
                      ? undefined
                      : {
                        formLabel: `${locales.common.required}`,
                        message: `${locales.controls.jobTitle.label} ${locales.common.requiredMessage}`,
                      }
                  }
                  textInputType="text"
                  value={formData.jobTitle}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormDropDown
                  id="ethnicity"
                  fieldId="ethnicity"
                  ref={formRefs[1][2]}
                  displayMode="Box"
                  items={ethnicity}
                  value={formData.ethnicity}
                  label={locales.controls.ethnicity.label}
                  defaultText={locales.controls.ethnicity.defaultText}
                  onChange={handleSelectChange}
                  hidden={formData.role !== LocalEnumerations.Roles.Referrer}
                />
                <FormCheckboxList
                  allowManySelect
                  displayMode="Box"
                  ref={formRefs[1][3]}
                  id="disabilities"
                  fieldId="disabilities"
                  items={disabilities}
                  value={formData.disabilities}
                  label={locales.controls.disabilities.label}
                  onChange={handleFormChange}
                  hidden={formData.role !== LocalEnumerations.Roles.Referrer}
                />
                {renderBookingsControls(1, 3)}
              </>
            ),
          },
          {
            name: pageStates[2].key,
            mode: pageStates[2].value as ControlState,
            enabled: true,
            orderNo: 3,
            showAsOrdered: true,
            content: (
              <div key={pageStates[2].key}>
                <FormTextCapture
                  fieldId="primaryEmail"
                  displayMode="Box"
                  id="primaryEmail"
                  ref={formRefs[2][0]}
                  label={locales.controls.primaryEmail.label}
                  requiredDetails={{
                    formLabel: `${locales.common.required}`,
                    message: `${locales.controls.primaryEmail.label} ${locales.common.requiredMessage}`,
                  }}
                  textInputType="email"
                  value={formData.primaryEmail}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="secondaryEmail"
                  id="secondaryEmail"
                  ref={formRefs[2][1]}
                  label={locales.controls.secondaryEmail.label}
                  textInputType="email"
                  value={formData.secondaryEmail}
                  maxLength={250}
                  onChange={handleFormChange}
                  hidden={formData.role !== LocalEnumerations.Roles.Referrer}
                />
              </div>
            ),
          },
          {
            name: pageStates[3].key,
            mode: pageStates[3].value as ControlState,
            enabled: true,
            orderNo: 4,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  fieldId="primaryTelephoneNumber"
                  id="primaryTelephoneNumber"
                  ref={formRefs[3][0]}
                  label={locales.controls.primaryTelephoneNumber.label}
                  textInputType="tel"
                  value={formData.primaryTelephoneNumber}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={
                    formData.role !== LocalEnumerations.Roles.Referrer
                      ? undefined
                      : {
                        formLabel: `${locales.common.required}`,
                        message: `${locales.controls.primaryTelephoneNumber.label} ${locales.common.requiredMessage}`,
                      }
                  }
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="secondaryTelephoneNumber"
                  id="secondaryTelephoneNumber"
                  ref={formRefs[3][1]}
                  label={locales.controls.secondaryTelephoneNumber.label}
                  textInputType="tel"
                  value={formData.secondaryTelephoneNumber}
                  maxLength={250}
                  onChange={handleFormChange}
                  hidden={formData.role !== LocalEnumerations.Roles.Referrer}
                />
              </>
            ),
          },
        ]}
      />
      <InformationButton
        key="informationSAve"
        buttonDetails={{
          itemKey: 'informationSave',
          clickEvent: saveRecord,
          label: locales.labels.save,
          mode: 'positive',
        }}
      >
        <>{locales.labels.SaveSummary}</>
      </InformationButton>
    </div>
  );
};

export default InternalUserCreateUpdate;
