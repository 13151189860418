import React from 'react';
import SVGTypes from './svg.types';

export const Filter: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99414)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM10 9.75C10 9.55109 10.079 9.36032 10.2197 9.21967C10.3603 9.07902 10.5511 9 10.75 9H28.75C28.9489 9 29.1397 9.07902 29.2803 9.21967C29.421 9.36032 29.5 9.55109 29.5 9.75V12.75C29.5 12.935 29.4316 13.1134 29.308 13.251L22.75 20.538V27.75C22.7499 27.9073 22.7003 28.0607 22.6082 28.1883C22.5161 28.3159 22.3863 28.4113 22.237 28.461L17.737 29.961C17.6243 29.9985 17.5043 30.0088 17.3869 29.9909C17.2695 29.9729 17.158 29.9274 17.0616 29.858C16.9653 29.7886 16.8867 29.6972 16.8326 29.5915C16.7784 29.4858 16.7501 29.3688 16.75 29.25V20.538L10.192 13.251C10.0684 13.1134 10 12.935 10 12.75V9.75ZM11.5 10.5V12.462L18.058 19.749C18.1816 19.8866 18.25 20.065 18.25 20.25V28.209L21.25 27.21V20.25C21.25 20.065 21.3184 19.8866 21.442 19.749L28 12.462V10.5H11.5Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99414">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Filter;
