import React, { FC } from 'react';
import Expander from '../../../general/expander/Expander';
import ListTableRowTypes from './ListTableRow.types';
import BaseBlock from '../../../reusableBlocks/baseBlock/BaseBlock';
import CloseBox from '../../../targetedControls/closeBox/CloseBox';
import './ListTableRow.css';

const ListTableRow: FC<ListTableRowTypes.Props> & {
  Expanded: FC<React.PropsWithChildren<{}>>;
  Collapsed: FC<React.PropsWithChildren<{}>>;
  ButtonRow: FC<ButtonRowProps>;
} = (props) => {
  const expanded = React.Children.toArray(props.children).find(
    (child: any) => child.type === ListTableRowExpanded
  );

  const collapsed = React.Children.toArray(props.children).filter(
    (child: any) => child.type === ListTableRowCollapsed
  );

  const buttonRow = React.Children.toArray(props.children).find(
    (child: any) => child.type === ButtonRow
  );

  return (
    <div
      className={`List-Table-Row${props.className ? ` ${props.className}` : ''}`} aria-roledescription='row'>
      <Expander
        key={`${props.id}_Status`}
        id={`${props.id}_Status`}
        isExpanded={props.isExpanded}
        className="Expand-Item"
        eventHandler={{
          onClick: () =>
            props.events.OnExpandChange(!props.isExpanded, props.id),
        }}
      />
      {props.isExpanded && (
        <div className="Expanded-View" data-testid={`${props.id}_ExpandedView`}>
          {expanded}
          <div className="Close-Col">
            <CloseBox
              dataTestId={`${props.id}_ExpandedViewClose`}
              onClick={() => props.events.OnExpandChange(false, props.id)}
            />
          </div>
        </div>
      )}
      {!props.isExpanded && (
        <>
          <div
            className="Collapsed-View"
            data-testid={`${props.id}_CollapsedView`}
          >
            {collapsed}
          </div>
          {buttonRow && (
            <BaseBlock
              boxSize="medium"
              id={`${props.id}_ButtonRowExpander`}
              testid={`${props.id}_ButtonRowExpander`}
              content={'...'}
              className="Button-Item"
              rounded={true}
              onClick={() => {
                if (!props.events.OnButtonVisibilityChange) {
                  return;
                }
                props.events.OnButtonVisibilityChange(
                  !props.isButtonRowExpanded,
                  props.id
                );
                if (!props.isButtonRowExpanded) {
                  props.events.OnExpandChange(false, props.id);
                }
              }}
            />
          )}
        </>
      )}

      {buttonRow}
    </div>
  );
};

const ListTableRowExpanded: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return children ? <>{children}</> : null;
};

const ListTableRowCollapsed: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return children ? <>{children}</> : null;
};

interface ButtonRowProps {
  children?: React.ReactNode;
  isExpanded: boolean;
  actionLabel: string;
  id: string;
}

const ButtonRow: React.FC<ButtonRowProps> = ({
  children,
  isExpanded,
  actionLabel,
  id,
}) => {
  return children ? (
    <div
      data-testid={`${id}-Row-Buttons-Container`}
      className={`Row-Buttons-Container ${
        isExpanded ? 'Expanded' : 'Collapsed'
      }`}
    >
      <span className="Action-Label Impact-Strong">{actionLabel}</span>
      {children}
    </div>
  ) : null;
};
ListTableRow.Expanded = ListTableRowExpanded;
ListTableRow.Collapsed = ListTableRowCollapsed;
ListTableRow.ButtonRow = ButtonRow;

export default ListTableRow;
