import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import serviceTypes from '../../../sysObjects/apiModels/ServiceDefinition.types';
import actions from '../../../systemUtils/services/ServiceDefinitionActions';
import Enumerations, {
  getHeadersAsync,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import FormDropDown from '../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import FormTextArea from '../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
const ServiceDefinition_CU: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { id } = useParams();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);

  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [selectedCondition, setSelectedCondition] = React.useState<
    string | undefined
  >();
  const [deliveryFormat, setDeliveryFormats] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [relatedConditions, setRelatedConditions] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [formData, setFormData] =
    React.useState<serviceTypes.ServiceDefinition>(actions.createDefault());
  const [tabStatus, setTabStatus] = React.useState<ControlState>('neutral');

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleDropDownFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: parseInt(result.value as string),
    }));
  };

  const handleFormChangeSetSelectedCondition = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setSelectedCondition(result.value as string);
    if (result.value !== '99') {
      setFormData((prevData) => ({
        ...prevData,
        otherRelatedCondition: '',
      }));
    }

    setFormData((prevData) => ({
      ...prevData,
      relatedCondition: parseInt(result.value as string),
    }));
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    actions
      .getAsync(await getHeadersAsync(userClaims, instance), id!)
      .then((result) => {
        if (result.isFailure) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
          return;
        }
        setFormData(result.result!);
        setIsLoading(false);
        if (result.result?.relatedCondition === 99) {
          setSelectedCondition('99');
        }
        context?.handleCrumbUpdate(
          [...locales.breadcrumbs]
            .slice(0, locales.breadcrumbs.length - 1)
            .concat({
              label: `${result.result!.externalDisplayName} - ${Enumerations.getRelatedConditions(intl.locale).find((rc) => rc.key === result.result!.relatedCondition)?.value} - ${Enumerations.getDeliveryFormats(intl.locale).find((df) => df.key === result.result!.deliveryFormat)?.value}`,
              key: id,
            }),
        );
      })
      .catch(() => {
        showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
      });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }

    setDeliveryFormats(Enumerations.getDeliveryFormats(intl.locale));
    setRelatedConditions(Enumerations.getRelatedConditions(intl.locale));
    context?.handleCrumbUpdate(
      [...locales.breadcrumbs].slice(0, locales.breadcrumbs.length - 1),
    );

    if (id) {
      loadDataAsync();
    } else {
      setIsLoading(false);
      context?.handleCrumbUpdate([...locales.breadcrumbs]);
    }
  }, []);

  const saveRecord = async () => {
    const validationResults = formRefs.map((innerRef) =>
      innerRef.current?.triggerValidation(),
    );
    const allValid = validationResults.every((result) => result === true);

    if (!allValid) {
      setTabStatus('negative');
      return;
    }

    actions
      .saveAsync(await getHeadersAsync(userClaims, instance), formData, id)
      .then((result) => {
        if (result.isFailure) {
          showMessage(
            getServerErrors(locales, result.errorCode),
            'negative'
          );
          return;
        }
        showMessage(
          `${formData.externalDisplayName} ${locales.ApiResponses.saveSuccess}`,
          'positive',
          '/services',
        );
      });
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        key={'pricingDefinition'}
        pages={[
          {
            name: 'Details',
            mode: tabStatus,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  id="serviceType"
                  fieldId="serviceType"
                  label={locales.formControlLabels.serviceType}
                  textInputType="text"
                  value={formData.serviceType}
                  maxLength={250}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.formControlLabels.serviceType} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0]}
                  onChange={handleFormChange}
                />
                <FormTextCapture
                  displayMode="Box"
                  id="serviceCode"
                  fieldId="serviceCode"
                  label={locales.formControlLabels.serviceCode}
                  textInputType="text"
                  value={formData.serviceCode}
                  maxLength={250}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.formControlLabels.serviceCode} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1]}
                  onChange={handleFormChange}
                />
                <FormTextCapture
                  displayMode="Box"
                  id="externalDisplayName"
                  fieldId="externalDisplayName"
                  label={locales.formControlLabels.externalDisplayName}
                  textInputType="text"
                  value={formData.externalDisplayName}
                  maxLength={200}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.formControlLabels.externalDisplayName} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[2]}
                  onChange={handleFormChange}
                />
                <FormTextCapture
                  displayMode="Box"
                  id="nominalCode"
                  fieldId="nominalCode"
                  label={locales.formControlLabels.nominalCode}
                  textInputType="text"
                  value={formData.nominalCode}
                  maxLength={250}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.formControlLabels.nominalCode} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[3]}
                  onChange={handleFormChange}
                />
                <FormTextArea
                  displayMode="Box"
                  fieldId="description"
                  id="description"
                  label={locales.formControlLabels.serviceDescription}
                  value={formData.description}
                  maxLength={250}
                  ref={formRefs[4]}
                  onChange={handleFormChange}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="deliveryFormat"
                  id="deliveryFormat"
                  value={formData.deliveryFormat}
                  items={deliveryFormat}
                  label={locales.formControlLabels.deliveryFormat}
                  defaultText={locales.common.Select_Default}
                  onChange={handleDropDownFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.formControlLabels.deliveryFormat} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[5]}
                />
                <FormDropDown
                  fieldId="relatedCondition"
                  id="relatedCondition"
                  displayMode="Box"
                  value={formData.relatedCondition}
                  items={relatedConditions}
                  label={locales.formControlLabels.relatedCondition}
                  defaultText={locales.common.Select_Default}
                  onChange={handleFormChangeSetSelectedCondition}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.formControlLabels.relatedCondition} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[6]}
                />
                <FormTextCapture
                  id="otherRelatedCondition"
                  fieldId="otherRelatedCondition"
                  label={locales.formControlLabels.otherRelatedCondition}
                  displayMode="Box"
                  textInputType="text"
                  value={formData.otherRelatedCondition}
                  maxLength={250}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.formControlLabels.otherRelatedCondition} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[7]}
                  onChange={handleFormChange}
                  hidden={selectedCondition !== '99'}
                />
              </>
            ),
          },
        ]}
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButton',
            label: locales.infoButton.label,
            mode: 'positive',
            clickEvent: saveRecord,
          }}
        >
          {locales.infoButton.summary}
        </InformationButton>
      </div>
    </div>
  );
};

export default ServiceDefinition_CU;
