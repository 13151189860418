import React, { useState } from 'react';
import AddBillableItemRowTypes from './AddBillableItemRow.types';
import StatusLabel from '../../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import Expander from '../../../../sharedControls/general/expander/Expander';
import Button from '../../../../sharedControls/general/button/Button';
import { SvgBad } from '../../../../sharedControls/svg';
import { FormControlOnChangeData } from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import FormDropDown from '../../../../sharedControls/formControls/formDropDown/FormDropDown';
import FormTextArea from '../../../../sharedControls/formControls/formTextArea/FormTextArea';

const AddBillableItemRow: React.FC<AddBillableItemRowTypes.Props> = (props) => {
  const [isExpanded, setExpandedState] = useState<Boolean>(
    props.isExpanded ?? false
  );
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [notes, setNotes] = useState<string>('');

  const renderExpander = () => {
    return (
      <div>
        <Expander
          isExpanded={isExpanded}
          id={`$Add_Row_Expander`}
          key={`$Add_Row_Expander`}
          eventHandler={{onClick: () => setExpandedState(!isExpanded)}}
        />
      </div>
    );
  };

  const addBillableItem = async () => {
    if (!canSelectAdd || selectedService === null) {
      return;
    }
    await props.onSave(selectedService, notes);

    handleCancellation();
  };

  const canSelectAdd = () => {
    return selectedService !== null;
  };

  const handleCancellation = () => {
    setSelectedService(null);
    setExpandedState(false);
  };

  const renderExpandedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.labels.rowTitle}</div>
        <div />
        <div />
        <div />
        <div className="Small-Cancel">
          <Button
            itemKey={'CancelButton'}
            children={
              <SvgBad
                width={20}
                height={20}
                fill={'var(--System-Colour-Lexxic-Primary)'}
              />
            }
            clickEvent={() => handleCancellation()}
            mode="neutral"
            useCooldown={false}
          />
        </div>
      </>
    );
  };

  const renderCollapsedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.labels.rowTitle}</div>
        <div />
        <div />
        <div />
        <div />
      </>
    );
  };

  const renderServiceInformation = () => {
    let retrievedServiceToFulfill = props.servicesToFulfill.find(
      (service) => service.id === selectedService
    );

    return (
      <>
        <div className="col Text-Regular">
          {props.labels.billableOrganisationName}
        </div>
        <div className="Value-Column">
          {retrievedServiceToFulfill?.billableOrganisationName && (
            <StatusLabel
              label={props.labels.billableOrganisationName}
              renderAs="span"
              key="billable_item_organisation"
              status="neutral"
            />
          )}

          <p>
            {retrievedServiceToFulfill?.billableOrganisationName ||
              'Select a service'}
          </p>
        </div>

        <div className="col Text-Regular">{props.labels.codes}</div>
        <div className="Value-Column">
          {retrievedServiceToFulfill?.serviceDefinition?.nominalCode && (
            <StatusLabel
              label={props.labels.nominalCode}
              renderAs="span"
              key="billable_item_nominal_code"
              status="neutral"
            />
          )}

          {retrievedServiceToFulfill?.serviceDefinition?.nominalCode ||
            'Select a service'}

          {retrievedServiceToFulfill?.serviceDefinition?.serviceCode && (
            <StatusLabel
              label={props.labels.serviceCode}
              renderAs="span"
              key="billable_item_service_code"
              status="neutral"
            />
          )}
          {retrievedServiceToFulfill?.serviceDefinition?.serviceCode}
        </div>
      </>
    );
  };

  const handleDropdownChange = (
    e: FormControlOnChangeData<string | number | string[] | null>
  ) => {
    if (typeof e.value !== 'string') {
      return;
    }

    setSelectedService(e.value);
  };

  const renderExpandedRow = () => {
    return (
      <div className="Expanded-row">
        <FormDropDown
          items={props.servicesToFulfill.map((service) => {
            return {
              key: service.id,
              value: service.serviceName + ' [' + service.serviceReference + ']',
            };
          })}
          fieldId={'serviceToFulfillId'}
          defaultText="Select a service"
          requiredDetails={{ formLabel: '', message: '' }}
          value={selectedService}
          displayMode="Column"
          onChange={(e) => handleDropdownChange(e)}
          id={'service_to_fulfillId'}
          label={props.labels.serviceName}
        />

        <FormTextArea
          fieldId={'notes'}
          id={'notes'}
          displayMode="Column"
          label={props.labels.notes}
          onChange={(e) => setNotes(e.value as string)}
        />

        {renderServiceInformation()}

        <div className="col Text-Regular">{props.labels.actions}</div>
        <div className="col-buttons">
          <Button
            label={props.labels.add}
            disabled={!canSelectAdd()}
            clickEvent={() => addBillableItem()}
            mode="positive"
            itemKey={'add_button'}
          />
          <Button
            label={props.labels.cancel}
            mode="negative"
            clickEvent={() => handleCancellation()}
            itemKey={'cancel_button'}
            useCooldown={false}
          />
        </div>
      </div>
    );
  };

  const renderRow = () => {
    return (
      <>
        {isExpanded ? renderExpandedHeader() : renderCollapsedHeader()}
        {isExpanded && renderExpandedRow()}
      </>
    );
  };

  return <div className="Billable-Item">{renderRow()}</div>;
};

export default AddBillableItemRow;
