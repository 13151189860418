import React from 'react';
import { TaskListProps } from './TaskList.types';
import TaskItem from '../taskItem/TaskItem';

import './TaskList.css';

const TaskList: React.FC<TaskListProps> = (props) => {
  return (
    <div className="Task-List">
      <div className="Task-List-Header">
        <h1 className="Font-Heading">{props.Label}</h1>
      </div>
      <div className="Task-List-Body">
        {props.isLoading ? (
          <div>Loading</div>
        ) : !props.errorMessage ? (
          props.tasks.map((task, index) => {
            if(props.CompleteTicked){
              task.CompleteTicked = props.CompleteTicked;
            }
            return (
              <TaskItem
                key={index}
                {...task}
              />
            );
          })
        ) : (
          <div>{props.errorMessage}</div>
        )}
      </div>
    </div>
  );
};

export default TaskList;
