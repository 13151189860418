import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Case_Types,
  CaseDetail_Types,
} from '../../../sysObjects/apiModels/Case.types';
import CaseUtils from '../../../systemUtils/case/caseUtils';
import { useIntl } from 'react-intl';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import Enumerations, {
  getHeadersAsync,
  getCurrentStatus,
  LocalEnumerations,
  getTimeSince,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import AppointmentsTab from '../../../systemComponents/targetedPageControls/case/tabs/appointmentsTab/AppointmentsTab';
import BillableItemsTab from '../../../systemComponents/targetedPageControls/case/tabs/billableItemsTab/BillableItemsTab';
import StatusHistory from '../../../sysObjects/apiModels/StatusHistory.types';
import BookingRowTypes from '../../../systemComponents/targetedPageControls/case/appointments/bookingRow/BookingRow.types';
import ServiceDefinitionActions from '../../../systemUtils/services/ServiceDefinitionActions';
import DocumentTab from '../../../systemComponents/targetedPageControls/case/tabs/documentsTab/DocumentsTab';
import NotesTab from '../../../systemComponents/targetedPageControls/case/tabs/noteTab/NotesTab';
import DocumentUtils from '../../../systemUtils/document/DocumentActions';
import DocumentActions from '../../../systemUtils/document/DocumentActions';
import DocumentRowTypes from '../../../systemComponents/targetedPageControls/case/documentDetails/documentRow/DocumentRow.types';
import CaseNote_Types from '../../../sysObjects/apiModels/CaseNote.types';
import DateHelpers, {
  getLocalDateString,
} from '../../../systemUtils/common/DateHelpers';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import ServicesToFulfillActions from '../../../systemUtils/services/ServicesToFulfillActions';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CaseServiceTab from '../../../systemComponents/targetedPageControls/case/tabs/caseServicesTab/CaseServicesTab';
import PreRequisitesTab from '../../../systemComponents/targetedPageControls/case/tabs/preRequisitesTab/PreRequisitesTab';

import locales from './locales/en-GB.json';
import ReferralTab from '../../../systemComponents/targetedPageControls/case/tabs/referralTab/ReferralTab';
import RecommendationsTab from '../../../systemComponents/targetedPageControls/case/tabs/recommendationsTab/RecommendationsTab';
import { CaseContainerPageTypes } from '../../../systemUtils/case/CaseContinerPage.types';
import OutcomesTab from '../../../systemComponents/targetedPageControls/case/tabs/outComesTab/OutcomesTab';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import { resourceLimits } from 'worker_threads';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
const CaseContainerPage: React.FC = () => {
  const intl = useIntl();
  const { userClaims } = useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);

  const { id } = useParams();
  const [caseDetails, setCaseDetails] =
    useState<Case_Types.RetrievedCase | null>(null);

  const [caseDocuments, setCaseDocuments] = useState<
    DocumentRowTypes.Document[]
  >([]);
  const [caseNotes, setCaseNotes] = useState<CaseNote_Types.RetrievedNote[]>(
    []
  );

  const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
  const [documentRefreshRequired, setDocumentRefreshRequired] =
    useState<boolean>(true);
  const [noteRefreshRequired, setNoteRefreshRequired] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [currentPageReference, setCurrentPageReference] = useState<number>(0);

  const [isInitialisingCase, setInitialisingCase] = useState<boolean>(true);
  const [areDocumentsLoading, setDocumentsLoading] = useState<boolean>(true);
  const [areNotesLoading, setNotesLoading] = useState<boolean>(true);
  const [isCaseReloading, setCaseReloading] = useState<boolean>(false);
  const isCaseLoading = isInitialisingCase || isCaseReloading;
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [documentFlicker, setDocumentFlicker] = useState<boolean>(false);

  const formats = Enumerations.getDeliveryFormats(intl.locale);
  const conditions = Enumerations.getRelatedConditions(intl.locale);
  const caseStatuses = Enumerations.getCaseStatuses(intl.locale);
  const servicesRequired = Enumerations.getServiceRequired(intl.locale);
  const referralReasons = Enumerations.getReferralReasons(intl.locale);

  const canUpdateStatus = (
    history: StatusHistory<number>[]
  ): { canUpdateStatus: boolean; stateId: number } => {
    const canEdit = UserCore.userIsAssociateAssessorOrHigher(userClaims?.user!);
    if (!canEdit) {
      return { canUpdateStatus: false, stateId: 0 };
    }

    if (!history || history.length === 0) {
      return { canUpdateStatus: false, stateId: 0 };
    }

    history.sort(
      (a, b) =>
        new Date(b.createdDateTime).getTime() -
        new Date(a.createdDateTime).getTime()
    );

    const value = history[0].value;
    return {
      canUpdateStatus:
        value === LocalEnumerations.AppointmentStatuses.Offered ||
        value === LocalEnumerations.AppointmentStatuses.Confirmed,
      stateId: value,
    };
  };

  const isAppointmentService = (history: StatusHistory<number>[]): boolean => {
    if (!history || history.length === 0) {
      return false;
    }

    return history.some(
      (historyItem) =>
        historyItem.value ===
        LocalEnumerations.ServiceStatuses.RequiresAppointment
    );
  };

  const canAddBillableItems = () => {
    if (!UserCore.userIsCaseManagerOrHigher(userClaims?.user!)) {
      return false;
    }

    if (
      !caseDetails?.servicesToFulfill ||
      caseDetails.servicesToFulfill.length === 0
    ) {
      return false;
    }

    let caseStatus = getCurrentStatus(caseDetails.statusHistory);

    if (
      caseStatus.value !==
      LocalEnumerations.CaseStatuses.ActiveServiceDeliveries
    ) {
      return false;
    }

    return true;
  };

  const needsAppointmentBooking = (
    history: StatusHistory<number>[]
  ): boolean => {
    if (!history || history.length === 0) {
      return false;
    }

    history.sort(
      (a, b) =>
        new Date(b.createdDateTime).getTime() -
        new Date(a.createdDateTime).getTime()
    );

    const value = history[0].value;
    return value === LocalEnumerations.ServiceStatuses.RequiresAppointment;
  };

  const getAppointments = (
    item: CaseDetail_Types.RetrievedServiceToFulfill
  ): BookingRowTypes.ServiceDetails[] => {
    const result = item.appointments.map((appointment, idx) => {
      const stateDetails = canUpdateStatus(appointment.statusHistory);
      return {
        isExpanded: false,
        rowId: `${item.id}_${idx}`,
        serviceToFulfillId: item.id,
        serviceDefinitionId: item.serviceId,
        serviceName:
          ServiceDefinitionActions.retrieveServiceDefinitionDescriptorAsKeyValuePair(
            item.serviceDefinition!,
            formats,
            conditions
          ).value,
        serviceReference: item.serviceReference,
        duration:
          item.serviceDefinition?.assessmentAppointmentDefinition?.duration,
        bookingDetails: {
          appointmentId: appointment.id,
          statusId: stateDetails.stateId as number,
          bookingDate: appointment.startDateTime,
          duration: appointment.duration,
          canEditBooking: false,
          isChargeable: appointment.isChargeable,
          state: stateDetails,
          isInvoiced: isAppointmentInvoiced(item),
          name: `${
            appointment.assessor.surname
              ? appointment.assessor.surname + ', '
              : ''
          }${
            appointment.assessor.name ? appointment.assessor.name : ''
          }`.trim(),
        } as BookingRowTypes.BookingDetails,
      };
    });

    if (
      needsAppointmentBooking(item.statusHistory) &&
      !result.some((item) =>
        [
          LocalEnumerations.AppointmentStatuses.Offered,
          LocalEnumerations.AppointmentStatuses.Confirmed,
        ].includes(item.bookingDetails!.statusId as number)
      )
    ) {
      result.push({
        isExpanded: false,
        rowId: item.id,
        serviceToFulfillId: item.id,
        serviceDefinitionId: item.serviceDefinition?.id,
        duration:
          item.serviceDefinition?.assessmentAppointmentDefinition?.duration,
        serviceName:
          ServiceDefinitionActions.retrieveServiceDefinitionDescriptorAsKeyValuePair(
            item.serviceDefinition!,
            formats,
            conditions
          ).value,
      } as any);
    }

    return result as BookingRowTypes.ServiceDetails[];
  };

  const isAppointmentInvoiced = (
    serviceToFulfill: CaseDetail_Types.RetrievedServiceToFulfill
  ) => {
    if (
      !serviceToFulfill.billableItems ||
      serviceToFulfill.billableItems.length === 0
    ) {
      return false;
    }

    return serviceToFulfill.billableItems.some((item) => item.isInvoiced);
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const [pageStates, setPages] = useState<KeyValuePair<string>[]>(
    Array.from({ length: 10 }, (_, index) => {
      return {
        key: locales.labels.tabs[index],
        value: 'neutral',
      };
    })
  );

  const loadCaseDocumentsAsync = async () => {
    const vis = Enumerations.getDocumentVisibilityState(intl.locale);

    const result = await DocumentActions.findDocumentsOnCaseAsync(
      {
        caseId: caseDetails!.id,
        relatedExternalUserId: caseDetails?.caseIdentifiers.customerAccountId!,
      },
      await getHeadersAsync(userClaims, instance)
    ).then((result) => {
      if (result.isFailure) {
        showMessage(locales.ApiResponses.errors.documentLoadError, 'negative');
        return;
      }

      setCaseDocuments(
        result.result!.map((doc) => {
          const uploadedDate = new Date(doc.createdDateTime);
          const editedDate = new Date(doc.modifiedDateTime);
          return {
            visibilityId: doc.documentVisibility,
            possibleVisibility: UserCore.userIsAssessorOrHigher(
              userClaims!.user!
            )
              ? vis
              : [],
            documentId: doc.id,
            isExpanded: false,
            visibilityLabel:
              vis.find((v) => v.key === doc.documentVisibility)?.value || '',
            caseId: doc.caseId,
            userId: doc.relatedExternalUserId,
            documentName: doc.userSpecifiedFileName,
            uploadedBy: `${doc.uploadedByUser.name} ${doc.uploadedByUser.surname}`,
            uploadedByInt: UserCore.getUserInitials(doc.uploadedByUser),
            uploadedOnTime: DateHelpers.getLocalTimeString(uploadedDate, intl),
            uploadedOnDate: DateHelpers.getLocalDateString(
              uploadedDate,
              intl,
              'MMMM'
            ),
            editedOnDate: DateHelpers.getLocalDateString(
              editedDate,
              intl,
              'MMMM'
            ),
            editedOnTime: DateHelpers.getLocalTimeString(editedDate, intl),
            timeSinceUpload: getTimeSince(new Date(doc.createdDateTime)),
            timeSinceEdit:
              doc.modifiedDateTime == null
                ? getTimeSince(new Date(doc.createdDateTime))
                : getTimeSince(new Date(doc.modifiedDateTime)),
            note: doc.accompanyingNotes,
          };
        })
      );
    });
  };

  useEffect(() => {
    if (!caseDetails) {
      return;
    }
    if (!documentRefreshRequired) {
      return;
    }
    setDocumentRefreshRequired(false);
    setDocumentsLoading(true);
    loadCaseDocumentsAsync().then(() => {
      setDocumentsLoading(false);
    });
  }, [caseDetails, documentRefreshRequired]);

  const loadCaseNotesAsync = async () => {
    const notes = await CaseUtils.findCaseNotesForCaseAsync(
      caseDetails!.id!,
      await getHeadersAsync(userClaims, instance)
    ).then((notes) => {
      if (notes.isFailure) {
        showMessage(locales.ApiResponses.errors.noteLoadError, 'negative');
        return;
      }

      setCaseNotes(notes.result!);
    });
  };

  useEffect(() => {
    if (!id) {
      showMessage(
        locales.ApiResponses.errors.caseNotFound,
        'negative',
        '/cases'
      );
    }
    if (!caseDetails) {
      return;
    }
    if (!noteRefreshRequired) {
      return;
    }
    setNoteRefreshRequired(false);
    setNotesLoading(true);
    loadCaseNotesAsync().then(() => {
      setNotesLoading(false);
    });
  }, [caseDetails, noteRefreshRequired]);

  const loadCaseDataAsync = async () => {
    const result = await CaseUtils.getCaseAsync(
      await getHeadersAsync(userClaims, instance),
      id!
    );

    setInitialisingCase(false);

    if (result.isFailure || !result.result) {
      showMessage(
        locales.ApiResponses.errors.caseNotFound,
        'negative',
        '/cases'
      );
      return;
    }
    try {
      context?.handleCrumbUpdate(
        [...locales.breadcrumbs]
          .slice(0, locales.breadcrumbs.length - 1)
          .concat({
            label: `${result.result?.caseReference || 'Case'}/${
              result.result?.customer.name
            } ${result.result?.customer.surname}/ ${
              result.result?.referrerOrganisation.name
            }`,
            key: result.result?.id,
            link: ``,
          })
      );
    } catch (error) {}
    console.log(result.result);
    setCaseDetails(result.result!);
  };

  useEffect(() => {
    context?.handleCrumbUpdate(locales.breadcrumbs);
  }, []);

  useEffect(() => {
    if (!id) {
      showMessage(
        locales.ApiResponses.errors.caseNotFound,
        'negative',
        '/cases'
      );
      return;
    }
    if (!refreshRequired) {
      return;
    }
    setRefreshRequired(false);
    setCaseReloading(true);
    loadCaseDataAsync().then(() => {
      setCaseReloading(false);
    });
  }, [refreshRequired]);

  const isCaseInActiveServiceDelivery = (): boolean =>
    isCasePastStatus(LocalEnumerations.CaseStatuses.ActiveServiceDeliveries);

  const isCaseInAwaitingPrerequisites = (): boolean =>
    isCasePastStatus(LocalEnumerations.CaseStatuses.AwaitingPrerequisites);

  const isCaseStillInProgress = (): boolean =>
    isCaseBeforeStatus(LocalEnumerations.CaseStatuses.CaseComplete);

  const isCasePastStatus = (minimumStatus: number): boolean => {
    if (!caseDetails) {
      return false;
    }

    const status = getCurrentStatus(caseDetails.statusHistory);
    const caseStatus = caseStatuses.find((x) => x.key === status.value);
    const minimumCaseStatus = caseStatuses.find((x) => x.key === minimumStatus);

    if (!caseStatus || !minimumCaseStatus) {
      return false;
    }

    return caseStatus.order >= minimumCaseStatus.order;
  };

  const isCaseBeforeStatus = (maximumStatus: number): boolean => {
    if (!caseDetails) {
      return false;
    }

    const status = getCurrentStatus(caseDetails.statusHistory);
    const caseStatus = caseStatuses.find((x) => x.key === status.value);
    const maximumCaseStatus = caseStatuses.find((x) => x.key === maximumStatus);

    if (!caseStatus || !maximumCaseStatus) {
      return false;
    }

    return caseStatus.order < maximumCaseStatus.order;
  };

  const handlePreRequisitesTabSave = async (
    formData: CaseDetail_Types.TriageDetail
  ): Promise<void> => {
    try {
      const headers = await getHeadersAsync(userClaims, instance);

      const result = await CaseUtils.saveTriageAsync(formData, headers);

      if (result.isFailure) {
        showMessage(
          getServerErrors(locales.labels.preRequisitesTab, result.errorCode),
          'negative'
        );
      } else {
        showMessage(
          locales.labels.preRequisitesTab.ApiResponses.saveSuccess,
          'positive'
        );
        setRefreshRequired(true);
      }
    } catch (error) {
      showMessage(
        locales.labels.preRequisitesTab.ApiResponses.saveFailed,
        'negative'
      );
    }
  };

  const allServicesAreComplete = () => {
    if (!caseDetails) {
      return false;
    }

    if (
      caseDetails.statusHistory.some(
        (x) =>
          x.value === LocalEnumerations.CaseStatuses.CaseComplete ||
          x.value === LocalEnumerations.CaseStatuses.CaseCancelled
      )
    ) {
      return false;
    }
    if (
      caseDetails.servicesToFulfill !== undefined &&
      caseDetails.servicesToFulfill.length! > 0
    ) {
      let closedServiceCounter = 0;
      for (const service of caseDetails?.servicesToFulfill!) {
        const currentService = getCurrentStatus(service.statusHistory);
        if (
          currentService.value ===
            LocalEnumerations.ServiceStatuses.ServiceCancelled ||
          currentService.value ===
            LocalEnumerations.ServiceStatuses.ServiceComplete
        ) {
          closedServiceCounter++;
        }
      }
      return caseDetails.servicesToFulfill.length === closedServiceCounter;
    }
    return true;
  };

  return isInitialisingCase ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <>
      <div className="Main-Form-Layout">
        <PillControl
          pageChangeAction={(_, newPage, newCaseReference) => {
            setPageNumber(newPage);
            setCurrentPageReference(newCaseReference!);
          }}
          pages={[
            {
              name: pageStates[0].key,
              mode: pageStates[0].value as ControlState,
              enabled: true,
              orderNo: 1,
              showAsOrdered: true,
              content: <div>Summary</div>,
              pageReference: CaseContainerPageTypes.Tabs.Summary,
            },
            {
              name: pageStates[1].key,
              mode: pageStates[1].value as ControlState,
              enabled: true,
              orderNo: 2,
              showAsOrdered: true,
              pageReference: CaseContainerPageTypes.Tabs.Referral,
              content: (
                <ReferralTab
                  labels={locales.labels.referralTab}
                  refId={caseDetails?.caseReference || ''}
                  firstName={caseDetails?.customer.name || ''}
                  lastName={caseDetails?.customer.surname || ''}
                  dob={
                    caseDetails !== null
                      ? getLocalDateString(
                          new Date(caseDetails.customer.birthDate),
                          intl,
                          'MMMM'
                        )
                      : ''
                  }
                  email={caseDetails?.customer.primaryEmail || ''}
                  tel={caseDetails?.customer.primaryTelephoneNumber || ''}
                  refOrg={caseDetails?.referrerOrganisation.name || ''}
                  suspectedConditions={conditions.filter((x) =>
                    caseDetails?.suspectedConditions?.includes(x.key)
                  )}
                  servicesRequired={servicesRequired.filter((x) =>
                    caseDetails?.servicesRequired?.includes(x.key)
                  )}
                  referralReason={
                    referralReasons.find(
                      (x) => x.key === caseDetails?.referralReason
                    )!
                  }
                  referralReasonOther={caseDetails?.referralReasonOther}
                  isCaseLoading={isInitialisingCase}
                />
              ),
            },
            {
              name: pageStates[2].key,
              mode: pageStates[2].value as ControlState,
              enabled: true,
              orderNo: 3,
              showAsOrdered: true,
              pageReference: CaseContainerPageTypes.Tabs.Services,
              content: (
                <>
                  <CaseServiceTab
                    intl={intl}
                    caseDetails={caseDetails}
                    labels={locales.labels.caseServicesTab}
                    instance={instance}
                    userClaims={userClaims}
                    pagingDetails={{
                      currentPageSize: userSettings.startingPageSize,
                      pageSizes: userSettings.pageSizes,
                    }}
                    events={{
                      requiresProofing: async (id, assessorId) => {
                        const rst =
                          await ServicesToFulfillActions.requireProofingAsync(
                            await getHeadersAsync(userClaims, instance),
                            id,
                            assessorId,
                            caseDetails!.id
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      reportReviewComplete: async (id) => {
                        const rst =
                          await ServicesToFulfillActions.reportReviewCompleteAsync(
                            await getHeadersAsync(userClaims, instance),
                            id,
                            caseDetails!.id
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      finalReportComplete: async (id) => {
                        const rst =
                          await ServicesToFulfillActions.finalReportCompleteAsync(
                            await getHeadersAsync(userClaims, instance),
                            id,
                            caseDetails!.id
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      reportSharedWithCustomer: async (id) => {
                        const rst =
                          await ServicesToFulfillActions.reportSharedWithCustomerAsync(
                            await getHeadersAsync(userClaims, instance),
                            id,
                            caseDetails!.id
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      approveServiceCost: async (id) => {
                        const rst =
                          await ServicesToFulfillActions.approveCostAsync(
                            await getHeadersAsync(userClaims, instance),
                            id,
                            caseDetails!.id
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      cancelService: async (id) => {
                        const rst =
                          await ServicesToFulfillActions.cancelServiceAsync(
                            await getHeadersAsync(userClaims, instance),
                            id,
                            caseDetails!.id
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      completeService: async (id) => {
                        const rst =
                          await ServicesToFulfillActions.completeServiceAsync(
                            await getHeadersAsync(userClaims, instance),
                            id,
                            caseDetails!.id
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      onError: (message) => showMessage(message, 'negative'),

                      setRefreshRequired: () => {
                        setRefreshRequired(true);
                      },
                    }}
                    isCaseLoading={isCaseLoading}
                  />
                </>
              ),
            },
            {
              name: pageStates[3].key,
              mode: pageStates[3].value as ControlState,
              enabled: isCaseInAwaitingPrerequisites(),
              orderNo: 4,
              showAsOrdered: true,
              pageReference: CaseContainerPageTypes.Tabs.PreRequisites,
              content: (
                <PreRequisitesTab
                  caseDetails={caseDetails!}
                  onSaveSuccess={() => setRefreshRequired(true)}
                  onSave={handlePreRequisitesTabSave}
                  refreshRequired={true}
                  locales={locales.labels.preRequisitesTab}
                  isAwaitingPrerequisites={isCaseInAwaitingPrerequisites()}
                  isCaseLoading={isCaseLoading}
                />
              ),
            },
            {
              name: pageStates[4].key,
              mode: pageStates[4].value as ControlState,
              enabled: isCaseInActiveServiceDelivery(),
              orderNo: 5,
              showAsOrdered: true,
              pageReference: CaseContainerPageTypes.Tabs.Appointments,
              content: (
                <>
                  <AppointmentsTab
                    caseId={id!}
                    isCaseMangerOrHigher={UserCore.userIsCaseManagerOrHigher(
                      userClaims?.user!
                    )}
                    labels={locales.labels.appointmentTab}
                    instance={instance}
                    user={userClaims}
                    key={id!}
                    reload={false}
                    pagingDetails={{
                      currentPageSize: userSettings.startingPageSize,
                      pageSizes: userSettings.pageSizes,
                    }}
                    maxAdvancedMonths={userSettings.monthsInAdvance}
                    services={
                      caseDetails?.servicesToFulfill
                        .filter((s) => isAppointmentService(s.statusHistory))
                        .flatMap((serviceToFulFill) => {
                          return serviceToFulFill.appointments === undefined ||
                            serviceToFulFill.appointments === null ||
                            serviceToFulFill.appointments.length === 0
                            ? [
                                {
                                  isExpanded: false,
                                  rowId: serviceToFulFill.id,
                                  serviceToFulfillId: serviceToFulFill.id,
                                  serviceReference:
                                    serviceToFulFill.serviceReference,
                                  serviceDefinitionId:
                                    serviceToFulFill.serviceDefinition!.id,
                                  serviceName:
                                    ServiceDefinitionActions.retrieveServiceDefinitionDescriptorAsKeyValuePair(
                                      serviceToFulFill.serviceDefinition!,
                                      formats,
                                      conditions
                                    ).value,
                                  duration:
                                    serviceToFulFill.serviceDefinition
                                      ?.assessmentAppointmentDefinition
                                      ?.duration,
                                },
                              ]
                            : getAppointments(serviceToFulFill);
                        }) || []
                    }
                    events={{
                      onBookingComplete: () => setRefreshRequired(true),
                      onError: (message) => showMessage(message, 'negative'),
                      setRefreshRequired: () => {
                        setRefreshRequired(true);
                      },
                    }}
                    isCaseLoading={isCaseLoading}
                  />
                </>
              ),
            },
            {
              name: pageStates[5].key,
              mode: pageStates[5].value as ControlState,
              enabled: isCaseInActiveServiceDelivery(),
              orderNo: 6,
              showAsOrdered: true,
              pageReference: CaseContainerPageTypes.Tabs.BillableItems,
              content: (
                <BillableItemsTab
                  intl={intl}
                  setRefreshRequired={() => setRefreshRequired(true)}
                  canAddBillableItems={canAddBillableItems()}
                  servicesToFulfill={caseDetails!.servicesToFulfill
                    .filter(
                      (x) =>
                        x.serviceDefinition?.assessmentAppointmentDefinition ===
                          null &&
                        getCurrentStatus(x.statusHistory).value ===
                          LocalEnumerations.ServiceStatuses.RequiresFulfillment
                    )
                    .map((service) => {
                      return {
                        ...service,
                        serviceName:
                          ServiceDefinitionActions.retrieveServiceDefinitionDescriptorAsKeyValuePair(
                            service.serviceDefinition!,
                            formats,
                            conditions
                          ).value,
                        billableOrganisationName:
                          caseDetails!.defaultBillingOrganisation.name,
                      };
                    })}
                  labels={locales.labels.billableTab}
                  pagingDetails={{
                    currentPageSize: userSettings.startingPageSize,
                    pageSizes: userSettings.pageSizes,
                  }}
                  eventHandlers={{
                    showMessage: (message) =>
                      showMessage(message.message, message.alertType),
                    onError: (message) => showMessage(message, 'negative'),
                  }}
                  data={
                    caseDetails?.servicesToFulfill.flatMap((service) => {
                      return (
                        service.billableItems?.map((item) => {
                          return {
                            name: ServiceDefinitionActions.retrieveServiceDefinitionDescriptorAsKeyValuePair(
                              service.serviceDefinition!,
                              formats,
                              conditions
                            ).value,
                            serviceReference: service.serviceReference,
                            itemID: item.id,
                            serviceCode: item.serviceCode,
                            price: item.unitPrice,
                            originalPrice: item.originalUnitPrice,
                            taxAmount: item.taxAmount,
                            originalTaxAmount: item.originalTaxAmount,
                            invoiceDate: item.invoiceDate
                              ? new Date(item.invoiceDate)
                              : null,
                            invoiceDueDate: item.invoiceDueDate
                              ? new Date(item.invoiceDueDate)
                              : null,
                            invoiceRaised: item.isInvoiced,
                            currency: item.currency,
                            isExpanded: false,
                            nominalCode: item.nominalCode,
                            dateCreated: new Date(item.createdDateTime),
                            billableOrganisationName:
                              caseDetails.defaultBillingOrganisation.name,
                            note: item.note,
                            invoiceNumber: item.invoiceNumber,
                            serviceToFulfillId: item.serviceToFulfillId,
                            caseId: item.caseId,
                            isEditing: false,
                          };
                        }) || []
                      );
                    }) || []
                  }
                  isCaseLoading={isCaseLoading}
                />
              ),
            },
            {
              name: pageStates[6].key,
              mode: pageStates[6].value as ControlState,
              enabled: true,
              orderNo: 8,
              showAsOrdered: false,
              pageReference: CaseContainerPageTypes.Tabs.Documents,
              content: (
                <DocumentTab
                  labels={locales.labels.documentTab}
                  caseId={caseDetails?.id || ''}
                  locale={intl}
                  loader={documentFlicker}
                  rows={caseDocuments}
                  load={
                    currentPageReference ===
                    CaseContainerPageTypes.Tabs.Documents
                  }
                  customerId={caseDetails?.customer.id || ''}
                  pagingDetails={{
                    currentPageSize: userSettings.startingPageSize,
                    pageSizes: userSettings.pageSizes,
                  }}
                  events={{
                    changeDocumentVisibility: async (
                      documentId: string,
                      documentName: string,
                      visibility: number,
                      visibilityName: string
                    ) => {
                      await DocumentActions.SetCaseDocumentVisibilityAsync(
                        {
                          DocumentId: documentId,
                          visibilityState: visibility,
                          RelatedExternalUserId: caseDetails!.customer.id,
                          CaseId: caseDetails!.id!,
                        },
                        await getHeadersAsync(userClaims, instance)
                      ).then((result) => {
                        if (result.isFailure) {
                          showMessage(
                            `${documentName} ${locales.labels.documentTab.ApiResponses.serverErrors} ${visibilityName}`,
                            'negative'
                          );
                          return;
                        }
                        showMessage(
                          `${documentName} ${locales.labels.documentTab.ApiResponses.visibilityChangeSuccess} ${visibilityName}`,
                          'positive'
                        );
                        setDocumentRefreshRequired(true);
                      });
                    },
                    uploadDocument: async (document) => {
                      document.RelatedExternalUserId = caseDetails?.customer.id;
                      var rst = await DocumentUtils.uploadDocumentAsync(
                        document,
                        await getHeadersAsync(userClaims, instance)
                      );
                      if (rst.isFailure) {
                        showMessage(
                          getServerErrors(
                            locales.labels.documentTab,
                            rst.errorCode
                          ),
                          'negative'
                        );
                        return rst;
                      }
                      setDocumentFlicker(!documentFlicker);
                      return rst;
                    },
                    onError: (message) => showMessage(message, 'negative'),
                    refreshDocuments: () => setDocumentRefreshRequired(true),
                  }}
                  isCaseLoading={isCaseLoading || areDocumentsLoading}
                />
              ),
            },
            {
              name: pageStates[7].key,
              mode: pageStates[7].value as ControlState,
              enabled: true,
              orderNo: 9,
              showAsOrdered: false,
              pageReference: CaseContainerPageTypes.Tabs.Notes,
              content: (
                <NotesTab
                  labels={locales.labels.notesTab}
                  pagingDetails={{
                    currentPageSize: userSettings.startingPageSize,
                    pageSizes: userSettings.pageSizes,
                  }}
                  events={{
                    onError: (message) => showMessage(message, 'negative'),

                    refreshNotes: () => setNoteRefreshRequired(true),
                    onCreateNote: async (note: CaseNote_Types.Base) => {
                      const result = await CaseUtils.addCaseNoteToCaseAsync(
                        note,
                        await getHeadersAsync(userClaims, instance)
                      );
                      if (result.isFailure) {
                        showMessage(
                          locales.ApiResponses.errors.noteSaveError,
                          'negative'
                        );
                        return result;
                      }

                      setNoteRefreshRequired(true);
                      return result;
                    },
                  }}
                  associatedDocuments={caseDocuments.map((doc) => {
                    return {
                      key: doc.documentId,
                      value: doc.documentName,
                    };
                  })}
                  caseId={caseDetails!.id}
                  rows={
                    caseNotes?.map((note) => {
                      return {
                        itemID: note.id,
                        content: note.content,
                        createdDateTime: note.createdDateTime,
                        modifiedDateTime: note.modifiedDateTime,
                        createdByName: `${note.createdByUser.name} ${note.createdByUser.surname}`,
                        isExpanded: false,
                        documentName:
                          caseDocuments.find(
                            (x) => x.documentId === note.documentId
                          )?.documentName || '',
                        createdByInitials: UserCore.getUserInitials(
                          note.createdByUser
                        ),
                      };
                    }) || []
                  }
                  isCaseLoading={
                    isCaseLoading || areNotesLoading || areDocumentsLoading
                  }
                />
              ),
            },
            {
              name: pageStates[8].key,
              mode: pageStates[8].value as ControlState,
              enabled: isCaseInActiveServiceDelivery(),
              orderNo: 7,
              showAsOrdered: true,
              pageReference: CaseContainerPageTypes.Tabs.Recommendations,
              content: (
                <RecommendationsTab
                  pagingDetails={{
                    currentPageSize: userSettings.startingPageSize,
                    pageSizes: userSettings.pageSizes,
                  }}
                  caseId={caseDetails?.id || ''}
                  labels={locales.labels.recommendationsTab}
                  readOnly={!isCaseStillInProgress()}
                  rows={
                    caseDetails?.serviceRecommendations.map((rec) => {
                      return {
                        itemID: rec.id,
                        serviceDefinitionName:
                          ServiceDefinitionActions.retrieveServiceDefinitionDescriptor(
                            rec.serviceDefinition!,
                            formats!,
                            conditions!
                          ),
                        serviceDefinitionId: rec.serviceDefinitionId,
                        createdByInitials: UserCore.getUserInitials(
                          rec.createdBy
                        ),
                        createdDateTime: new Date(rec.createdDateTime),
                        isExpanded: false,
                        createdByName: `${rec.createdBy.name} ${rec.createdBy.surname}`,
                        readOnly: !isCaseStillInProgress(),
                      };
                    }) || []
                  }
                  events={{
                    onError: (message) => showMessage(message, 'negative'),
                    triggerRefresh: () => setRefreshRequired(true),
                  }}
                  isCaseLoading={isCaseLoading}
                />
              ),
            },
            {
              name: pageStates[9].key,
              mode: pageStates[9].value as ControlState,
              enabled: isCaseInActiveServiceDelivery(),
              orderNo: 10,
              showAsOrdered: true,
              content: <OutcomesTab />,
            },
          ]}
        >
          <PillControl.CenterControls>
            {pageNumber === 7 && allServicesAreComplete() && (
                <InformationButton
                  key="CloseCase"
                  buttonDetails={{
                    itemKey: 'informationSave',
                    clickEvent: () => {
                     setConfirmCancel(true);
                    },
                    label:
                      locales.labels.informationButtons.completeCases.button,
                    mode: 'positive',
                  }}
                >
                  <>{locales.labels.informationButtons.completeCases.summary}</>
                </InformationButton>
            )}
          </PillControl.CenterControls>
          <PillControl.ConfirmControls>
          {confirmCancel && (<ButtonBox title={locales.labels.confirm.title} buttons={[
        {
          id:"ConfirmCancel",
          controlState: 'positive',
          label: locales.labels.confirm.confirm,
          onClick: () =>{ getHeadersAsync(userClaims, instance)
            .then((r) => {
              return r;
            })
            .then((headers) => {
              CaseUtils.completeCaseAsync(
                caseDetails!?.id,
                headers
              ).then((result) => {
                const caseName = `${
                  caseDetails!.caseReference || 'Case'
                }/${caseDetails!.customer.name} ${
                  caseDetails!.customer.surname
                }/ ${caseDetails!.referrerOrganisation.name}`;

                if (result.isFailure) {
                  showMessage(
                    `${caseName} - ${locales.ApiResponses.errors.completeCaseError}`,
                    'negative'
                  );
                  return;
                }
                showMessage(
                  `${caseName} - ${locales.ApiResponses.success.completeCaseSuccess}`,
                  'positive',
                  '/cases'
                );
              });
            });}
        },
        {id:"CancelConfirm",
          controlState:'positive',
          label: locales.labels.confirm.cancel,
          onClick: () =>{ setConfirmCancel(false); }
        }
        ]} id="cancelCase" className='Cancel-Case' />)}
          </PillControl.ConfirmControls>
        </PillControl>

      </div>

    </>
  );
};

export default CaseContainerPage;
