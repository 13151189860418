import React from 'react';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import pricingTypes from '../../../sysObjects/apiModels/PricingDefinition.types';
import actions from '../../../systemUtils/pricing/PricingActions';
import Enumerations, {
  getHeadersAsync,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormTextArea from '../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import FormDropDown from '../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import { useMsal } from '@azure/msal-react';

const PricingDefinitionCreate = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();
  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];

  const [formData, setFormData] =
    React.useState<pricingTypes.pricingDefinition>(
      actions.createDefaultPricingDefinition(),
    );

  const [supportedCurrencies, setSupportedCurrencies] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [tabStatus, setTabStatus] = React.useState<ControlState>('neutral');

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const saveRecord = async () => {
    const validationResults = formRefs.map((innerRef) =>
      innerRef.current?.triggerValidation(),
    );
    const tabValid = validationResults.every((result) => result === true);

    if (!tabValid) {
      setTabStatus('negative');
      return;
    }

    actions
      .savePricingDefinitionAsync(
        formData,
        await getHeadersAsync(userClaims, instance),
      )
      .then((result) => {
        if (result.isFailure) {
          showMessage(getServerErrors(locales, result.errorCode), 'negative');
          return;
        }
        showMessage(
          `${formData.name}: - ${locales.ApiResponses.saveSuccess}`,
          'positive',
          `/pricing/edit/${result.result?.replace(/['"]/g, '')}`,
        );
      });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }

    setSupportedCurrencies(
      Enumerations.getSupportedCurrencies(intl.locale).map((currency) => {
        return { key: currency.code, value: currency.description };
      }),
    );
    context?.handleCrumbUpdate(
      [...locales.breadcrumbs]
        .slice(0, locales.breadcrumbs.length)
        .map((breadcrumb) => ({
          ...breadcrumb,
          link: breadcrumb.link ?? '',
        })),
    );
  }, []);

  return (
    <div className="Main-Form-Layout">
      <PillControl
        key={'pricingDefinition'}
        pages={[
          {
            name: locales.tabs.details,
            mode: tabStatus,
            enabled: true,
            orderNo: 1,
            showAsOrdered: false,
            content: (
              <>
                <FormTextCapture
                  id="name"
                  fieldId="name"
                  label={locales.name}
                  textInputType="text"
                  value={formData.name}
                  maxLength={250}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.name} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0]}
                  onChange={handleFormChange}
                  displayMode={'Box'}
                />
                <FormTextArea
                  fieldId="description"
                  id="description"
                  label={locales.description}
                  value={formData.description}
                  maxLength={250}
                  ref={formRefs[1]}
                  onChange={handleFormChange}
                  displayMode={'Box'}
                />
                <FormDropDown
                  fieldId="currency"
                  id="currency"
                  value={formData.currency}
                  items={supportedCurrencies}
                  label={locales.currency}
                  defaultText={locales.defaults.currency}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.currency} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[2]}
                  displayMode={'Box'}
                />
              </>
            ),
          },
        ]}
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButton',
            label: locales.infoButton,
            mode: 'positive',
            clickEvent: saveRecord,
          }}
        >
          {locales.infoSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default PricingDefinitionCreate;
