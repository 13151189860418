import React, { useState, FC } from 'react';
import BaseBlock from '../../reusableBlocks/baseBlock/BaseBlock';
import { SvgSearch } from '../../svg';

import StatusBlock from '../../reusableBlocks/statusBlock/StatusBlock';
import Button from '../../general/button/Button';
import ListTableSearchTypes from './ListTableSearch.types';
import './ListTableSearch.css';

/**
 * The common search text control
 **/
const ListTableSearch: FC<ListTableSearchTypes.Props> = (props) => {
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [textSearchIsValid, setTextSearchIsValid] = useState<boolean | null>(
    null
  );

  return (
    <div className="Common-Search-Filter">
      <BaseBlock
        boxSize="medium"
        className="Icon-Holder"
        content={<SvgSearch className="dark-icon" />}
      />
      <input
        type="text"
        className="form-textBox"
        id="searchText"
        data-testid="searchText"
        value={inputSearchValue}
        onKeyDown={(evt) => {
          if ((evt as any).key === 'Enter') {
            if (textSearchIsValid !== false) {
              props.onSearch(inputSearchValue);
            }
          }
        }}
        onChange={(evt) => {
          setInputSearchValue(evt.target.value);
          if (evt.target.value !== '') {
            setTextSearchIsValid(true);
          } else {
            setTextSearchIsValid(null);
          }
        }}
        onBlur={(evt) => {
          if (evt.target.value === '') {
            setTextSearchIsValid(null);
          }
        }}
      />

      <StatusBlock
        boxSize="medium"
        className="icon"
        showIcon={true}
        id="searchTextStatus"
        boxState={
          textSearchIsValid === null
            ? 'neutral'
            : textSearchIsValid === true
            ? 'positive'
            : 'negative'
        }
      />
      <Button
        itemKey="searchTextButton"
        children={<SvgSearch className="icon" />}
        label={props.Labels.searchButton}
        mode="positive"
        testId="searchTextButton"
        clickEvent={() => {
          if (textSearchIsValid !== false) {
            props.onSearch(inputSearchValue);
          }
        }}
      />
    </div>
  );
};

export default ListTableSearch;
