import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import pricingTypes from '../../../sysObjects/apiModels/PricingDefinition.types';
import actions from '../../../systemUtils/pricing/PricingActions';
import Enumerations, {
  getHeadersAsync,
  LocalEnumerations,
} from '../../../systemUtils/common/CommonHelpers';
import { useMsal } from '@azure/msal-react';
import ServiceDefinitionActions from '../../../systemUtils/services/ServiceDefinitionActions';
import { PricingDefinitionItemTypes } from '../../../systemComponents/targetedPageControls/pricing/pricingItemRow/PricingItemRow.types';
import PricingItemList from '../../../systemComponents/targetedPageControls/pricing/pricingItemList/PricingItemList';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import ServiceDefinition_Types from '../../../sysObjects/apiModels/ServiceDefinition.types';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import './pricingDefinitionListEdit.css';

const PricingDefinitionListEdit = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const { id } = useParams();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [refreshRequired, setRefreshRequired] = React.useState<boolean>(true);
  const [rows, setRows] = React.useState<PricingDefinitionItemTypes.Item[]>();
  const [deliveryFormat, setDeliveryFormats] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [relatedConditions, setRelatedConditions] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [serviceDefinitions, setServiceDefinitions] = React.useState<
    ServiceDefinition_Types.RetrievedServiceDefinition[]
  >([]);
  const [formData, setFormData] =
    React.useState<pricingTypes.pricingDefinition>(
      actions.createDefaultPricingDefinition(),
    );

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }

    context?.handleCrumbUpdate(
      [...locales.breadcrumbs].slice(0, locales.breadcrumbs.length - 1),
    );

      loadDataAsync();
  }, []);

  React.useEffect(() => {
    if (!id) {
      return;
    }
    if (!refreshRequired) {
      return;
    }

    setRefreshRequired(false);
    context?.handleCrumbUpdate(locales.breadcrumbs);

    preparePriceListTab(id);
  }, [refreshRequired]);

  const loadDataAsync = async () => {
    actions
      .getPricingDefinitionAsync(
        id!,
        await getHeadersAsync(userClaims, instance),
      )
      .then((result) => {
        if (result.isFailure) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
          return;
        }
        setFormData({
          currency: result.result!.currency,
          description: result.result!.description,
          name: result.result!.name,
          status: result.result!.status,
        });
        context?.handleCrumbUpdate(
          [...locales.breadcrumbs]
            .slice(0, locales.breadcrumbs.length - 1)
            .concat([
              {
                label: result.result!.name || '',
                key: id || '',
                link: '',
              },
            ]),
        );
      })
      .catch(() => {
        showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
      });
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const determineRelatedCondition = (
    retrievedRelatedConditions: KeyValuePair<number>[],
    item: ServiceDefinition_Types.RetrievedServiceDefinition,
  ) => {
    const relatedText = retrievedRelatedConditions.find(
      (x) => x.key === item.relatedCondition!,
    )!.value;

    return item.relatedCondition !== 99
      ? relatedText
      : `${retrievedRelatedConditions.find((x) => x.key === item.relatedCondition!)!.value} - ${item.otherRelatedCondition}`;
  };

  const preparePriceListTab = async (id: string) => {
    const retrievedDeliveryFormats = Enumerations.getDeliveryFormats(
      intl.locale,
    );
    const retrievedRelatedConditions = Enumerations.getRelatedConditions(
      intl.locale,
    );
    setDeliveryFormats(retrievedDeliveryFormats);
    setRelatedConditions(retrievedRelatedConditions);

    if (!id || !retrievedRelatedConditions || !retrievedDeliveryFormats) {
      showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
      return;
    }

    const pricingDefinitionsResult =
      await actions.getPricingDefinitionAsync(
        id,
        await getHeadersAsync(userClaims, instance),
      );

    if (pricingDefinitionsResult.isFailure) {
      showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
      return;
    }

    const serviceDefinitionResult = await ServiceDefinitionActions.findAsync(
      {
        active: LocalEnumerations.ServiceDefinitionStatuses.Active,
      },
      await getHeadersAsync(userClaims, instance),
    );

    if (serviceDefinitionResult.isFailure) {
      showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
      return;
    }

    setServiceDefinitions(serviceDefinitionResult.result!);

    setRows(
      pricingDefinitionsResult!
        .result!.pricingDefinitionItems!.filter(
          (x) => x.validTo === undefined || x.validTo === null,
        )
        .sort(
          (a, b) =>
            new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime(),
        )
        .map((item) => {
          return {
            id: item.id,
            currency: pricingDefinitionsResult.result!.currency,
            name: item.serviceDefinition.serviceType,
            price: item.unitPrice,
            code: item.serviceDefinition.serviceCode,
            relatedCondition: determineRelatedCondition(
              retrievedRelatedConditions,
              item.serviceDefinition,
            ),
            deliveryFormat: retrievedDeliveryFormats.find(
              (x) => x.key === item.serviceDefinition.deliveryFormat!,
            )!.value,
            validFrom: item.validFrom,
            selectedServiceId: item.serviceDefinitionId,
            onSave: async (price, serviceId) => {
              actions
                .savePricingDefinitionItemAsync(
                  {
                    pricingDefinitionId: id,
                    serviceDefinitionId: serviceId,
                    unitPrice: price,
                  },
                  await getHeadersAsync(userClaims, instance),
                )
                .then((result) => {
                  if (result.isFailure) {
                    showMessage(locales.ApiResponses.saveFailed, 'negative');
                    return;
                  }
                  showMessage(
                    `${formData.name}: - ${locales.ApiResponses.saveSuccess}`,
                    'positive',
                  );
                  setRefreshRequired(true);
                });
            },
          };
        }),
    );
    setIsLoading(false);
  };

  const appendRemainingServices = (
    presentItems: PricingDefinitionItemTypes.Item[],
  ) => {
    const serviceIds = presentItems.map((item) => item.selectedServiceId);
    const remainingServices = serviceDefinitions.filter(
      (service) => !serviceIds.includes(service.id),
    );

    let remainingItems: PricingDefinitionItemTypes.Item[] =
      remainingServices.map((service) => {
        return {
          currency: formData.currency,
          name: service.serviceType,
          price: 0,
          code: service.serviceCode,
          relatedCondition: determineRelatedCondition(
            relatedConditions,
            service,
          ),
          deliveryFormat: deliveryFormat.find(
            (x) => x.key === service.deliveryFormat!,
          )!.value,
          selectedServiceId: service.id,
          onSave: async (price, serviceId) => {
            actions
              .savePricingDefinitionItemAsync(
                {
                  pricingDefinitionId: id!,
                  serviceDefinitionId: serviceId,
                  unitPrice: price,
                },
                await getHeadersAsync(userClaims, instance),
              )
              .then((result) => {
                if (result.isFailure) {
                  showMessage(locales.ApiResponses.saveFailed, 'negative');
                  return;
                }

                showMessage(
                  `${formData.name}: - ${locales.ApiResponses.saveSuccess}`,
                  'positive',
                );
                setRefreshRequired(true);
              });
          },
        };
      });

    return [...presentItems, ...remainingItems];
  };

  const renderPriceItems = () => {
    let rowsToDisplay = rows || [];
    if (!id) {
      return null;
    }

    rowsToDisplay = appendRemainingServices(rowsToDisplay);
    
    return (
      <PricingItemList
        rows={rowsToDisplay}
        cols={locales.pricingList.cols}
        labels={{
          ...locales.pricingList,
          table: {
            ...locales.table,
            tableName: `${locales.table.tableName} - ${formData.name}`,
          },
        }}
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        triggerRefresh={() => {
          setRefreshRequired(true);
        }}
      />
    );
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        key={'pricingDefinition'}
        pages={[
          {
            name: locales.tabs.pricing,
            mode: 'neutral',
            content: renderPriceItems(),
            enabled: true,
            orderNo: 1,
            showAsOrdered: false,
          },
        ]}
      />
    </div>
  );
};

export default PricingDefinitionListEdit;
