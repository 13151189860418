import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import OrgActions from '../../../systemUtils/organisation/OrganisationActions';
import { useNavigate } from 'react-router-dom';

import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import Organisation_Types from '../../../sysObjects/apiModels/Organisation.types';
import { useMsal } from '@azure/msal-react';

import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import Enumerations, {
  getHeadersAsync,
} from '../../../systemUtils/common/CommonHelpers';
import {
  ColDetails,
  colSearchDetails,
  ControlState,
  KeyValuePair,
} from '../../../sysObjects/common.types';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ListTableRow from '../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';

import './OrganisationsIndex.css';
import ListTableSearch from '../../../systemComponents/sharedControls/tables/listTableSearch/ListTableSearch';
import {
  filterTable,
  sortTable,
} from '../../../systemUtils/common/SortSearchHelpers';
import StandardHeader from '../../../systemComponents/sharedControls/tables/listTable/headers/standard/StandardHeader';
import SortableHeader from '../../../systemComponents/sharedControls/tables/listTable/headers/sortable/SortableHeader';

const OrganisationIndex: FC = () => {
  const [sortColumnDetails, setSortColumnDetails] = useState<colSearchDetails>({
    field: 'name',
    searchDirection: 'asc',
    searchType: 'string',
  });
  const fieldsToSearch: (keyof Organisation_Types.organisationTableRow)[] = [
    'name',
  ];

  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = useContext(CommonPageContext);
  const { userClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userSettings } = useUserSettingsContext();
  const [orgs, setOrgs] = useState<Organisation_Types.organisationTableRow[]>(
    []
  );

  const [showTextSearch, setShowTextSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const showMessages = (
    message: string,
    state: ControlState,
    path: string | null = null
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };
  const loadDataAsync = async () => {
    try {
      const headers = await getHeadersAsync(userClaims, instance);
      const result = await OrgActions.lookUpAsync({}, headers);
      setIsLoading(false);
      if (result.isFailure || !result.result) {
        showMessages(locales.ApiResponses.loadingFailed, 'negative', '/');
        return;
      }
      const industrySectors = Enumerations.getIndustrySectors(intl.locale);
      setOrgs(
        result.result?.map((org) => {
          return {
            ...org,
            isExpanded: false,
            buttonRowExpanded: false,
            sectorLabel: industrySectors.find(
              (s) => s.key.toString() === org.industrySectorCode
            )?.value || '',
          };
        }) || []
      );
    } catch (e) {
      showMessages(locales.ApiResponses.loadingFailed, 'negative', '/');
    }
  };

  useEffect(() => {
    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      showMessages(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }
    loadDataAsync();
    context?.handleCrumbUpdate(locales.breadcrumbs);
  }, []);

  useEffect(() => {
    setOrgs((prevOrgs) => {
      return prevOrgs.map((prevOrg) => {
        return {
          ...prevOrg,
          isExpanded: false,
          buttonsExpanded: false,
        };
      });
    });
  }, [searchString]);

  const DetermineUserButtons = (
    org: Organisation_Types.organisationTableRow
  ) => {
    let buttons: ButtonBoxTypes.Button[] = [];
    if (UserCore.userIsSuperUser(userClaims!.user!)) {
      buttons.push({
        controlState: 'positive',
        id: `nav-edit-${org.id}`,
        label: locales.editButton,
        onClick: () => navigate(`/organisations/${org.id}/edit`),
      });

      if (!org.billingDetailsId) {
        buttons.push({
          id: `nav-create-billing-${org.id}`,
          controlState: 'positive',
          label: locales.createBillingDetails,
          onClick: () => navigate(`/organisations/${org.id}/billing/create`),
        });
      } else {
        buttons.push({
          id: `nav-edit-billing-${org.id}`,
          controlState: 'positive',
          label: locales.editBillingDetails,
          onClick: () =>
            navigate(
              `/organisations/${org.id}/billing/edit/${org.billingDetailsId}`
            ),
        });
        buttons.push({
          id: `nav-sla-${org.id}`,
          controlState: 'positive',
          label: locales.slaButton,
          onClick: () =>
            navigate(
              `/organisations/${org.id}/billing/${org.billingDetailsId}/sla`
            ),
        });
      }
    }
    return buttons;
  };

  return isLoading ? (
    <PageLoader alt={locales.common.Load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={false}
        search={{
          isVisible: showTextSearch,
          onStateChange: (isExpanded: boolean) => setShowTextSearch(isExpanded),
        }}
        labels={{
          tableName: locales.tableName,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        rows={sortTable(
          filterTable(orgs, searchString, fieldsToSearch),
          sortColumnDetails.field as keyof Organisation_Types.organisationTableRow,
          sortColumnDetails.searchDirection === 'none'
            ? 'asc'
            : sortColumnDetails.searchDirection,
          sortColumnDetails.searchType
        ).map((org) => {
          return (
            <ListTableRow
              isButtonRowExpanded={org.buttonRowExpanded}
              isExpanded={org.isExpanded}
              events={{
                OnExpandChange: (isExpanded: boolean) => {
                  setOrgs((prevOrgs) =>
                    prevOrgs.map((prevOrg) =>
                      prevOrg.id === org.id
                        ? {
                            ...prevOrg,
                            isExpanded,
                            buttonRowExpanded:
                              isExpanded === true
                                ? false
                                : prevOrg.buttonRowExpanded,
                          }
                        : {
                            ...prevOrg,
                            isExpanded: false,
                            buttonRowExpanded: false,
                          }
                    )
                  );
                },
                OnButtonVisibilityChange: (buttonRowExpanded: boolean) => {
                  setOrgs((prevOrgs) =>
                    prevOrgs.map((prevOrg) =>
                      prevOrg.id === org.id
                        ? { ...prevOrg, buttonRowExpanded }
                        : {
                            ...prevOrg,
                            isExpanded: false,
                            buttonRowExpanded: false,
                          }
                    )
                  );
                },
              }}
              id={`list-table-row-${org.id}`}
              key={`list-table-row-${org.id}`}
            >
              <ListTableRow.ButtonRow
                actionLabel={locales.actions}
                isExpanded={org.buttonRowExpanded}
                id={org.id}
              >
                <ButtonBox
                  buttons={DetermineUserButtons(org)}
                  id={`Button-Box-${org.id}`}
                  displayBorder={false}
                />
              </ListTableRow.ButtonRow>
              <ListTableRow.Expanded>
                <>
                  <div>{locales.colNames.OrgName}</div>
                  <div>{org.name}</div>
                  <div>{locales.colNames.sectorCode}</div>
                  <div>{org.sectorLabel}</div>
                  <div>Actions</div>
                  <ButtonBox
                    buttons={DetermineUserButtons(org)}
                    id={`Button-Box-${org.id}-expanded`}
                    displayBorder={true}
                    className="Button-Box-expanded"
                  />
                </>
              </ListTableRow.Expanded>
              <ListTableRow.Collapsed>
                <div>{org.name}</div>
                <div>{org.sectorLabel}</div>
              </ListTableRow.Collapsed>
            </ListTableRow>
          );
        })}
        className="Organisational-List-Table"
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular" />
          {locales.cols.map((col: ColDetails, index: number) => {
            return col.style === 'sortable' ? (
              <SortableHeader
                key={`${col.id}-${index}`}
                id={col.id}
                name={col.name}
                fieldName={col.field}
                searchType={col.searchType}
                sortDirection={
                  col.field === sortColumnDetails.field
                    ? sortColumnDetails.searchDirection
                    : 'none'
                }
                onSortSelected={(name, searchType, sortDirection) => {
                  setSortColumnDetails({
                    field: name,
                    searchDirection: sortDirection,
                    searchType: searchType as
                      | 'string'
                      | 'number'
                      | 'boolean'
                      | 'date',
                  });
                }}
              />
            ) : (
              <StandardHeader
                id={col.id}
                name={col.name}
                key={`${col.id}-${index}`}
              />
            );
          })}
          <div className="Heading Text-Regular" />
        </ListTable.Headers>
        <ListTable.SearchOptions>
          {showTextSearch && (
            <ListTableSearch
              Labels={{ searchButton: locales.search }}
              onSearch={(message: string) => setSearchString(message)}
            />
          )}
        </ListTable.SearchOptions>
      </ListTable>

      {UserCore.userIsSuperUser(userClaims!.user!) ? (
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButton',
            label: locales.infoButton,
            mode: 'positive',
            clickEvent: () => navigate('/organisations/create'),
          }}
        >
          {locales.infoSummary}
        </InformationButton>
      ) : null}
    </div>
  );
};

export default OrganisationIndex;
