import { FC } from 'react';
import SideBar from '../../reusableBlocks/sideBar/SideBar';
import KeyValueTableTypes from './KeyValueTable.types';
import KeyValueLabel from '../../general/keyValueLabel/KeyValueLabel';

import './KeyValueTable.css';
import React from 'react';

const KeyValueTable: FC<KeyValueTableTypes.Props> = (props) => {
  return (
    <div className="KeyValueTable">
      <div className="KeyValueTable-Header">
        <SideBar
          FieldKey="KeyValueLabel"
          boxState="locked"
          className="KeyValueTable-Bar"
          includeInnerBar={false}
          key={`${props.id}-Label-Bar`}
        />
        <h3 className="Text-Regular" key={`${props.id}-Label`}>
          {props.tableName}
        </h3>
      </div>
      {props.groups.map((group, groupIndex) => {
        return (
          <React.Fragment key={`${group.groupName}-Line`}>
            {groupIndex !== 0 && <div className="KeyValueLabel-Line" />}
            {group.rows.map((row, rowIndex) => {
              return (
                <React.Fragment key={`${group.groupName}-${rowIndex}`}>
                  {(row.showEmpty || row.value !== "") && (
                    <KeyValueLabel key={`${group.groupName}-${rowIndex}`} {...row} isTop={rowIndex === 0 && groupIndex !== 0} />
                  )}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default KeyValueTable;
