import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import Enumerations, {
  getHeadersAsync,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import extUserActions from '../../../systemUtils/userUtils/ExternalUserActions';
import { useNavigate, useParams } from 'react-router-dom';
import OrgActions from '../../../systemUtils/organisation/OrganisationActions';
import ExternalUser_Types from '../../../sysObjects/apiModels/ExternalUser.types';
import { useMsal } from '@azure/msal-react';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import FormDropDown from '../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import FormDate from '../../../systemComponents/sharedControls/formControls/formDate/FormDate';
import FormCheckboxList from '../../../systemComponents/sharedControls/formControls/formCheckboxList/FormCheckboxList';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';

const CreateCustomer: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);
  const { id } = useParams();
  const formRefs = [
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
    [useRef<FormControlRef>(null), useRef<FormControlRef>(null)],
    [useRef<FormControlRef>(null), useRef<FormControlRef>(null)],
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
  ];

  const { userClaims } = useContext(UserClaimsContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [genders, setGenders] = useState<KeyValuePair<number>[]>([]);
  const [ethnicity, setEthnicity] = useState<KeyValuePair<number>[]>([]);
  const [disabilities, setDisabilities] = useState<KeyValuePair<number>[]>([]);

  const [referralOrganisations, setReferralOrganisations] = useState<
    KeyValuePair<string>[]
  >([]);
  const [formData, setFormData] = useState<ExternalUser_Types.User>(
    extUserActions.createExternalAccount(),
  );

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleSelectChange = (
    result: FormControlOnChangeData<string | string[] | number | null>,
  ) => {
    if (result.value === null) {
      return;
    }

    if (Array.isArray(result.value)) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]:
        typeof result.value === 'number'
          ? result.value
          : parseInt(result.value! as string, 10),
    }));
  };

  const handleFormChangeAddress = (
    result: FormControlOnChangeData<string | number | null>,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      homeAddress: {
        ...prevData.homeAddress!,
        [result.fieldId]: result.value,
      },
    }));
  };

  const handleDateChange = (result: FormControlOnChangeData<Date | null>) => {
    if (!result.value) {
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: null,
      }));
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: new Date(result.value!) || null,
    }));
  };

  const loadDataAsync = async () => {
    const headers = await getHeadersAsync(userClaims!, instance);
    OrgActions.lookUpAsync(
      { hasBillingDetails: true },
      headers
    ).then((rst) => {
      if (rst.error) {
        showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
        return;
      }
      setReferralOrganisations(
        rst.result!.map((item) => {
          return { key: item.id, value: item.name };
        }),
      );

      if (id) {
        extUserActions
          .getUserAsync(headers, id)
          .then((result) => {
            if (result.isFailure) {
              showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
              return result;
            }
            setFormData(result.result!);
            setIsLoading(false);
            context?.handleCrumbUpdate(
              [...locales.breadcrumbs]
                .slice(0, locales.breadcrumbs.length - 1)
                .concat({
                  label: `${result.result!.surname}, ${result.result!.name}`,
                  key: id,
                }),
            );
          });
        return;
      }
      setIsLoading(false);
    })
  };

  useEffect(() => {
    context?.handleCrumbUpdate(
      [...locales.breadcrumbs].slice(0, locales.breadcrumbs.length - 1)
    );
    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }

    setEthnicity(Enumerations.getEthnicity(intl.locale));
    setDisabilities(Enumerations.getDisabilities(intl.locale));
    setGenders(Enumerations.getGender(intl.locale));

    loadDataAsync();
    context?.handleCrumbUpdate(locales.breadcrumbs);
}, []);

const [pageStates, setPages] = useState<KeyValuePair<string>[]>([
  {
    key: locales.tabNames.pageOne,
    value: 'neutral',
  },
  {
    key: locales.tabNames.pageTwo,
    value: 'neutral',
  },
  {
    key: locales.tabNames.pageThree,
    value: 'neutral',
  },
  {
    key: locales.tabNames.pageFour,
    value: 'neutral',
  },
  {
    key: locales.tabNames.pageFive,
    value: 'neutral',
  },
]);

const pageChange = (oldPage: number) => {
  const validationResults = formRefs[oldPage].map((ref) =>
    ref.current?.triggerValidation(),
  );
  const allValid = validationResults.every((result) => result === true);

  setPages((prevPages) => {
    const updatedPages = [...prevPages];
    updatedPages[oldPage] = {
      ...updatedPages[oldPage],
      value: allValid ? 'positive' : 'negative',
    };
    return updatedPages;
  });
};

const saveRecord = async () => {
  let allValid: boolean[] = [];

  formRefs.forEach((ref, i) => {
    const validationResults = ref.map((innerRef) =>
      innerRef.current?.triggerValidation(),
    );
    const tabValid = validationResults.every((result) => result === true);
    allValid.push(tabValid);
    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[i] = {
        ...updatedPages[i],
        value: tabValid ? 'positive' : 'negative',
      };
      return updatedPages;
    });
  });

  const pageValid = allValid.every((isValid) => isValid);
  if (pageValid) {
    extUserActions
      .saveUserAsync(await getHeadersAsync(userClaims, instance), formData, id)
      .then((result) => {
        if (result.isFailure) {
          showMessage(getServerErrors(locales, result.errorCode), 'negative');
          return;
        }
        showMessage(
          `${formData.surname}, ${formData.name}: - ${locales.ApiResponses.saveSuccess}`,
          'positive',
          '/customers/accounts',
        );
      });
    return;
  }
};

return isLoading ? (
  <PageLoader alt={locales.common.load} />
) : (
  <div className="Main-Form-Layout">
    <PillControl
      backLabel={locales.labels.back}
      nextLabel={locales.labels.forward}
      pageChangeAction={pageChange}
      pages={[
        {
          name: pageStates[0].key,
          mode: pageStates[0].value as ControlState,
          enabled: true,
          orderNo: 1,
          showAsOrdered: true,
          content: (
            <>
              <FormDropDown
                displayMode="Box"
                fieldId="organisationId"
                id="organisationId"
                label={locales.controls.organisationId.label}
                items={referralOrganisations}
                value={formData.organisationId}
                defaultText="Select"
                requiredDetails={{
                  formLabel: locales.common.required,
                  message: `${locales.controls.organisationId.label} ${locales.common.requiredMessage}`,
                }}
                ref={formRefs[0][0]}
                onChange={handleFormChange}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="name"
                ref={formRefs[0][1]}
                id="name"
                label={locales.controls.name.label}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.name.label} ${locales.common.requiredMessage}`,
                }}
                textInputType="text"
                value={formData.name}
                maxLength={250}
                onChange={handleFormChange}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="otherNames"
                id="otherNames"
                label={locales.controls.secondName.label}
                textInputType="text"
                value={formData.otherNames}
                maxLength={250}
                ref={formRefs[0][2]}
                onChange={handleFormChange}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="surname"
                id="surname"
                ref={formRefs[0][3]}
                label={locales.controls.surname.label}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.surname.label} ${locales.common.requiredMessage}`,
                }}
                textInputType="text"
                value={formData.surname}
                maxLength={250}
                onChange={handleFormChange}
              />
              <FormDate
                displayMode="Box"
                id="birthDate"
                fieldId="birthDate"
                ref={formRefs[0][4]}
                label={locales.controls.dob.label}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.dob.label} ${locales.common.requiredMessage}`,
                }}
                datePartLabels={{
                  day: locales.controls.dob.day,
                  month: locales.controls.dob.month,
                  year: locales.controls.dob.year,
                }}
                value={
                  formData.birthDate == null
                    ? null
                    : typeof formData.birthDate === 'string'
                      ? new Date(formData.birthDate)
                      : formData.birthDate
                }
                onChange={handleDateChange}
                invalidDateText={locales.controls.dob.invalidText}
              />
            </>
          ),
        },
        {
          name: pageStates[1].key,
          mode: pageStates[1].value as ControlState,
          enabled: true,
          orderNo: 2,
          showAsOrdered: true,
          content: (
            <>
              <FormDropDown
                displayMode="Box"
                id="gender"
                fieldId="gender"
                items={genders}
                ref={formRefs[1][0]}
                value={formData.gender}
                label={locales.controls.gender.label}
                defaultText={locales.controls.gender.defaultText}
                onChange={handleSelectChange}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="jobTitle"
                id="jobTitle"
                ref={formRefs[1][1]}
                helpMessage={locales.controls.jobTitle.helperText}
                label={locales.controls.jobTitle.label}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.jobTitle.label} ${locales.common.requiredMessage}`,
                }}
                textInputType="text"
                value={formData.jobTitle}
                maxLength={250}
                onChange={handleFormChange}
              />
              <FormDropDown
                displayMode="Box"
                id="ethnicity"
                fieldId="ethnicity"
                ref={formRefs[1][2]}
                items={ethnicity}
                value={formData.ethnicity}
                label={locales.controls.ethnicity.label}
                defaultText={locales.controls.ethnicity.defaultText}
                onChange={handleSelectChange}
              />
              <FormCheckboxList
                displayMode="Box"
                allowManySelect
                ref={formRefs[1][3]}
                id="disabilities"
                fieldId="disabilities"
                items={disabilities}
                value={formData.disabilities}
                label={locales.controls.disabilities.label}
                onChange={handleFormChange}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.labels.checkListRequired}`,
                }}
              />
            </>
          ),
        },
        {
          name: pageStates[2].key,
          mode: pageStates[2].value as ControlState,
          enabled: true,
          orderNo: 3,
          showAsOrdered: true,
          content: (
            <div key={pageStates[2].key}>
              <FormTextCapture
                displayMode="Box"
                fieldId="primaryEmail"
                id="primaryEmail"
                ref={formRefs[2][0]}
                label={locales.controls.primaryEmail.label}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.primaryEmail.label} ${locales.common.requiredMessage}`,
                }}
                textInputType="email"
                value={formData.primaryEmail}
                maxLength={250}
                onChange={handleFormChange}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="secondaryEmail"
                id="secondaryEmail"
                ref={formRefs[2][1]}
                helpMessage={locales.controls.secondaryEmail.helperText}
                label={locales.controls.secondaryEmail.label}
                textInputType="text"
                value={formData.secondaryEmail}
                maxLength={250}
                onChange={handleFormChange}
              />
            </div>
          ),
        },
        {
          name: pageStates[3].key,
          mode: pageStates[3].value as ControlState,
          enabled: true,
          orderNo: 4,
          showAsOrdered: true,
          content: (
            <>
              <FormTextCapture
                displayMode="Box"
                fieldId="primaryTelephoneNumber"
                id="primaryTelephoneNumber"
                ref={formRefs[3][0]}
                label={locales.controls.primaryTelephoneNumber.label}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.primaryTelephoneNumber.label} ${locales.common.requiredMessage}`,
                }}
                textInputType="tel"
                value={formData.primaryTelephoneNumber}
                maxLength={250}
                onChange={handleFormChange}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="secondaryTelephoneNumber"
                id="secondaryTelephoneNumber"
                ref={formRefs[3][1]}
                label={locales.controls.secondaryTelephoneNumber.label}
                textInputType="text"
                value={formData.secondaryTelephoneNumber}
                maxLength={250}
                onChange={handleFormChange}
              />
            </>
          ),
        },
        {
          name: pageStates[4].key,
          mode: pageStates[4].value as ControlState,
          enabled: true,
          orderNo: 5,
          showAsOrdered: true,
          content: (
            <>
              <FormTextCapture
                displayMode="Box"
                fieldId="line1"
                id="line1"
                ref={formRefs[4][0]}
                label={locales.controls.line1.label}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.line1.label} ${locales.common.requiredMessage}`,
                }}
                textInputType="text"
                value={formData.homeAddress!.line1}
                maxLength={250}
                onChange={handleFormChangeAddress}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="line2"
                id="line2"
                ref={formRefs[4][1]}
                label={locales.controls.line2.label}
                textInputType="text"
                value={formData.homeAddress!.line2}
                maxLength={250}
                onChange={handleFormChangeAddress}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="line3"
                id="line3"
                ref={formRefs[4][2]}
                label={locales.controls.line3.label}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.line3.label} ${locales.common.requiredMessage}`,
                }}
                textInputType="text"
                value={formData.homeAddress!.line3}
                maxLength={250}
                onChange={handleFormChangeAddress}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="postalCode"
                id="postalCode"
                ref={formRefs[4][3]}
                label={locales.controls.postalCode.label}
                textInputType="text"
                value={formData.homeAddress!.postalCode}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.postalCode.label} ${locales.common.requiredMessage}`,
                }}
                maxLength={250}
                onChange={handleFormChangeAddress}
              />
              <FormTextCapture
                displayMode="Box"
                fieldId="country"
                id="country"
                ref={formRefs[4][4]}
                label={locales.controls.country.label}
                textInputType="text"
                value={formData.homeAddress!.country}
                requiredDetails={{
                  formLabel: `${locales.common.required}`,
                  message: `${locales.controls.country.label} ${locales.common.requiredMessage}`,
                }}
                maxLength={250}
                onChange={handleFormChangeAddress}
              />
            </>
          ),
        },
      ]}
    />
    <InformationButton
      key="informationSAve"
      buttonDetails={{
        itemKey: 'informationSave',
        clickEvent: saveRecord,
        label: locales.labels.save,
        mode: 'positive',
      }}
    >
      <>{locales.labels.SaveSummary}</>
    </InformationButton>
  </div>
);
};

export default CreateCustomer;
