import React, {useState, useContext, useRef} from 'react';
import './AddCaseContact.css';
import CaseLayoutRowTypes  from './CaseTableRowProps.types';
import { FaPlus } from 'react-icons/fa6';
import StatusBlock from '../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import FormTextCapture from '../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import { FormControlRef } from '../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import ButtonBox from '../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import { CaseUtils } from '../../../../systemUtils/case/caseUtils';
import { getHeadersAsync } from '../../../../systemUtils/common/CommonHelpers';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../sharedControls/contexts/UserClaimsContext';

const AddCaseContact: React.FC<CaseLayoutRowTypes.AddCaseContactProps> = ({props, isLastItem}) => {

    const { instance } = useMsal();
    const { userClaims } = useContext(UserClaimsContext);

    const formRefs = [
      [
        useRef<FormControlRef>(null),
        useRef<FormControlRef>(null),
        useRef<FormControlRef>(null),
        useRef<FormControlRef>(null)
      ]];

    const [showAddContactForm, setShowAddContactForm] = useState(false);
    const [contactName, setContactName] = React.useState<string>("");
    const [contactEmail, setContactEmail] = React.useState<string>("");
    const [contactRelationship, setContactRelationship] = React.useState<string>("");
    const [contactTelephone, setContactTelephone] = React.useState<string>("");

    const handleAddContactLinkClick = () => {
        setShowAddContactForm(true);
    };
    
    const resetAddContactForm = () => {
        setContactName("");
        setContactRelationship("");
        setContactEmail("");
        setContactTelephone("");
    }
    
    const closeAddContactForm = () => {
        setShowAddContactForm(false);
        resetAddContactForm()
    };

    const handleAddContactAsync = async () => {
        CaseUtils.addCaseContactToCaseAsync(
            props.id,
            {
                name: contactName,
                relationshipToCustomer: contactRelationship,
                emailAddress: contactEmail,
                telephoneNumber: contactTelephone,
            },
        await getHeadersAsync(userClaims, instance)).then((result) => {
            if (result.isFailure) {
                props.events.onError(props.messages.addContact.errorMessage);
                return;
            }
            if (result.isSuccess){
                const newContact = {
                    email: contactEmail,
                    name: contactName,
                    relationship: contactRelationship,
                    phone: contactTelephone,
                };
                props.events.onAddContact(props.id, newContact);
                closeAddContactForm();
            } 
        })
    };

    const renderAddContactFormElements = () => {{
        return (
            <div>
                <div className='Font-Heading'> {props.labels.addContact.addContactHeading} </div>
                    <FormTextCapture
                        textInputType={'text'}
                        fieldId="contactName"
                        id="contactName"
                        label= {props.labels.addContact.contactName}
                        value={contactName}
                        maxLength={250}
                        ref={formRefs[0][0]}
                        requiredDetails=
                        {{
                          formLabel: '',
                          message:  props.messages.addContact.requiredField
                        }}
                        onChange={(e) => {
                            setContactName(e.value as string);
                        }}
                        displayMode={'Column'}
                    />

                    <FormTextCapture
                        textInputType={'text'}
                        fieldId="contactRelationship"
                        id="contactRelationship"
                        label={props.labels.addContact.contactRelationship}
                        value={contactRelationship}
                        maxLength={250}
                        ref={formRefs[0][1]}
                        requiredDetails=
                        {{
                          formLabel: '',
                          message: props.messages.addContact.requiredField
                        }}
                        onChange={(e) => {
                            setContactRelationship(e.value as string);
                        }}
                        displayMode={'Column'}
                    />

                    <FormTextCapture
                        textInputType={'email'}
                        fieldId="contactEmail"
                        id="contactEmail"
                        label={props.labels.addContact.contactEmail}
                        value={contactEmail}
                        maxLength={100}
                        ref={formRefs[0][2]}
                        onChange={(e) => {
                            setContactEmail(e.value as string);
                        }}
                        displayMode={'Column'}
                    />

                    <FormTextCapture
                        textInputType={'tel'}
                        fieldId="contactTelephone"
                        id="contactTelephone"
                        label={props.labels.addContact.contactTelephone}
                        value={contactTelephone}
                        maxLength={100}
                        ref={formRefs[0][3]}
                        onChange={(e) => {
                            setContactTelephone(e.value as string);
                        }}
                        displayMode={'Column'}
                    />
                
                <div className="col-buttons">
                    <ButtonBox
                    key={`case_contact_buttons_${props.id}`}
                    id={`case_contact_buttons_${props.id}`}
                    className="Add-Case-Contact-Buttons"
                    displayBorder={true}
                    buttons={[
                        {
                            id: `AddContact`,
                            label: props.labels.addContact.addButton,
                            controlState: 'positive',
                            onClick: () => { 
                                const validationResults = formRefs[0].map((ref) =>
                                  ref.current?.triggerValidation(),
                                );
                                const allValid = validationResults.every((result) => result === true);

                                if (!allValid) {
                                  return;  
                                }
                                handleAddContactAsync();
                            }
                        },
                        {
                            id: `Cancel`,
                            label: props.labels.addContact.cancelButton,
                            controlState: 'negative',
                            onClick: () => { 
                                closeAddContactForm();
                            }
                        }
                    ]}
                    />
                </div>
            </div>
        )
    }};
    
    const renderAddContactOptionElement = () => {{
        return (
            <div onClick={handleAddContactLinkClick}>
            <StatusBlock
            boxSize="small"
            boxState="positive"
            id="AddContact"
            content={<FaPlus />}
          /> 
           <span role="button" className="Add-Case-Contact-Link-Text">
                {props.labels.addContact.addContactLinkText}
            </span>
          </div>
        )
    }};

    return (
      <>
          {props.contacts?.length === 0 && (
              showAddContactForm ? renderAddContactFormElements() : renderAddContactOptionElement()
          )}
  
          {props.contacts && props.contacts?.length > 0 && isLastItem && (
              showAddContactForm ? renderAddContactFormElements() : renderAddContactOptionElement()
          )}
      </>
    );
};
    
export default AddCaseContact;
