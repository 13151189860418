import React, { FC } from 'react';
import SortableHeaderTypes from './SortableHeader.Types';
import './SortableHeader.css';
export const SortableHeader: FC<SortableHeaderTypes.Props> = (props) => {
  return (
    <div
      className="ListTable-Heading-Sortable"
      id={props.id}
      role="columnheader"
      aria-roledescription="columnheader"
    >
      <span className="Text-Regular">{props.name}</span>
      <div className="ButtonHolder" id="SortOptions">
        <div
          className={`SortIcon asc${
            props.sortDirection === 'asc' ? ' Selected' : ''
          }`}
          aria-hidden
        >
          <svg
            id={`${props.id}-asc`}
            data-testid={`${props.id}-asc`}
            onClick={() =>
              props.onSortSelected(props.fieldName, props.searchType, 'asc')
            }
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M-8.74228e-07 0L0 20L20 20L20 -8.74228e-07L-8.74228e-07 0Z" />
            <path
              className="Arrow"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.59601 14.8261C9.70316 14.9375 9.84848 15 10 15C10.1515 15 10.2968 14.9375 10.404 14.8261C10.5111 14.7148 10.5713 14.5638 10.5713 14.4063L10.5713 7.5279L13.0235 10.0772C13.1307 10.1887 13.2762 10.2513 13.428 10.2513C13.5797 10.2513 13.7252 10.1887 13.8325 10.0772C13.9397 9.9657 14 9.8145 14 9.65685C14 9.49919 13.9397 9.348 13.8325 9.23652L10.4045 5.67442C10.3514 5.61913 10.2884 5.57526 10.219 5.54533C10.1496 5.51541 10.0751 5.5 10 5.5C9.92485 5.5 9.85044 5.51541 9.78103 5.54533C9.71162 5.57526 9.64857 5.61913 9.5955 5.67442L6.16755 9.23652C6.06027 9.348 6 9.49919 6 9.65685C6 9.8145 6.06027 9.9657 6.16755 10.0772C6.27483 10.1887 6.42033 10.2513 6.57205 10.2513C6.72376 10.2513 6.86927 10.1887 6.97655 10.0772L9.42868 7.5279L9.42868 14.4063C9.42868 14.5638 9.48887 14.7148 9.59601 14.8261Z"
            />
          </svg>
        </div>
        <div
          className={`SortIcon desc${
            props.sortDirection === 'desc' ? ' Selected' : ''
          }`}
          aria-hidden
        >
          <svg
            id={`${props.id}-desc`}
            data-testid={`${props.id}-desc`}
            onClick={() =>
              props.onSortSelected(props.fieldName, props.searchType, 'desc')
            }
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_806_68363)">
              <path
                fill="none"
                d="M20 20L20 0L0 -8.74228e-07L-8.74228e-07 20L20 20Z"
              />

              <path
                className="Arrow"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.404 5.17389C10.2968 5.06255 10.1515 5 10 5C9.84848 5 9.70316 5.06255 9.59601 5.17389C9.48887 5.28522 9.42867 5.43623 9.42867 5.59368L9.42867 12.4721L6.97655 9.92282C6.86927 9.81135 6.72376 9.74872 6.57205 9.74872C6.42033 9.74872 6.27483 9.81135 6.16755 9.92282C6.06027 10.0343 6 10.1855 6 10.3432C6 10.5008 6.06027 10.652 6.16755 10.7635L9.5955 14.3256C9.64857 14.3809 9.71162 14.4247 9.78103 14.4547C9.85044 14.4846 9.92485 14.5 10 14.5C10.0751 14.5 10.1496 14.4846 10.219 14.4547C10.2884 14.4247 10.3514 14.3809 10.4045 14.3256L13.8325 10.7635C13.9397 10.652 14 10.5008 14 10.3432C14 10.1855 13.9397 10.0343 13.8325 9.92282C13.7252 9.81135 13.5797 9.74872 13.428 9.74872C13.2762 9.74872 13.1307 9.81135 13.0235 9.92282L10.5713 12.4721L10.5713 5.59368C10.5713 5.43623 10.5111 5.28522 10.404 5.17389Z"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SortableHeader;
