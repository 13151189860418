import {
  createAction,
  confirmAction,
  deleteAction,
} from '../common/APIHelper';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import { ServicesToFulfill_Types } from '../../sysObjects/apiModels/ServicesToFulfill.types';
import System_Types from '../../sysObjects/apiModels/System.types';

const ServicesToFulfillActions = {
/**
 * Sets service to approve costs
 * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header details for the request.
 * @param {string} servicesToFulfillId - The service to fulfill ID
 * @param {string} caseId - The case ID
 * @returns The result of the action.
 */
approveCostAsync: async (
  headerInfo: FunctionalResult<System_Types.ApiHeader>,
  servicesToFulfillId: string,
  caseId: string
): Promise<FunctionalResult<void>> => {
  if (headerInfo.isFailure) {
    return FunctionalResult.Error('Failed to load header details');
  }
  const host = await getHostConfigAsync();
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }
  let queryParams: string[] = [
    `${settings.ServicesToFulfill.approveCost.queryCaseId}=${caseId}`,
  ];

  return confirmAction({
    hostPath: host!.result!.path,
    apiPath: `${
      settings.ServicesToFulfill.approveCost.path
    }/${servicesToFulfillId}?${queryParams.join('&')}`,
    userId: headerInfo.result!.userId,
    accessToken: headerInfo.result!.token,
  });
},

/**
 * Creates a service to fulfil
 * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header details for the request.
 * @param {ServicesToFulfill_Types.CreateItem} obj The object to create.
 * @returns The result of the action.
 **/
createAsync: async (
  headerInfo: FunctionalResult<System_Types.ApiHeader>,
  obj: ServicesToFulfill_Types.CreateItem
): Promise<FunctionalResult<string>> => {
  if (headerInfo.isFailure) {
    return FunctionalResult.Error('Failed to load header details');
  }
  const host = await getHostConfigAsync();
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }

  return createAction({
    hostPath: host!.result!.path,
    apiPath: settings.ServicesToFulfill.create.path,
    userId: headerInfo.result!.userId,
    accessToken: headerInfo.result!.token,
    formData: obj,
  });
},

/**
 * Finds the object based on the lookup criteria.
 * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header details for the request.
 * @param {SlaDefinition_Types.findQuery} obj The Query object to search for.
 * @returns
 */
deleteAsync: async (
  headerInfo: FunctionalResult<System_Types.ApiHeader>,
  servicesToFulfillId: string,
  caseId: string
): Promise<FunctionalResult<void>> => {
  if (headerInfo.isFailure) {
    return FunctionalResult.Error('Failed to load header details');
  }
  const host = await getHostConfigAsync();
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }

  return deleteAction({
    hostPath: host!.result!.path,
    apiPath: `${settings.ServicesToFulfill.delete.path}/${servicesToFulfillId}?caseId=${caseId}`,
    userId: headerInfo.result!.userId,
    accessToken: headerInfo.result!.token,
  });
},

  /**
   * Sets service to require proofing
   * @param  {FunctionalResult<System_Types.ApiHeader>} headerDetails The header details for the request.
   * @param {string} servicesToFulfillId - The service to fulfill ID
   * @param {string} assessorId - The assessor to assign
   * @param {string} caseId - The case ID
   * @returns The result of the action.
   */
  requireProofingAsync: async (
    headerDetails: FunctionalResult<System_Types.ApiHeader>,
    servicesToFulfillId: string,
    assessorId: string,
    caseId: string
  ): Promise<FunctionalResult<void>> => {
    if (headerDetails.isFailure) {
      return FunctionalResult.Error('Failed to load header details');
    }
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    let queryParams: string[] = [
      `${settings.ServicesToFulfill.requireProofing.queryCaseId}=${caseId}`,
      `${settings.ServicesToFulfill.requireProofing.queryAssessorId}=${assessorId}`,
    ];
    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: `${
        settings.ServicesToFulfill.requireProofing.path
      }/${servicesToFulfillId}?${queryParams.join('&')}`,
      userId: headerDetails.result!.userId,
      accessToken: headerDetails.result!.token,
    });
  },

  /**
   * Sets service to Report Review Complete
   * @param  {FunctionalResult<System_Types.ApiHeader>} headerDetails The header details for the request.
   * @param {string} servicesToFulfillId - The service to fulfill ID
   * @param {string} caseId - The case ID
   * @returns The result of the action.
   */
  reportReviewCompleteAsync: async (
    headerDetails: FunctionalResult<System_Types.ApiHeader>,
    servicesToFulfillId: string,
    caseId: string
  ): Promise<FunctionalResult<void>> => {
    if (headerDetails.isFailure) {
      return FunctionalResult.Error('Failed to load header details');
    }
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    let queryParams: string[] = [
      `${settings.ServicesToFulfill.reportReviewComplete.queryCaseId}=${caseId}`,
    ];
    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: `${
        settings.ServicesToFulfill.reportReviewComplete.path
      }/${servicesToFulfillId}?${queryParams.join('&')}`,
      userId: headerDetails.result!.userId,
      accessToken: headerDetails.result!.token,
    });
  },

/**
 * Sets service to cancelled
 * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header details for the request.
 * @param {string} servicesToFulfillId - The service to fulfill ID
 * @param {string} caseId - The case ID
 * @returns The result of the action.
 */
cancelServiceAsync: async (
  headerInfo: FunctionalResult<System_Types.ApiHeader>,
  servicesToFulfillId: string,
  caseId: string
): Promise<FunctionalResult<void>> => {
  if (headerInfo.isFailure) {
    return FunctionalResult.Error('Failed to load header details');
  }
  const host = await getHostConfigAsync();
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }
  let queryParams: string[] = [
    `${settings.ServicesToFulfill.cancelService.queryCaseId}=${caseId}`,
  ];

  return confirmAction({
    hostPath: host!.result!.path,
    apiPath: `${
      settings.ServicesToFulfill.cancelService.path
    }/${servicesToFulfillId}?${queryParams.join('&')}`,
    userId: headerInfo.result!.userId,
    accessToken: headerInfo.result!.token,
  });
},

/**
 * Sets service to complete
 * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header details for the request.
 * @param {string} servicesToFulfillId - The service to fulfill ID
 * @param {string} caseId - The case ID
 * @returns The result of the action.
 */
completeServiceAsync: async (
  headerInfo: FunctionalResult<System_Types.ApiHeader>,
  servicesToFulfillId: string,
  caseId: string
): Promise<FunctionalResult<void>> => {
  if (headerInfo.isFailure) {
    return FunctionalResult.Error('Failed to load header details');
  }
  const host = await getHostConfigAsync();
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }
  let queryParams: string[] = [
    `${settings.ServicesToFulfill.completeService.queryCaseId}=${caseId}`,
  ];

  return confirmAction({
    hostPath: host!.result!.path,
    apiPath: `${
      settings.ServicesToFulfill.completeService.path
    }/${servicesToFulfillId}?${queryParams.join('&')}`,
    userId: headerInfo.result!.userId,
    accessToken: headerInfo.result!.token,
  });
},

/**
 * Sets service to final report complete
 * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header details for the request.
 * @param {string} servicesToFulfillId - The service to fulfill ID
 * @param {string} caseId - The case ID
 * @returns The result of the action.
 */
finalReportCompleteAsync: async (
  headerInfo: FunctionalResult<System_Types.ApiHeader>,
  servicesToFulfillId: string,
  caseId: string
): Promise<FunctionalResult<void>> => {
  if (headerInfo.isFailure) {
    return FunctionalResult.Error('Failed to load header details');
  }
  const host = await getHostConfigAsync();
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }
  let queryParams: string[] = [
    `${settings.ServicesToFulfill.finalReportComplete.queryCaseId}=${caseId}`,
  ];

  return confirmAction({
    hostPath: host!.result!.path,
    apiPath: `${
      settings.ServicesToFulfill.finalReportComplete.path
    }/${servicesToFulfillId}?${queryParams.join('&')}`,
    userId: headerInfo.result!.userId,
    accessToken: headerInfo.result!.token,
  });
},

/**
 * Sets service to customer review pending
 * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header details for the request.
 * @param {string} servicesToFulfillId - The service to fulfill ID
 * @param {string} caseId - The case ID
 * @returns The result of the action.
 */
reportSharedWithCustomerAsync: async (
  headerInfo: FunctionalResult<System_Types.ApiHeader>,
  servicesToFulfillId: string,
  caseId: string
): Promise<FunctionalResult<void>> => {
  if (headerInfo.isFailure) {
    return FunctionalResult.Error('Failed to load header details');
  }
  const host = await getHostConfigAsync();
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }
  let queryParams: string[] = [
    `${settings.ServicesToFulfill.reportSharedWithCustomer.queryCaseId}=${caseId}`,
  ];

  return confirmAction({
    hostPath: host!.result!.path,
    apiPath: `${
      settings.ServicesToFulfill.reportSharedWithCustomer.path
    }/${servicesToFulfillId}?${queryParams.join('&')}`,
    userId: headerInfo.result!.userId,
    accessToken: headerInfo.result!.token,
  });
},

};
export default ServicesToFulfillActions;
