import React, { useContext } from 'react';
import BillableItemsTabTypes from './BillableItemsTab.types';
import BillableItemRowTypes from '../../billableItems/billableItemRow/BillableItemRow.types';
import './BillableItemsTab.css';
import { useMsal } from '@azure/msal-react';
import BillableItemRow from '../../billableItems/billableItemRow/BillableItemRow';
import AddBillableItemRow from '../../billableItems/addBillableItemRow/AddBillableItemRow';
import { UserClaimsContext } from '../../../../sharedControls/contexts/UserClaimsContext';
import CaseUtils from '../../../../../systemUtils/case/caseUtils';
import {
  getServerErrors,
  getHeadersAsync,
} from '../../../../../systemUtils/common/CommonHelpers';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';
import BillableItem_Types from '../../../../../sysObjects/apiModels/BillableItem.types';
import BillableItemActions from '../../../../../systemUtils/BillableItemsUtils/BillableItemsActions';

const BillableItemsTab: React.FC<BillableItemsTabTypes.Props> = (props) => {
  const [rows, setRows] = React.useState<
    BillableItemRowTypes.BillableItemRowItem[]
  >([]);

  const { userClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();
  const locales = require(`./locales/${props.intl.locale}.json`);

  React.useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const setError = (message: string) => {
    props.eventHandlers.onError(message);
  };

  const handleAddBillableItem = async (
    serviceToFulfillId: string,
    note: string | null,
  ) => {
    await CaseUtils.addManualBillableItemToCaseAsync(
      {
        serviceToFulfillId: serviceToFulfillId,
        note: note,
      },
      await getHeadersAsync(userClaims, instance),
    )
      .then((result) => {
        if (result.isFailure) {
          setError(getServerErrors(props.labels, result.errorCode));
          return;
        }
        props.setRefreshRequired();
      })
      .catch(() => {
        setError(props.labels.ApiResponses.saveFailed);
      });
  };

  return (
    <>
      <ListTable
        isLoading={props.isCaseLoading}
        className="BillableItems-Table"
        labels={{
          tableName: props.labels.table.tableName,
          of: props.labels.table.of,
          size: props.labels.table.size,
          emptyMessage: props.labels.table.emptyMessage,
        }}
        rows={
          rows?.map((row, rI) => {
            return (
              <BillableItemRow
                key={`BillItems_Rows_${rI}`}
                item={row}
                intl={props.intl}
                states={props.labels.billableStates}
                labels={props.labels.rowLabels}
                eventHandlers={{
                  raiseInvoice: async (
                    caseId: string,
                    billableIds: string[],
                  ) => {
                    BillableItemActions.createInvoiceAsync(
                      await getHeadersAsync(userClaims, instance),
                      caseId,
                      billableIds,
                    ).then((result) => {
                      if (result.isFailure) {
                        setError(
                          getServerErrors(
                            props.labels.ApiResponses.serverErrors,
                            result.errorCode,
                          ),
                        );
                        return;
                      } else {
                        props.setRefreshRequired();
                      }
                    });
                  },
                  editBillableItem: (
                    billableItem: BillableItemRowTypes.BillableItemRowItem,
                  ) => {
                    billableItem.isEditing = true;
                    setRows([...rows]);
                  },
                  updateBillableItem: async (
                    modifiedBillableItemPrice: BillableItem_Types.ModifiedBillableItemPriceType,
                  ) => {
                    BillableItemActions.modifyBillableItemAsync(
                      await getHeadersAsync(userClaims, instance),
                      modifiedBillableItemPrice,
                    ).then((result) => {
                      if (result.isFailure) {
                        setError(
                          locales.ApiResponses.errors.modifyBillableItemError,
                        );
                        return;
                      } else {
                        props.setRefreshRequired();
                      }
                    });
                  },
                  cancelEdit: (
                    billableItem: BillableItemRowTypes.BillableItemRowItem,
                  ) => {
                    billableItem.isEditing = false;
                    setRows([...rows]);
                  },
                  onError: (message: string | React.ReactNode) => {},
                }}
              />
            );
          }) || []
        }
        pagingDetails={props.pagingDetails}
        defaultItem={
          props.canAddBillableItems &&
          props.servicesToFulfill.length > 0 && (
            <AddBillableItemRow
              labels={props.labels.addRowLabels}
              servicesToFulfill={props.servicesToFulfill}
              onSave={handleAddBillableItem}
            />
          )
        }
      >
        <ListTable.Headers>
          {[
            <div key="StatusCol"></div>,
            ...props.labels.table.tableHeaders.map((header, hI) => {
              return <div key={`BillItems_Header_${hI}`}>{header}</div>;
            }),
          ]}
        </ListTable.Headers>
      </ListTable>
    </>
  );
};

export default BillableItemsTab;
