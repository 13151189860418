import React from 'react';

import { useIntl } from 'react-intl';
import { OrgActions } from '../../../systemUtils/organisation/OrganisationActions';
import { useNavigate, useParams } from 'react-router-dom';
import Enumerations, {
  getHeadersAsync,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import Organisation_Types from '../../../sysObjects/apiModels/Organisation.types';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormSearchableDropDown from '../../../systemComponents/sharedControls/formControls/formSearchableDropDown/FormSearchableDropDown';
import FormDropDown from '../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import FormTextArea from '../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import FormCheckbox from '../../../systemComponents/sharedControls/formControls/formCheckbox/FormCheckbox';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';

const OrganisationCreateUpdate: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { id } = useParams();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);

  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [sizes, setSizes] = React.useState<KeyValuePair<number>[]>([]);
  const [industrySectors, setSectors] = React.useState<KeyValuePair<number>[]>(
    [],
  );

  const [pageStates, setPageState] = React.useState<KeyValuePair<string>[]>([
    {
      key: locales.controls.pages[0],
      value: 'neutral',
    },
  ]);

  const [orgs, setOrgs] = React.useState<KeyValuePair<string>[]>([]);
  const [types, setTypes] = React.useState<KeyValuePair<number>[]>([]);
  const [formData, setFormData] =
    React.useState<Organisation_Types.organisation>(
      OrgActions.createDefaultOrganisation(),
    );

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | boolean | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleDropDownFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    if (Array.isArray(result.value)) {
      const numbers = (result.value as string[]).map((item) => {
        return parseInt(item);
      });
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: numbers,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: parseInt(result.value as string),
      }));
    }
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    const headers = await getHeadersAsync(userClaims, instance);
    if (id) {
      OrgActions.getOrgByIDAsync(
        id,
        headers
      ).then((result) => {
        if (result.isFailure) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/organisations');
          return;
        }
        setIsLoading(false);
        setFormData(result.result!);
        context?.handleCrumbUpdate(
          [...locales.breadcrumbs]
            .slice(0, locales.breadcrumbs.length - 1)
            .concat({
              label: result.result!.name,
              key: id,
            }),
        );
      });
    } else {
      context?.handleCrumbUpdate([...locales.breadcrumbs]);
    }

    OrgActions.lookUpAsync(
      {},
      headers
    ).then((result) => {
      if (result.isFailure) {
        showMessage(locales.ApiResponses.permissionError, 'negative', '/organisations');
        return;
      }
      setIsLoading(false);
      if (!id) {
        setOrgs(
          result.result!.map((item) => {
            return { key: item.id, value: item.name };
          }),
        );
        return;
      }

      setOrgs(
        result
          .result!.filter((item) => item.id !== id)
          .map((item) => {
            return { key: item.id, value: item.name };
          }),
      );
    });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/organisations');
      return;
    }
    context?.handleCrumbUpdate(
      [...locales.breadcrumbs].slice(0, locales.breadcrumbs.length - 1)
    );
    setSizes(Enumerations.getOrganisationSize(intl.locale));
    setTypes(Enumerations.getOrganisationType(intl.locale));
    setSectors(Enumerations.getIndustrySectors(intl.locale));

    loadDataAsync();
  }, []);

  const save = async () => {
    const validationResults = formRefs.map((innerRef) =>
      innerRef.current?.triggerValidation(),
    );
    const tabValid = validationResults.every((result) => result === true);
    if (!tabValid) {
      setPageState((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[0] = {
          ...updatedPages[0],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
      return;
    }
    OrgActions.saveOrganisationAsync(
      await getHeadersAsync(userClaims, instance),
      formData,
      id,
    )
      .then((result) => {
        if (result.isFailure) {
          showMessage(
            getServerErrors(locales, result.errorCode),
            'negative'
          );
          return;
        }
        showMessage(`${formData.name}:- ${locales.ApiResponses.saveSuccess}`, 'positive', '/organisations');
      })
      .catch(() => {
        showMessage(locales.ApiResponses.saveFailed, 'negative');
      });
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        pages={[
          {
            mode: pageStates[0].value as ControlState,
            name: pageStates[0].key,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  fieldId="name"
                  id="name"
                  label={locales.controls.OrgName}
                  value={formData.name}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.OrgName} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0]}
                  textInputType="text"
                />
                <FormSearchableDropDown
                  displayMode="Box"
                  id="industrySectorCode"
                  fieldId="industrySectorCode"
                  items={industrySectors.map((sector) => ({
                    key: sector.key.toString(),
                    value: sector.value,
                    children: (
                      <>
                        <span>{sector.value}</span>
                      </>
                    ),
                  }))}
                  label={locales.controls.IndustrySector}
                  defaultText={locales.common.Select_Default}
                  value={formData.industrySectorCode?.toString() || ''}
                  onChange={(e) =>
                    handleFormChange({
                      id: 'industrySectorCode',
                      fieldId: 'industrySectorCode',
                      value: e.value,
                    })
                  }
                  ref={formRefs[1]}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.IndustrySector} ${locales.common.requiredMessage}`,
                  }}
                />
                <FormDropDown
                  displayMode="Box"
                  id="organisationSize"
                  fieldId="organisationSize"
                  items={sizes}
                  label={locales.controls.organisationSize}
                  defaultText={locales.common.Select_Default}
                  value={formData.organisationSize}
                  onChange={handleDropDownFormChange}
                  ref={formRefs[2]}
                />
                <FormDropDown
                  displayMode="Box"
                  id="organisationType"
                  fieldId="organisationType"
                  items={types}
                  label={locales.controls.organisationType}
                  defaultText={locales.common.Select_Default}
                  value={formData.organisationType}
                  onChange={handleDropDownFormChange}
                  ref={formRefs[3]}
                />
                <FormTextArea
                  displayMode="Box"
                  id="notes"
                  fieldId="notes"
                  label={locales.controls.notes}
                  value={formData.notes}
                  maxLength={250}
                  onChange={handleFormChange}
                  ref={formRefs[4]}
                />
                <FormDropDown
                  displayMode="Box"
                  id="employerReferringRelationships"
                  fieldId="employerReferringRelationships"
                  items={orgs}
                  label={locales.controls.employerReferringRelationships}
                  defaultText={locales.common.Select_Default}
                  value={formData.employerReferringRelationships}
                  onChange={handleFormChange}
                  ref={formRefs[5]}
                  multiple
                  size={orgs.length < 4 ? orgs.length : 5}
                />
                <FormDropDown
                  displayMode="Box"
                  id="referrerReferringRelationships"
                  fieldId="referrerReferringRelationships"
                  items={orgs}
                  label={locales.controls.referrerReferringRelationships}
                  defaultText={locales.common.Select_Default}
                  value={formData.referrerReferringRelationships}
                  onChange={handleFormChange}
                  multiple
                  size={orgs.length < 4 ? orgs.length : 5}
                  ref={formRefs[6]}
                />
                <FormCheckbox
                  displayMode="Box"
                  id="usersCanAccessPortal"
                  fieldId="usersCanAccessPortal"
                  label={locales.controls.usersCanAccessPortal}
                  value={formData.usersCanAccessPortal}
                  onChange={handleFormChange}
                  ref={formRefs[7]}
                />
              </>
            ),
          },
        ]}
        key={'Organisation'}
      />
      <InformationButton
        key="informationSave"
        buttonDetails={{
          itemKey: 'informationSave',
          clickEvent: () => save(),
          label: locales.controls.saveButton,
          mode: 'positive',
        }}
      >
        <>{locales.controls.summary}</>
      </InformationButton>
    </div>
  );
};

export default OrganisationCreateUpdate;
