import { IntlShape } from 'react-intl';
import moment from 'moment-timezone';
import { parseISO, isValid } from 'date-fns';

/**
 * Converts a date to an ISO string.
 * @param date The date to convert.
 * @returns the date as an ISO string.
 */
export const updateDateToISO = (date: Date): string => {
  const momentDate = moment(date);
  const formattedDate = momentDate.format('YYYY-MM-DDTHH:mm:ssZ');
  return formattedDate;
};

/**
 * Parses the Date string to a Date object.
 * @param dateString
 * @returns
 */
export const parseDate = (dateString: string): Date | null => {
  if (dateString == null || dateString === '') {
    return null;
  }
  const parsedDate = parseISO(dateString);
  if (!isValid(parsedDate)) {
    return null;
  }

  return parsedDate;
};

/**
 * Gets the local date string.
 * @param date The date to convert.
 * @param intl The internationalization object.
 * @param monthFormat The format of the month.
 * @returns The local date string.
 */
export const getLocalDateString = (
  date: string | Date | null,
  intl: IntlShape,
  monthFormat: 'MM' | 'MMMM'
): string => {
  if (date == null) {
    return '';
  }
  const parsedDate = date instanceof Date ? date : parseISO(date);

  if (!isValid(parsedDate)) {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: monthFormat === 'MM' ? '2-digit' : 'long',
    year: 'numeric',
  };

  return intl.formatDate(parsedDate, options);
};

/**
 * Gets the local time string.
 * @param date The date to convert.
 * @param intl The internationalization object.
 * @returns The local time string.
 */
export const getLocalTimeString = (
  date: string | Date | null,
  intl: IntlShape
): string => {
  if (date == null) {
    return '';
  }
  const parsedDate = date instanceof Date ? date : parseISO(date);

  if (!isValid(parsedDate)) {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  return intl.formatTime(parsedDate, options);
};


const DateHelpers = {
  updateDateToISO,
  parseDate,
  getLocalDateString,
  getLocalTimeString,
};

export default DateHelpers;
