import React from 'react';
import { DiagnosisRecordRowTypes } from './DiagnosisRecordRow.types';
import StatusBlock from '../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import { SvgClock } from '../../../sharedControls/svg';
import Expander from '../../../sharedControls/general/expander/Expander';
import ButtonBox from '../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import ButtonBoxTypes from '../../../sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import './DiagnosisRecordRow.css';

const DiagnosisRecordRow: React.FC<DiagnosisRecordRowTypes.Props> = (props) => {
  const [isExpanded, setExpanded] = React.useState(
    props.item.expanded
  );

  const handleRemove = () => {
    if (props.readonly || !props.item.onRemove) {
      return;
    }

    props.item.onRemove();
  };

  const renderActions = () => {
    return props.readonly ? null : (
      <>
        <div className='col Text-Regular'>{props.labels.actions}</div>
        <div className="Colspan">
          <div className="Button-Box">
            <ButtonBox
              id={`${props.item.id}_action-buttons`}
              key={`${props.item.id}_action-buttons`}
              displayBorder={true}
              buttons={[
                {
                  label: props.labels.removeButtonLabel,
                  id: `remove_${props.item.id}`,
                  controlState: 'negative',
                  onClick: () => handleRemove(),
                } as ButtonBoxTypes.Button,
              ]}
            />
          </div>
        </div>
      </>
    );
  };

  const renderExpandedRow = () => {
    return (
      <div className='Expanded-row'>

        <div className="col Text-Regular">{props.labels.diagnosisDate}</div>
        <div className="col Text-Regular Value-Column">
          {props.item.diagnosisDate}
        </div>

        <div className="col Text-Regular">{props.labels.diagnosisSource}</div>
        <div className="col Text-Regular Value-Column">
          {props.item.diagnosisSource}
        </div>

        <div className="col Text-Regular">{props.labels.addedBy}</div>
        <div className="col Text-Regular Value-Column">
          <StatusBlock
            className="dark-background"
            showIcon={false}
            boxSize="small"
            content={props.item.addedByInitials}
            boxState="neutral"
            id="personStatus"
          />
          {props.item.addedByName}
        </div>

        {props.item.notes && (
          <>
            <div className="col Text-Regular">{props.labels.notes}</div>
            <div className="col Text-Regular Value-Column">
              {props.item.notes}
            </div>
          </>
        )}

        {renderActions()}
      </div >
    );
  };

  const renderExpander = () => {
    return (
      <div>
        <Expander
          id={`${props.item.id}_Status`}
          key={`${props.item.id}_Status`}
          isExpanded={isExpanded === true}
          eventHandler={{ onClick: () => setExpanded(!isExpanded) }}
        />
      </div>
    );
  };

  const renderCollapsedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.item.diagnosedCondition}</div>
        <div className="td">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`${props.item.id}_diagnosis-date`}
            content={<SvgClock width={30} height={30} fill={'white'} />}
          />
          {props.item.diagnosisDate}
        </div>
        <div className="td">{props.item.diagnosisSource}</div>
      </>
    )
  }

  const renderExpandedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.item.diagnosedCondition}</div>
        <div />
        <div />
        <div />
      </>
    )
  }

  const renderRow = () => {
    return (
      <>
        {
          isExpanded
            ? renderExpandedHeader()
            : renderCollapsedHeader()
        }
        {isExpanded && renderExpandedRow()}
      </>
    )
  };

  return (
    <div
      className="Diagnosis-Record-Item"
      data-testid={`diagnosis-record-item${props.item.id}`}
      key={`${props.item.id}-diagnosis-record-row`}
    >
      {renderRow()}
    </div>
  );
};

export default DiagnosisRecordRow;