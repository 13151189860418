
import {
  createAction,
  deleteAction,
  fetchAction,
} from '../../systemUtils/common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import System_Types from '../../sysObjects/apiModels/System.types';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import CustomerTypes from '../../sysObjects/apiModels/Customer.types';

namespace DiagnosisRecordActions {
  /**
   *
   * @param {string} customerAccountId The id of the user account of the customer to retrieve diagnosis records for.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information.
   * @returns {FunctionalResult<CustomerTypes.RetrievedDiagnosisRecord[]>} The result of the action.
   */
  export const retrieveDiagnosisRecordsAsync = async (
    customerAccountId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<CustomerTypes.RetrievedDiagnosisRecord[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Customers.retrieveDiagnosisRecords.path!}/${customerAccountId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
 * Asynchronously removes a diagnosis record by its ID.
 *
 * @param diagnosisRecordId - The ID of the diagnosis record to be removed.
 * @param headerInfo - The header information containing user ID and access token.
 * @returns A promise that resolves to a FunctionalResult containing void if successful, or an error message if the operation fails.
 */
  export const removeDiagnosisRecordAsync = async (
    diagnosisRecordId: string,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return deleteAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.Customers.removeDiagnosisRecord.path!}/${diagnosisRecordId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Asynchronously adds diagnosis records.
   *
   * @param diagnosisRecord - The diagnosis record to be added.
   * @param headerInfo - The header information containing user ID and access token.
   * @returns A promise that resolves to a FunctionalResult containing a string message.
   */
  export const addDiagnosisRecordsAsync = async (
    diagnosisRecord: CustomerTypes.DiagnosisRecord,
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.Customers.createDiagnosisRecord.path!,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
      formData: diagnosisRecord,
    });
  };

  export const createDefaultDiagnosisRecord = (): CustomerTypes.DiagnosisRecord => {
    return {
      dateDiagnosed: undefined,
      diagnosisSource: undefined,
      notes: undefined,
      diagnosedCondition: undefined,
      customerId: '',
    };
  };
}

export default DiagnosisRecordActions;
