import React, { FC } from 'react';
import SearchIconExpandTypes from './SearchIconExpand.types';
import './SearchIconExpand.css';
import exp from 'constants';
import BaseBlock from '../../reusableBlocks/baseBlock/BaseBlock';
import { SvgClose, SvgOpen } from '../../svg';
const SearchIconExpand: FC<SearchIconExpandTypes.Props> = (props) => {
  return (
    <div className="Search-Icon" onClick={() => props.onExpand?.(!props.isExpanded)}>
      <BaseBlock
        boxSize="medium"
        className="Icon-Holder"
        content={props.icon}
        onClick={() => props.onExpand?.(!props.isExpanded)}
        testid={`search-icon-${props.testId}`}
      />
      <BaseBlock
        boxSize="medium"
        className={`Icon-Expand ${props.isExpanded ? 'Icon-Expand-Close' : 'Icon-Expand-Open'}`}
        content={
          props.isExpanded ? (
            <SvgClose className="icon-dark" />
          ) : (
            <SvgOpen className="icon" />
          )
        }
        onClick={() => props.onExpand?.(!props.isExpanded)}
        testid={`search-expand-${props.testId}`}
      />
    </div>
  );
};

export default SearchIconExpand;
