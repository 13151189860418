import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import System_Types from '../../sysObjects/apiModels/System.types';
import { getHostConfigAsync } from '../common/HostConfigActions';
import settings from '../../config/services.json';
import LocalDocumentTypes from '../../sysObjects/apiModels/DocumentTypes';
import DocumentTypes from '../../sysObjects/apiModels/UploadDocument.types';
import {
  confirmAction,
  fetchAction,
  fetchWithAttachmentAction,
  uploadFileAction,
} from '../common/APIHelper';

/**
 * Retrieves the document file content from the server
 * @param query - The query object to be sent with the request
 * @param headers - The headers to be sent with the request
 * @returns a string of the file content
 */
export const retrieveCaseDocumentFileContentAsync = async (
  query: LocalDocumentTypes.GetDocumentQuery,
  headers: FunctionalResult<System_Types.ApiHeader>,
): Promise<FunctionalResult<DocumentTypes.returnModel>> => {
  const host = await getHostConfigAsync();
  if (headers.isFailure) {
    return FunctionalResult.Error('Failed to get headers');
  }
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }

  let queryParams: string[] = [];

  queryParams.push(
    `${settings.Documents.retrieve.queryDocumentId}=${query.documentId}`,
  );
  queryParams.push(
    `${settings.Documents.retrieve.queryCaseId}=${query.caseId}`,
  );
  queryParams.push(
    `${settings.Documents.retrieve.queryExternalUserAccountId}=${query.relatedExternalUserId}`,
  );

  let queryPath = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

  return fetchWithAttachmentAction({
    hostPath: host!.result!.path,
    apiPath: `${settings.Documents.retrieve.path}${queryPath}`,
    userId: headers.result!.userId,
    accessToken: headers.result!.token,
  });
};
/**
 * Gets the details of the documents on a case from the server
 * @param query - The query object to be sent with the request
 * @param headers - The headers to be sent with the request
 * */
export const findDocumentsOnCaseAsync = async (
  query: LocalDocumentTypes.FindCaseDocumentsQuery,
  headers: FunctionalResult<System_Types.ApiHeader>,
): Promise<FunctionalResult<LocalDocumentTypes.FindResultObject[]>> => {
  const host = await getHostConfigAsync();
  if (headers.isFailure) {
    return FunctionalResult.Error('Failed to get headers');
  }
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }

  let queryParams: string[] = [];

  queryParams.push(`${settings.Documents.find.queryCaseId}=${query.caseId}`);
  queryParams.push(
    `${settings.Documents.find.queryExternalUserAccountId}=${query.relatedExternalUserId}`,
  );

  if (query.VisibilityStates && query.VisibilityStates.length > 0) {
    query.VisibilityStates.forEach((status) => {
      queryParams.push(
        `${settings.Documents.find.queryVisibility}=${status.toString()}`,
      );
    });
  }
  let queryPath = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

  return fetchAction({
    hostPath: host!.result!.path,
    apiPath: `${settings.Documents.find.path}${queryPath}`,
    userId: headers.result!.userId,
    accessToken: headers.result!.token,
  });
};

/**
 * Uploads the document to the server
 * @param obj - The document object to be uploaded
 * @param headers - The headers to be sent with the request
 * */
export const uploadDocumentAsync = async (
  obj: DocumentTypes.UploadModal,
  headers: FunctionalResult<System_Types.ApiHeader>,
): Promise<FunctionalResult<string>> => {
  const host = await getHostConfigAsync();
  if (headers.isFailure) {
    return FunctionalResult.Error('Failed to get headers');
  }
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }
  return uploadFileAction({
    hostPath: host!.result!.path,
    apiPath: `${settings.Documents.create.path}`,
    userId: headers.result!.userId,
    accessToken: headers.result!.token,
    data: obj,
  });
};

/**
 * Sets the visibility of a document on a case
 * @param obj - The object to set the permissions
 * @param headers 
 * @returns 
 */
const SetCaseDocumentVisibilityAsync = async (
  obj: DocumentTypes.SetVisibilityModal,
  headers: FunctionalResult<System_Types.ApiHeader>,
): Promise<FunctionalResult<void>> => {
  if (headers.isFailure) {
    return FunctionalResult.Error('Failed to get headers');
  }

  const host = await getHostConfigAsync();
  if (host.isFailure) {
    return FunctionalResult.Error('Failed to load host configuration');
  }
  let queryParams: string[] = [];

  queryParams.push(`${settings.Documents.changePermissions.queryCaseId}=${obj.CaseId}`);
  queryParams.push(`${settings.Documents.changePermissions.queryDocumentId}=${obj.DocumentId}`);
  queryParams.push(`${settings.Documents.changePermissions.queryExternalUserAccountId}=${obj.RelatedExternalUserId}`);
  queryParams.push(`${settings.Documents.changePermissions.queryVisibility}=${obj.visibilityState}`);

  let queryPath = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

  return confirmAction({
    hostPath: host!.result!.path,
    apiPath: `${settings.Documents.changePermissions.path}${queryPath}`,
    userId: headers.result!.userId,
    accessToken: headers.result!.token,
  });
};

const DocumentActions = {
  retrieveCaseDocumentFileContentAsync,
  findDocumentsOnCaseAsync,
  uploadDocumentAsync,
  SetCaseDocumentVisibilityAsync
};
export default DocumentActions;
