// src/layouts/RootLayout/RootLayout.tsx
import React from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import image from '../../../misc/lexxic.png';
import { useIntl } from 'react-intl';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import {
  SvgBrain,
  SvgCases,
  SvgExternalUser,
  SvgOrganisation,
  SvgPricing,
  SvgUser,
  SvgDash,
} from '../../../systemComponents/sharedControls/svg';
import BreadCrumbBarTypes from '../../../systemComponents/sharedControls/pageLevel/breadcrumbs/Breadcrumbs.types';
import { SystemUserClaims } from '../../../systemComponents/sharedControls/contexts/UserClaimsContextType.types';
import NavMenu from '../../../systemComponents/sharedControls/pageLevel/navMenu/NavMenu';
import Breadcrumbs from '../../../systemComponents/sharedControls/pageLevel/breadcrumbs/Breadcrumbs';
import LexxicFooter from '../../../systemComponents/sharedControls/pageLevel/footer/Footer';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import C from '../../../systemUtils/userUtils/SystemUserActions';
import './Root.css';
import { handleLogout } from '../../../systemUtils/common/CommonHelpers';
import logo from '../../../misc/ConnectOnly.png';
import AlertModal from '../../../systemComponents/sharedControls/pageLevel/systemAlerts/alertModal/AlertModal';
import AlertModalTypes from '../../../systemComponents/sharedControls/pageLevel/systemAlerts/alertModal/AlertModal.types';
import AlertList from '../../../systemComponents/sharedControls/pageLevel/systemAlerts/alertList/AlertList';
import { messageDetails } from '../../../sysObjects/common.types';

const RootLayout: React.FC = () => {
  const intl = useIntl();
  const local = require(`./locales/${intl.locale}.json`);
  const { instance } = useMsal();
  const { userClaims } = React.useContext(UserClaimsContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [alertItem, setAlertItem] = React.useState<messageDetails | null>(null);
  const [modalMessage, setModalMessage] =
    React.useState<AlertModalTypes.messageDetailObj>({
      alertType: 'neutral',
      message: '',
      showMessage: false,
    });

  const buttonConfigs = {
    superUserButtons: [
      {
        id: 4,
        label: local.labels.inUserButton.label,
        ariaLabel: local.labels.inUserButton.ariaLabel,
        accessKey: 'd',
        icon: <SvgUser className="icon" />,
        areaName: 'team',
        to: '/team/accounts',
      },
      {
        id: 5,
        label: local.labels.serviceButton.label,
        ariaLabel: local.labels.serviceButton.ariaLabel,
        accessKey: 's',
        icon: <SvgBrain className="icon" />,
        areaName: 'services',
        to: '/services',
      },
      {
        id: 6,
        label: local.labels.pricingButton.label,
        ariaLabel: local.labels.pricingButton.ariaLabel,
        accessKey: 'p',
        icon: <SvgPricing className="icon" />,
        areaName: 'pricing',
        to: '/pricing',
      },
    ],
    caseManagerOrHigherButtons: [
      {
        id: 2,
        label: local.labels.organisationButton.label,
        ariaLabel: local.labels.organisationButton.ariaLabel,
        accessKey: 'o',
        to: '/organisations',
        areaName: 'organisations',
        icon: <SvgOrganisation className="icon" />,
      },
      {
        id: 3,
        label: local.labels.exUserButton.label,
        ariaLabel: local.labels.exUserButton.ariaLabel,
        accessKey: 'u',
        icon: <SvgExternalUser className="icon" />,
        areaName: 'customers',
        to: '/customers/accounts',
      },
    ],
    defaultButtons: [
      {
        id: 1,
        label: local.labels.caseButton.label,
        ariaLabel: local.labels.caseButton.ariaLabel,
        accessKey: 'c',
        to: '/cases',
        areaName: 'cases',
        icon: <SvgCases className="icon" />,
      },
    ],
  };
  const segments = location.pathname
    .split('/')
    .filter((segment) => segment !== '');

  const [buttons, setButtons] = React.useState<any[]>([]);
  const [breadcrumb, setBreadcrumb] = React.useState<
    BreadCrumbBarTypes.BreadcrumbsLinks[]
  >([]);

  const determineButtons = (userClaims?: SystemUserClaims | null) => {
    if (C.userIsSuperUser(userClaims!.user!)) {
      return buttonConfigs.defaultButtons.concat(
        buttonConfigs.caseManagerOrHigherButtons.concat(
          buttonConfigs.superUserButtons,
        ),
      );
    } else if (C.userIsCaseManagerOrHigher(userClaims!.user!)) {
      return buttonConfigs.defaultButtons.concat(
        buttonConfigs.caseManagerOrHigherButtons,
      );
    } else {
      return buttonConfigs.defaultButtons;
    }
  };

  React.useEffect(() => {
    const buttonsToShow = determineButtons(userClaims);
    setButtons(buttonsToShow);
  }, [userClaims]);

  return (
    <CommonPageContext.Provider
      value={{
        handleCrumbUpdate: (items) => {
          setBreadcrumb(items.map((item) => item));
        },
        handleMessage(details) {
          setModalMessage({
            alertType: details.alertType,
            message: details.message,
            showMessage: true,
          });
        },
      }}
    >
      <div className="layout-container">
        <div className="nav">
          <NavMenu
            image={image}
            imageLogo={logo}
            workFlowNavigation={buttons}
            currentArea={segments.length > 0 ? segments[0] : 'dashboard'}
            yourNavigation={[
              {
                id: 'YourButton',
                label: local.labels.yourButton.label,
                ariaLabel: local.labels.yourButton.ariaLabel,
                accessKey: 'y',
                icon: <SvgDash className="icon" />,
                areaName: 'dashboard',
                to: '/',
              },
            ]}
            key="SideNav"
            labels={local.labels.navMenuCommon}
            eventHandlers={{
              logout: async () => await handleLogout(instance, navigate),
              buttonClicked: (path: string) => navigate(path),
            }}
          />
        </div>
        <div className="vertical-line" aria-hidden="true"></div>
        <div className="main-content">
          <AlertModal
            messageDetail={modalMessage}
            onDismiss={(reason, details) => {
              setModalMessage({ ...modalMessage, showMessage: false });
              if (reason === 'TimeOut' && details && details.message !== '') {
                setAlertItem({
                  message: details!.message,
                  alertType: details!.alertType,
                });
              }
            }}
          />
          <div className="breadcrumb">
            <Breadcrumbs
              breadcrumbLinks={breadcrumb}
              onClick={(to) => {
                navigate(to);
              }}
            />
          </div>
          <div className="body">
            <Outlet />
          </div>
        </div>
        <AlertList
          labels={{
            closeButton: local.labels.alertText.closeButton,
            allClearButton: local.labels.alertText.allClearButton,
          }}
          propUpdate={() => {
            setAlertItem(null);
          }}
          detail={alertItem}
        />
        <div className="footer">
          <LexxicFooter
            address={local.labels.Address}
            image={image}
          />
        </div>
      </div>
    </CommonPageContext.Provider>
  );
};

export default RootLayout;
