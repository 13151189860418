import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/services/ServiceDefinitionActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import Enumerations, {
  getHeadersAsync,
} from '../../../systemUtils/common/CommonHelpers';
import {
  ColDetails,
  colSearchDetails,
  ControlState,
  KeyValuePair,
} from '../../../sysObjects/common.types';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ServiceDefinitionIndexTypes from './ServiceDefinition_Index.types';
import ListTableRow from '../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import './ServiceDefinition_Index.css';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import ListTableSearch from '../../../systemComponents/sharedControls/tables/listTableSearch/ListTableSearch';
import {
  filterTable,
  sortTable,
} from '../../../systemUtils/common/SortSearchHelpers';
import StandardHeader from '../../../systemComponents/sharedControls/tables/listTable/headers/standard/StandardHeader';
import SortableHeader from '../../../systemComponents/sharedControls/tables/listTable/headers/sortable/SortableHeader';

const ServiceDefinition_index: FC = () => {
  const [sortColumnDetails, setSortColumnDetails] = useState<colSearchDetails>({
    field: 'externalDisplayName',
    searchDirection: 'asc',
    searchType: 'string',
  });
  const fieldsToSearch: (keyof ServiceDefinitionIndexTypes.RowData)[] = [
    'externalDisplayName',
    'description',
    'nominalCode',
    'serviceCode',
  ];
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = useContext(CommonPageContext);
  const { userClaims } = useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [services, setServices] = useState<
    ServiceDefinitionIndexTypes.RowData[]
  >([]);

  const [conditions, setConditions] = useState<KeyValuePair<number>[]>([]);
  const [deliveryFormats, setDeliveryFormats] = useState<
    KeyValuePair<number>[]
  >([]);

  const [showTextSearch, setShowTextSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const { instance } = useMsal();

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    actions
      .findAsync({ active: 0 }, await getHeadersAsync(userClaims, instance))
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
          return;
        }

        context?.handleCrumbUpdate(locales.breadcrumbs);
        setServices(
          result.result.map((service) => {
            return {
              ...service,
              isExpanded: false,
              isButtonRowExpanded: false,
            };
          })
        );
      });
  };

  useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }
    setConditions(Enumerations.getRelatedConditions(intl.locale));
    setDeliveryFormats(Enumerations.getDeliveryFormats(intl.locale));

    loadDataAsync();
  }, []);

  useEffect(() => {
    setServices((prevItems) => {
      return prevItems.map((prevItem) => {
        return {
          ...prevItem,
          isExpanded: false,
          buttonsExpanded: false,
        };
      });
    });
  }, [searchString]);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={isLoading}
        labels={{
          tableName: locales.tableHeading,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
        search={{
          isVisible: showTextSearch,
          onStateChange: (isExpanded: boolean) => setShowTextSearch(isExpanded),
        }}
        key="fk"
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        rows={sortTable(
          filterTable(services, searchString, fieldsToSearch),
          sortColumnDetails.field as keyof ServiceDefinitionIndexTypes.RowData,
          sortColumnDetails.searchDirection === 'none'
            ? 'asc'
            : sortColumnDetails.searchDirection,
          sortColumnDetails.searchType
        ).map((service) => {
          const buttons: ButtonBoxTypes.Button[] = [
            {
              controlState: 'positive',
              label: locales.editButton,
              id: `edit-${service.id}`,
              onClick: () => navigate(`/services/edit/${service.id}`),
            },
            service.assessmentAppointmentDefinition !== null
              ? {
                  id: `editAssessmentDefinition-${service.id}`,
                  controlState: 'positive',
                  label: locales.editAssessmentDefinitionButton,
                  onClick: () =>
                    navigate(
                      `/services/${service.id}/appointmentTemplate/edit/${
                        service.assessmentAppointmentDefinition!.id
                      }`
                    ),
                }
              : {
                  id: `createAssessmentDefinition-${service.id}`,
                  controlState: 'positive',
                  label: locales.createAssessmentDefinitionButton,
                  onClick: () =>
                    navigate(
                      `/services/${service.id}/appointmentTemplate/create`
                    ),
                },
          ];
          return (
            <ListTableRow
              key={`ListTableRow-${service.id}`}
              id={`ListTableRow-${service.id}`}
              className="Service-List-Item"
              events={{
                OnExpandChange: (isExpanded: boolean) => {
                  setServices((prevItems) =>
                    prevItems.map((item) =>
                      item.id === service.id
                        ? {
                            ...item,
                            isExpanded,
                            isButtonRowExpanded:
                              isExpanded === true
                                ? false
                                : item.isButtonRowExpanded,
                          }
                        : {
                            ...item,
                            isExpanded: false,
                            isButtonRowExpanded: false,
                          }
                    )
                  );
                },
                OnButtonVisibilityChange: (isButtonRowExpanded: boolean) => {
                  setServices((prevItems) =>
                    prevItems.map((item) =>
                      item.id === service.id
                        ? { ...item, isButtonRowExpanded }
                        : {
                            ...item,
                            isExpanded: false,
                            isButtonRowExpanded: false,
                          }
                    )
                  );
                },
              }}
              isButtonRowExpanded={service.isButtonRowExpanded}
              isExpanded={service.isExpanded}
            >
              <ListTableRow.Collapsed>
                <div>{`${service.externalDisplayName} - ${
                  conditions.find((rc) => rc.key === service.relatedCondition)
                    ?.value || ''
                } - ${
                  deliveryFormats.find(
                    (df) => df.key === service.deliveryFormat
                  )?.value || ''
                }`}</div>
              </ListTableRow.Collapsed>
              <ListTableRow.Expanded>
                <>
                  <div>{locales.code}</div>
                  <div>{service.serviceCode}</div>
                  <div>{locales.type}</div>
                  <div>{service.serviceType}</div>
                  <div>{locales.nomCode}</div>
                  <div>{service.nominalCode}</div>
                  <div>{locales.description}</div>
                  <div>{service.description}</div>
                  <div>{locales.action}</div>
                  <ButtonBox
                    className="Button-Box-Container-Expanded"
                    id={`ButtonBox-Expanded-${service.id}`}
                    displayBorder={true}
                    buttons={buttons}
                  ></ButtonBox>
                </>
              </ListTableRow.Expanded>
              <ListTableRow.ButtonRow
                actionLabel={locales.action}
                isExpanded={service.isButtonRowExpanded}
                id={service.id}
              >
                <ButtonBox
                  className="Button-Box-Container"
                  id={`ButtonBox-ButtonRow-${service.id}`}
                  displayBorder={false}
                  buttons={buttons}
                ></ButtonBox>
              </ListTableRow.ButtonRow>
            </ListTableRow>
          );
        })}
        className="Service-Definition-Index"
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular" />
          {locales.cols.map((col: ColDetails, index: number) => {
            return col.style === 'sortable' ? (
              <SortableHeader
                key={`${col.id}-${index}`}
                id={col.id}
                name={col.name}
                fieldName={col.field}
                searchType={col.searchType}
                sortDirection={
                  col.field === sortColumnDetails.field
                    ? sortColumnDetails.searchDirection
                    : 'none'
                }
                onSortSelected={(name, searchType, sortDirection) => {
                  setSortColumnDetails({
                    field: name,
                    searchDirection: sortDirection,
                    searchType: searchType as
                      | 'string'
                      | 'number'
                      | 'boolean'
                      | 'date',
                  });
                }}
              />
            ) : (
              <StandardHeader
                id={col.id}
                name={col.name}
                key={`${col.id}-${index}`}
              />
            );
          })}
          <div className="Heading Text-Regular" />
        </ListTable.Headers>
        <ListTable.SearchOptions>
          {showTextSearch && (
            <ListTableSearch
              Labels={{ searchButton: locales.search }}
              onSearch={(message: string) => setSearchString(message)}
            />
          )}
        </ListTable.SearchOptions>
      </ListTable>
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButtonBilling',
            label: locales.createButton,
            mode: 'positive',
            clickEvent: () => navigate('/services/create'),
          }}
        >
          {locales.createSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default ServiceDefinition_index;
