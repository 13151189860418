import ServiceRecommendation_Types from "../../sysObjects/apiModels/ServiceRecommendation.Types";
import System_Types from "../../sysObjects/apiModels/System.types";
import { FunctionalResult } from "../../sysObjects/FunctionalResult";
import { createAction, deleteAction } from "../common/APIHelper";
import { getHostConfigAsync } from "../common/HostConfigActions";
import settings from '../../config/services.json';

/**
 * Namespace containing utility functions for handling service recommendations.
 */
export namespace RecommendationUtils {
    /**
   * Adds a service recommendation.
   * 
   * @param serviceRecommendation - The service recommendation to add.
   * @param headerInfo - The API header information.
   * @returns A promise that resolves to a FunctionalResult containing a string message.
   */
    export const addServiceRecommendationAsync = async (
        serviceRecommendation: ServiceRecommendation_Types.ServiceRecommendation,
        headerInfo: FunctionalResult<System_Types.ApiHeader>,
    ): Promise<FunctionalResult<string>> => {
        const host = await getHostConfigAsync();
        if (host.isFailure) {
            return FunctionalResult.Error('Failed to load host configuration');
        }
        if (
            headerInfo.isFailure ||
            headerInfo.result === null ||
            headerInfo.result === undefined
        ) {
            return FunctionalResult.Error('Failed to load host configuration');
        }

        return createAction({
            hostPath: host!.result!.path,
            apiPath: `${settings.Cases.AddRecommendation.path!}/${serviceRecommendation.caseId}`,
            userId: headerInfo.result.userId,
            accessToken: headerInfo.result.token,
            formData: serviceRecommendation.serviceDefinitionId,
        });
    };

    /**
   * Deletes a service recommendation.
   * 
   * @param id - The ID of the service recommendation to delete.
   * @param caseId - The ID of the case associated with the service recommendation.
   * @param headerInfo - The API header information.
   * @returns A promise that resolves to a FunctionalResult containing void.
   */
    export const deleteServiceRecommendationAsync = async (
        id: string,
        caseId: string,
        headerInfo: FunctionalResult<System_Types.ApiHeader>,
    ): Promise<FunctionalResult<void>> => {
        const host = await getHostConfigAsync();
        if (host.isFailure) {
            return FunctionalResult.Error('Failed to load host configuration');
        }
        if (
            headerInfo.isFailure ||
            headerInfo.result === null ||
            headerInfo.result === undefined
        ) {
            return FunctionalResult.Error('Failed to load host configuration');
        }

        return deleteAction({
            hostPath: host!.result!.path,
            apiPath: `${settings.Cases.DeleteRecommendation.path!}/${caseId}?${settings.Cases.DeleteRecommendation.queryServiceRecommendationId}=${id}`,
            userId: headerInfo.result.userId,
            accessToken: headerInfo.result.token,
        });
    };
};